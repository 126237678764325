import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerLayoutSemLayout } from '@/componentes/ContainerLayoutSemLayout'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { Schema, useAuthConfirmarMatriculaV5 } from '@/hooks/API/autenticacaoV5/useAuthConfirmarMatriculaV5'
import { Flex, Img, Link, Select, Text } from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'
import { inputStyle } from '../../../../componentes/CompositionsPatterns/Formulário/Input'
import { useAuthPegarInstituicao } from '../../../../hooks/API/autenticacaoV5/useAuthPegarInsituicao'
import { useBackLogin } from '../@components/useBackLogin'
import { useSearchParams } from 'react-router-dom'

export const VerificarMatriculaPagina = () => {
  const { mutateAuthBuscarMatricula, isLoadingAuthBuscarMatricula, contextAuthBuscarMatricula } = useAuthConfirmarMatriculaV5()
  const { instituicoes } = useAuthPegarInstituicao({ matricula: contextAuthBuscarMatricula.watch('matricula') })
  const [searchBarParams, setSearchParams] = useSearchParams()
  const instituicaoId = searchBarParams.get('instituicaoId')

  const { backLogin } = useBackLogin()

  const handlesubmit = (data: Schema) => {
    if (!instituicaoId) return
    localStorage.setItem('instituicoes', instituicaoId)
    // navigate(primeiroAcessoRotasApp.cadastro)
    mutateAuthBuscarMatricula(data)
  }
  return (
    <ContainerLayoutSemLayout>
      <Img src="/logo.png" alt="Logo da empresa" width="250px" alignSelf="center" />
      <FlexColumnUi gap={gapUi.lg}>
        <TextoXxlUi color={coresUi['primaria-ativo']} alignSelf="start" fontWeight="500">
          Primeiro acesso
        </TextoXxlUi>
        <TextoMdUi>Bem vindo(a) ao primeiro acesso! Para continuar, informe sua matrícula.</TextoMdUi>
      </FlexColumnUi>
      <FormProvider {...contextAuthBuscarMatricula}>
        <FormulárioUi.Form callback={handlesubmit}>
          <FlexColumnUi width="100%" gap={gapUi['4xl']}>
            <FormulárioUi.Container name="matricula">
              <FormulárioUi.Label título="Matrícula" />
              <FormulárioUi.Input placeholder="Digite sua matrícula" />
              <FormulárioUi.MensagemErro />
            </FormulárioUi.Container>

            <FormulárioUi.Container name="instituicaoId">
              <FormulárioUi.Label título="Instituição" />
              <Select
                placeholder="Selecione uma instituição"
                {...inputStyle}
                isDisabled={!instituicoes}
                onChange={(e) => {
                  const valorCampo = e.target.value
                  if (valorCampo === 'undefined') {
                    setSearchParams((params) => {
                      params.delete('instituicaoId')
                      return params
                    })
                  } else {
                    setSearchParams((params) => {
                      params.set('instituicaoId', valorCampo)
                      return params
                    })
                  }
                }}
              >
                {instituicoes &&
                  instituicoes.map((instituicao) => (
                    <option key={instituicao.id} value={instituicao.id}>
                      {instituicao.nome}
                    </option>
                  ))}
              </Select>
              <FormulárioUi.MensagemErro />
            </FormulárioUi.Container>
            <Flex width="100%" flexDirection="column" alignItems={'center'} gap={gapUi['2xl']}>
              <BotãoFormulárioUi isLoading={false} type="submit" isDisabled={isLoadingAuthBuscarMatricula}>
                {isLoadingAuthBuscarMatricula ? 'Verificando matrícula...' : 'Verificar matrícula'}
              </BotãoFormulárioUi>

              <TextoSmUi alignSelf="center" textAlign={'center'}>
                Já possui uma conta?
                <Text color={coresUi['primaria-ativo']} as="span" paddingY="10px" onClick={backLogin}>
                  {' '}
                  Realizar login
                </Text>
              </TextoSmUi>
              <TextoSmUi textAlign={'center'}>
                Não sabe sua matrícula?
                <Link
                  href="https://api.whatsapp.com/send?phone=5511945665619&text=Ol%C3%A1,%20preciso%20de%20ajuda%20para%20recuperar%20minha%20matr%C3%ADcula%20!"
                  target="_blank"
                  color={coresUi['primaria-ativo']}
                  paddingY="10px"
                >
                  {' '}
                  Clique aqui para descobrir
                </Link>
              </TextoSmUi>
            </Flex>
          </FlexColumnUi>
        </FormulárioUi.Form>
      </FormProvider>
    </ContainerLayoutSemLayout>
  )
}
