import { ResponsavelModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import Yup from '@/utilitários/Yup/Yup'
import { responseError } from '@/utilitários/handler/responseHandler'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'

export const useCódigoAcesso = () => {
  const storage = new LocalStorage()
  const {
    mutate: códigoAcesso,
    isLoading: códigoAcessoIsLoading,
    status: códigoAcessoStatus,
  } = useMutation(endPoint, {
    onSuccess: (data) => {
      storage.set('codigoAcesso', data.codigoAcesso)
      storage.set('isMenorIdade', data.isMenorIdade ? 'true' : 'false')
      storage.set('matricula', data.matricula)
      storage.set('hasResponsavel', data.responsavel ? 'true' : 'false')
      if (data.responsavel) {
        storage.set('nomeResponsável', data.responsavel.nome)
        storage.set('cpfResponsável', data.responsavel.cpf)
        storage.set('celularResponsável', data.responsavel.telefone)
        storage.set('emailResponsável', data.responsavel.email)
        storage.set('confirmEmailResponsável', data.responsavel.email)
      }
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const códigoAcessoContext = useForm({
    resolver: yupResolver(códigoAcessoSchema),
    reValidateMode: 'onChange',
  })

  return {
    códigoAcesso,
    códigoAcessoIsLoading,
    códigoAcessoContext,
    códigoAcessoStatus,
  }
}

type ServiceResponseData = {
  codigoAcesso: string
  isMenorIdade: boolean
  matricula: string
  responsavel: ResponsavelModel
}
async function endPoint(data: CódigoAcessoSchema): Promise<ServiceResponseData> {
  const result = await api().post(rotasApi.codigoAcesso, data)
  return result.data.data
}

const códigoAcessoSchema = Yup.object({
  codigoAcesso: Yup.string().required().label('Código de acesso'),
})

export type CódigoAcessoSchema = Yup.InferType<typeof códigoAcessoSchema>
