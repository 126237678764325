import { coresUi } from '@/configurações/estilização/cores'
import { paddingUi } from '@/configurações/estilização/medidas'
import { Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { ReactNode } from 'react'

export const useModalCustomUi = (): IModalUiReturn => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const UiModal = (props: IModalCustomUiProps) => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={'xs'}>
      <ModalOverlay backdropFilter="auto" backdropBlur={`10px`} filter={`grayscale(0%)`} id="overlay-close-modal" />
      <ModalContent bg={coresUi.fundo.secundaria} shadow="xs" padding={paddingUi['2xl']} alignItems="center" textAlign="center">
        {props.children}
      </ModalContent>
    </Modal>
  )

  return { UiModal, isOpen, onOpen, onClose }
}

export interface IModalCustomUiProps {
  children?: ReactNode
}

export interface IModalUiReturn {
  UiModal: (props: IModalCustomUiProps) => JSX.Element
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}
