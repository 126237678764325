import { corPrimariaInativoUi } from '@/configurações/estilização/cores'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { ReactNode, useRef } from 'react'

export const AlertDilogPopup: React.FC<AlertDilogProps> = ({ titulo, descricao, marcarComoLida, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  return (
    <>
      <Button
        borderBottomRadius="10px"
        colorScheme={corPrimariaInativoUi}
        onClick={onOpen}
        color="#1c1c1c"
        fontWeight={500}
        textAlign={'center'}
        paddingY={'5px'}
        _hover={{
          borderRadius: '0px',
          borderBottomRadius: '10px',
          bg: corPrimariaInativoUi,
        }}
      >
        {children}
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {titulo}
            </AlertDialogHeader>

            <AlertDialogBody>{descricao}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme="green"
                onClick={() => {
                  marcarComoLida()
                  onClose()
                }}
                ml={3}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

interface AlertDilogProps {
  titulo: string
  descricao: string
  marcarComoLida: () => void
  children: ReactNode
}
