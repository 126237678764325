import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi } from '@/configurações/estilização/medidas'
import { Button, ButtonProps } from '@chakra-ui/react'
import { TextoMdUi } from '../Textos/TextoMdUi'
import { TextoSmUi } from '../Textos/TextoSmUi'

interface IBotãoFormulárioUi extends ButtonProps {
  callback?: () => void
  children: string | JSX.Element
  isDisabled?: boolean
  type?: ButtonProps['type']
  hidden?: boolean
  isLoading?: boolean
  height?: string
}

export const BotãoFormulárioUi = ({ callback, children, type, isLoading, ...rest }: IBotãoFormulárioUi) => {
  return (
    <Button
      __css={{}}
      width="100%"
      {...(rest.height
        ? {
            height: rest.height,
          }
        : { height: '48px' })}
      background={coresUi['primaria-ativo']}
      {...(callback && { onClick: callback })}
      {...(rest.isDisabled && {
        opacity: 0.5,
      })}
      borderRadius={borderRadiusUi.lg}
      type={type}
      {...rest}
    >
      {typeof children === 'string' && (
        <TextoMdUi color={coresUi.texto['com-fundo-primário']} fontWeight="medium">
          {isLoading ? 'Carregando...' : children}
        </TextoMdUi>
      )}
      {typeof children !== 'string' && (
        <TextoSmUi color={coresUi.texto['com-fundo-primário']} fontWeight="medium">
          {isLoading ? 'Carregando...' : children}
        </TextoSmUi>
      )}
    </Button>
  )
}
