import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { Flex } from '@chakra-ui/layout'
import { ReactNode } from 'react'
import Scrollbars from 'react-scrollbars-custom'
export const ScrollHorizontalUi = ({ children, id }: { children: ReactNode; id: string }) => {
  return (
    <Scrollbars id={id} style={{ width: '100%', scrollbarWidth: 'none' }} mobileNative>
      <Flex marginX={paddingUi.xl} flexWrap="nowrap" paddingBottom={paddingUi.lg} gap={gapUi.lg}>
        {children}
      </Flex>
    </Scrollbars>
  )
}
