import {
  CardVisualizarQuizPesquisaResultadoUi,
  IQuizPerguntas,
} from '@/componentes/Card/CardVisualizarQuizPesquisaResultado/CardVisualizarQuizPesquisaResultadoUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { gapUi } from '@/configurações/estilização/medidas'
import { useQuizPesquisaVisualizar } from '@/hooks/API/quiz/useQuizPesquisaVisualizar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { X } from '@phosphor-icons/react'
import { useParams } from 'react-router-dom'

export const HistóricoPesquisaPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Histórico Pesquisa',
    isBotãoEsquerdo: true,
    íconeDireita: X,
  })
  const { id } = useParams()
  const { quizPesquisaVisualizar } = useQuizPesquisaVisualizar({ quizPesquisaId: id })

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi gap={gapUi.lg}>
        {quizPesquisaVisualizar?.Perguntas?.map((pergunta, index) => (
          <CardVisualizarQuizPesquisaResultadoUi
            key={pergunta.id}
            pergunta={pergunta.pergunta}
            alternativas={
              pergunta.Alternativas?.map((alternativa) => {
                return {
                  id: alternativa.id,
                  selecionada: quizPesquisaVisualizar.Respostas?.find((resposta) => resposta.alternativaId === alternativa.id),
                  texto: alternativa.alternativa,
                }
              }) as unknown as IQuizPerguntas['alternativas']
            }
            index={index}
          />
        ))}
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
