import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { VERSAO_BUILD } from '@/configurações/configuraçõesApi'
import { gapUi } from '@/configurações/estilização/medidas'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Flex, Image } from '@chakra-ui/react'

export const SobrePágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Sobre', isBotãoEsquerdo: true })

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi gap={gapUi['2xl']} alignItems="center">
        <Image src="/logo.png" alt="Logo" w="200px" />
        <Flex alignItems="center" gap="10px">
          <TextoSmUi>Versão atual:</TextoSmUi>
          <TextoSmUi>{VERSAO_BUILD}</TextoSmUi>
        </Flex>
        <TextoSmUi>© Copyright {new Date().getFullYear()} - Todos os direitos reservados</TextoSmUi>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
