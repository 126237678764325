import { marginUi, paddingUi } from '@/configurações/estilização/medidas'
import { UsuárioAutenticadoContexto } from '@/contextos/UsuárioAutenticado'
import { Box, Container, Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import { BarraMenu } from './BarraMenu/BarraMenu'

export const LayoutComMenu = () => {
  const isLoading = useContextSelector(UsuárioAutenticadoContexto, (value) => value.isLoading)
  if (isLoading) return <Outlet />

  return (
    <Container
      display="flex"
      width="full"
      height={`100vh`}
      alignItems="center"
      justifyContent="center"
      padding={0}
      paddingTop={paddingUi['5xl']}
    >
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="center"
        height="100%"
        justifyContent="space-between"
        overflowY="hidden"
      >
        <Box width="100%" overflowY="auto" marginBottom={marginUi.md}>
          <Outlet />
        </Box>
        <BarraMenu />
      </Flex>
    </Container>
  )
}
