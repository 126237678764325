import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useNotificacaoMarcarComoLida = () => {
  const { mutate: marcarComoLida, status: statusMarcarComoLida } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['notificacoes'])
      await queryClient.invalidateQueries(['notificacoes-count'])
    },
  })

  return { marcarComoLida, statusMarcarComoLida }
}

async function endPoint(data: { id: string }): Promise<boolean> {
  const result = await api().put(rotasApi.notificacaoMarcarComoLida, data)
  return result.data.data
}
