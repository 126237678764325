import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, paddingUi } from '@/configurações/estilização/medidas'
import { Button, ButtonProps } from '@chakra-ui/react'
import { TextoXsUi } from '../Textos/TextoXsUi'

export const BotãoCardUi = (props: IBotãoCardUiProps) => {
  const { inverterChildren, ...rest } = props
  return (
    <Button
      as={'span'}
      backgroundColor={coresUi.fundo.primaria}
      borderRadius={props.bordaArredondada ? props.bordaArredondada : borderRadiusUi.xl}
      width="fit-content"
      paddingX={paddingUi.xxl}
      height="32px"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      _hover={{}}
      _active={{}}
      _focus={{ transform: 'scale(0.95)' }}
      {...(inverterChildren && { flexDirection: 'row-reverse' })}
      {...rest}
    >
      <TextoXsUi color={coresUi.texto.primaria}>{props.texto}</TextoXsUi>
      {props.children && props.children}
    </Button>
  )
}

type ButtonPropsOmit = Omit<ButtonProps, 'paddingX' | 'paddingY' | 'borderRadius'>
interface IBotãoCardUiProps extends ButtonPropsOmit {
  texto: string
  inverterChildren?: boolean
  bordaArredondada?: number
}
