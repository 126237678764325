import EmojiPicker, { EmojiClickData, EmojiStyle, SuggestionMode, Theme } from 'emoji-picker-react'
import { useState } from 'react'

export const useEmojiPickerUi = (props: EmojiPickerUiProps) => {
  const [emojis, setEmojis] = useState<EmojiClickData[]>([])
  const [emoji, setEmoji] = useState<EmojiClickData | undefined>(undefined)

  const lidaComEmoji = (emoji: EmojiClickData) => {
    if (props.multiplos) setEmojis((emojis) => [...emojis, emoji])
    else setEmoji(emoji)
  }

  const EmojiRender = () => (
    <EmojiPicker
      emojiStyle={EmojiStyle.NATIVE}
      theme={Theme.DARK}
      width="100%"
      autoFocusSearch={false}
      emojiVersion={'5.0'}
      lazyLoadEmojis={true}
      previewConfig={{ showPreview: false }}
      onEmojiClick={lidaComEmoji}
      suggestedEmojisMode={SuggestionMode.FREQUENT}
    />
  )

  return { EmojiRender, emojis: props.multiplos ? emojis : [emoji], setEmojis, setEmoji }
}

interface EmojiPickerUiProps {
  multiplos?: boolean
}
