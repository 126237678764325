import { AutenticacaoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import { useQuery } from '@tanstack/react-query'

export const useMe = () => {
  const { setUsuárioAtivo } = useUsuárioAtivoAtom()
  const {
    data: dataMe,
    error: errorMe,
    status: statusMe,
    isLoading: isLoadingMe,
  } = useQuery({
    queryKey: ['me'],
    queryFn: endPoint,
    onSuccess: (data) => {
      setUsuárioAtivo(data)
    },
  })

  return { dataMe, errorMe, statusMe, isLoadingMe }
}

async function endPoint(): Promise<AutenticacaoModel> {
  const result = await api().get(rotasApi.me)
  return result.data.data
}
