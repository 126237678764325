import { rotasApp } from '@/configurações/rotasApp'
import { useNavigate } from 'react-router-dom'

export const useBackLogin = () => {
  const navigate = useNavigate()

  const backLogin = () => {
    localStorage.removeItem('matricula')
    localStorage.removeItem('instituicoes')
    localStorage.removeItem('email')
    navigate(rotasApp.login)
  }

  return { backLogin }
}
