import { configuraçõesApi } from '@/configurações/configuraçõesApi'
import { coresUi } from '@/configurações/estilização/cores'
import { Avatar, AvatarProps as AvatarPropsChakra, Flex } from '@chakra-ui/react'

export const _Avatar = (props: AvatarProps) => (
  <Flex
    width="fit-content"
    height="fit-content"
    borderRadius="900px"
    padding="2px"
    background="linear-gradient(to right, #f1cc1c, #bc112b, #de09bc,#bc112b)"
  >
    <Avatar
      name={props.nome?.trim()}
      src={configuraçõesApi.baseArquivo(props.src!)}
      size={props.size ?? 'md'}
      border={`3px solid ${coresUi.fundo.primaria}`}
    />
  </Flex>
)

interface AvatarProps {
  nome: string
  src: string
  size?: AvatarPropsChakra['size']
}
