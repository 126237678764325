import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { Img } from '@chakra-ui/react'
import { ExcluirContaForm } from './excluirContaForm'

export const ExcluirContaPágina = () => {
  return (
    <FlexColumnUi maxWidth={500} gap={gapUi['6xl']} padding={paddingUi.md}>
      <Img src="/logo.png" alt="Logo da empresa" width="250px" alignSelf="center" />
      <TextoXxlUi color={coresUi['primaria-ativo']} alignSelf="center" fontWeight="500">
        Excluir conta
      </TextoXxlUi>
      <TextoMdUi gridArea="descricao" lineHeight={lineHeightUi.xl}>
        Tem certeza de que deseja excluir sua conta? Isso resultará na perda de todo o seu progresso e pontuação na plataforma, e
        não será possível fazer login novamente.
      </TextoMdUi>
      <TextoMdUi gridArea="aviso" lineHeight={lineHeightUi.md} color={coresUi['primaria-ativo']}>
        Esta ação é irreversível!
      </TextoMdUi>

      <ExcluirContaForm />
    </FlexColumnUi>
  )
}
