import { coresUi } from '@/configurações/estilização/cores'
import { Button } from '@chakra-ui/react'
import { TextoSmUi } from '../Textos/TextoSmUi'

export const BotãoModalUi = (props: BotaoModalUiProps) => {
  return (
    <Button
      as="span"
      width="100%"
      height="42px"
      background={coresUi['primaria-ativo']}
      _active={{}}
      _hover={{}}
      _focus={{}}
      onClick={props.callback}
      gridArea={props.gridArea}
    >
      <TextoSmUi color={coresUi.texto['com-fundo-primário']} fontWeight="medium">
        {props.children}
      </TextoSmUi>
    </Button>
  )
}

interface BotaoModalUiProps {
  callback: () => void
  children: string
  gridArea?: string
}
