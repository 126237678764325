import {
  CardVisualizarQuizPesquisaResultadoUi,
  IQuizPerguntas,
} from '@/componentes/Card/CardVisualizarQuizPesquisaResultado/CardVisualizarQuizPesquisaResultadoUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { gapUi } from '@/configurações/estilização/medidas'
import { useQuizPesquisaVisualizar } from '@/hooks/API/quiz/useQuizPesquisaVisualizar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useParams } from 'react-router-dom'

export const HistóricoQuizPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Visualizar', isBotãoEsquerdo: true })

  const { id } = useParams()
  const { quizPesquisaVisualizar } = useQuizPesquisaVisualizar({ quizPesquisaId: id })

  return (
    <ContainerAppUi ResetCSS>
      {quizPesquisaVisualizar && (
        <FlexColumnUi gap={gapUi.lg}>
          {quizPesquisaVisualizar?.Perguntas?.map((pergunta, index) => (
            <CardVisualizarQuizPesquisaResultadoUi
              key={pergunta.id}
              pergunta={pergunta.pergunta}
              alternativas={
                pergunta.Alternativas?.map((alternativa) => {
                  return {
                    id: alternativa.id,
                    selecionada: alternativa.correta,
                    texto: alternativa.alternativa,
                    errada:
                      (quizPesquisaVisualizar.Respostas?.find((resposta) => resposta.alternativaId === alternativa.id)
                        ?.correta === false &&
                        quizPesquisaVisualizar.Respostas &&
                        quizPesquisaVisualizar.Respostas?.length > 0) ||
                      false,
                  }
                }) as unknown as IQuizPerguntas['alternativas']
              }
              index={index}
            />
          ))}
        </FlexColumnUi>
      )}
    </ContainerAppUi>
  )
}
