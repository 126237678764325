import { coresUi } from '@/configurações/estilização/cores'

export const progress = {
  baseStyle: {
    filledTrack: {
      bg: coresUi['primaria-ativo'],
    },
    track: {
      bg: coresUi['primaria-inativo'],
    },
  },
}
