import { Regex } from '@/utilitários/Regex'
import * as Yup from 'yup'
import { mensagemErroSchema } from './mensagemErroSchema'

const matrícula = Yup.string().label('Matrícula')
const email = Yup.string().matches(Regex.EMAIL, mensagemErroSchema.inválido('Email')).label('Email')
const cpf = Yup.string().matches(Regex.CPF_MASCARA, mensagemErroSchema.inválido('CPF')).label('CPF')
const senha = Yup.string().min(6, mensagemErroSchema.mínimo('Senha', 6)).max(20, mensagemErroSchema.máximo('Senha', 20))

export const abstractSchema = { matrícula, email, cpf, senha }
