import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { corTextoUi, coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useOuvidoria } from '@/hooks/API/ouvidoria/useOuvidoria'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useModalUi } from '@/hooks/useModalUi'
import { Button, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const OuvidoriaPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Ouvidoria', isBotãoEsquerdo: true })
  const { UiModal, onOpen } = useModalUi()
  const [valorTextArea, setValorTextArea] = useState('')

  const navigate = useNavigate()
  const MINIMO_CARACTERES = 50

  const { enviarMensagem, isLoadingEnviarMensagem, statusEnviarMensagem } = useOuvidoria()

  useEffect(() => {
    if (statusEnviarMensagem === 'success') onOpen()
  }, [statusEnviarMensagem])

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi gap={gapUi['5xl']}>
        <FlexColumnUi gap={gapUi.md}>
          <FormulárioUi.Container name="mensagem">
            <FormulárioUi.Label título="Formulário"></FormulárioUi.Label>
            <FormulárioUi.Textarea
              value={valorTextArea}
              onChange={({ target }) => setValorTextArea(target.value)}
              placeholder="Digite sua mensagem para a Ouvidoria"
              resize="none"
              minHeight="225px"
            />
          </FormulárioUi.Container>
          <Flex>
            <TextoXsUi color={corTextoUi.erro}>*</TextoXsUi>
            <TextoXsUi>Sua mensagem deve conter no mínimo {MINIMO_CARACTERES} caracteres.</TextoXsUi>
          </Flex>
        </FlexColumnUi>
        <Button
          width="100%"
          color={coresUi.texto['com-fundo-primário']}
          height="48px"
          background={coresUi['primaria-ativo']}
          fontWeight="500"
          _active={{}}
          _hover={{}}
          _focus={{}}
          borderRadius={borderRadiusUi.lg}
          onClick={() => enviarMensagem({ mensagem: valorTextArea })}
          isDisabled={valorTextArea.length < MINIMO_CARACTERES || isLoadingEnviarMensagem}
        >
          Enviar mensagem
        </Button>
        <UiModal
          callbackBotão={() => {
            setValorTextArea('')
            navigate(rotasApp.início)
          }}
          textoBotão="Entendido"
          descrição="Sua mensagem foi enviada com sucesso para a Ouvidoria!"
          título="Mensagem enviada!"
        />
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
