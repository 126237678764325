import { BotãoCardUi } from '@/componentes/Botões/BotãoCardUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { corPrimariaAtivoUi, coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useQuizStatus } from '@/hooks/API/quiz/useQuizStatus'
import { Flex, Icon, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { ClockCounterClockwise, GameController, Play, Strategy, Timer, Trophy } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { ResponseQuizPesquisaListar } from './useQuizPesquisaListar'
import { useEffect, useState } from 'react'

export const useAlertaModalQuizPesquisa = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false)
  const navigate = useNavigate()

  const AlertaModal = ({ quizPesquisaSelecionada }: { quizPesquisaSelecionada: ResponseQuizPesquisaListar | undefined }) => {
    const { quizStatus } = useQuizStatus({
      quizId: quizPesquisaSelecionada?.id,
      enabled: isOpen,
    })

    const respostasCount = quizStatus?.respostasCount
    const totalPerguntas = quizPesquisaSelecionada?.totalPerguntas

    useEffect(() => {
      if (quizPesquisaSelecionada && isPopupVisible) {
        onOpen()
      } else {
        onClose()
      }
    }, [quizPesquisaSelecionada])

    useEffect(() => {
      const lastClosedTime = localStorage.getItem('@Esforce:popupClosedTime')
      if (lastClosedTime) {
        const currentTime = new Date().getTime()
        const timeDifference = currentTime - parseInt(lastClosedTime, 10)
        if (timeDifference > 24 * 60 * 60 * 1000) {
          setIsPopupVisible(true)
        }
      } else {
        setIsPopupVisible(true)
      }
    }, [])

    const handleClosePopup = () => {
      setIsPopupVisible(false)
      localStorage.setItem('@Esforce:popupClosedTime', new Date().getTime().toString())
      onClose()
    }

    return (
      <Modal isOpen={isOpen} onClose={() => {}} isCentered={true} size={'xs'}>
        <ModalOverlay backdropFilter="auto" backdropBlur={`10px`} filter={`grayscale(0%)`} id="overlay-close-modal" />
        <ModalContent
          bg={coresUi['primaria-ativo']}
          borderRadius={borderRadiusUi.xxl}
          shadow="xs"
          padding={paddingUi['2xl']}
          alignItems="center"
          textAlign="center"
        >
          <>
            <FlexColumnUi gap={gapUi['4xl']}>
              <FlexColumnUi gap={gapUi.lg} alignItems="start">
                <TextoSmUi color={coresUi.texto['com-fundo-primário']}>
                  {quizPesquisaSelecionada?.tipo === 'quiz' ? 'Novo Quiz no Ar!' : 'Nova Pesquisa no Ar!'}
                </TextoSmUi>
                <TextoXxlUi fontWeight="medium" color={coresUi.texto['com-fundo-primário']}>
                  {quizPesquisaSelecionada?.tema}
                </TextoXxlUi>
              </FlexColumnUi>
              <FlexColumnUi gap={gapUi.md} alignItems="start">
                <Flex alignItems="center" gap={gapUi.md}>
                  <Icon as={GameController} color={coresUi.texto['com-fundo-primário']} />
                  <TextoSmUi color={coresUi.texto['com-fundo-primário']}>Total de perguntas: {totalPerguntas}</TextoSmUi>
                </Flex>
                {quizPesquisaSelecionada?.tipo === 'quiz' && (
                  <Flex alignItems="flex-start" gap={gapUi.md}>
                    <Icon as={Timer} color={coresUi.texto['com-fundo-primário']} />
                    <TextoSmUi color={coresUi.texto['com-fundo-primário']}>Tempo: 15s por questão</TextoSmUi>
                  </Flex>
                )}
                <Flex alignItems="center" gap={gapUi.md}>
                  <Icon as={Trophy} color={coresUi.texto['com-fundo-primário']} />
                  <TextoSmUi fontWeight="semibold" color={coresUi.texto['com-fundo-primário']}>
                    Pontuação: {quizPesquisaSelecionada?.pontuacao}
                  </TextoSmUi>
                </Flex>
                {quizPesquisaSelecionada?.tipo === 'quiz' && !!respostasCount && (
                  <Flex alignItems="center" gap={gapUi.md}>
                    <Icon as={Strategy} color={coresUi.texto['com-fundo-primário']} />
                    <TextoSmUi fontWeight="semibold" color={coresUi.texto['com-fundo-primário']}>
                      Progresso: {respostasCount}/{totalPerguntas}
                    </TextoSmUi>
                  </Flex>
                )}
              </FlexColumnUi>
              <Flex width="full" justifyContent="space-between">
                <BotãoCardUi texto="Fechar" onClick={handleClosePopup} />

                <BotãoCardUi
                  texto={(() => {
                    if (respostasCount === 0) return 'Iniciar'
                    else if (respostasCount === totalPerguntas) {
                      return 'Ver histórico'
                    } else return 'Continuar'
                  })()}
                  display="flex"
                  alignItems="center"
                  gap={gapUi.lg}
                  onClick={() => {
                    const finalizado = respostasCount === totalPerguntas
                    if (quizPesquisaSelecionada?.tipo === 'pesquisa' && !finalizado) {
                      return navigate(`${rotasApp.pesquisa}?pesquisaId=${quizPesquisaSelecionada?.id}`)
                    } else if (quizPesquisaSelecionada?.tipo === 'quiz' && !finalizado) {
                      return navigate(`${rotasApp.quiz}?quizId=${quizPesquisaSelecionada?.id}`)
                    } else if (finalizado) {
                      return navigate(rotasApp.histórico)
                    }
                  }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    {...(respostasCount !== totalPerguntas && {
                      background: coresUi.texto.primaria,
                    })}
                    borderRadius={borderRadiusUi.full}
                    width="18px"
                    height="18px"
                  >
                    {(() => {
                      if (respostasCount === 0) return <IconPlay />
                      else if (respostasCount === totalPerguntas)
                        return <Icon as={ClockCounterClockwise} color={corPrimariaAtivoUi} />
                      else return <IconPlay />
                    })()}
                  </Flex>
                </BotãoCardUi>
              </Flex>
            </FlexColumnUi>
          </>
        </ModalContent>
      </Modal>
    )
  }

  return {
    AlertaModal,
  }
}

const IconPlay = () => <Icon as={() => <Play weight="fill" size="12px" color={coresUi.texto['com-fundo-primário']} />} />
