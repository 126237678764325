import { coresUi } from '@/configurações/estilização/cores'
import { paddingUi } from '@/configurações/estilização/medidas'
import { AccordionButton, AccordionButtonProps, AccordionIcon } from '@chakra-ui/react'
import { ReactNode } from 'react'

export const Título = ({ children, ...rest }: { children: ReactNode } & AccordionButtonProps) => {
  return (
    <AccordionButton paddingY={paddingUi.xxl} paddingX={0} {...rest}>
      {children}

      <AccordionIcon color={coresUi['primaria-ativo']} />
    </AccordionButton>
  )
}
