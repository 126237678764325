import { coresUi } from '@/configurações/estilização/cores'
import { fontSizeUi, lineHeightUi } from '@/configurações/estilização/tipografia'
import { Text, TextProps } from '@chakra-ui/react'

export const TextoLabelFormulárioUi = (props: TextoLabelFormulárioUiProps) => (
  <Text
    color={coresUi.texto.primaria}
    margin={0}
    fontSize={fontSizeUi.sm}
    lineHeight={lineHeightUi.sm}
    fontWeight="400"
    userSelect="none"
    {...props}
  />
)

export interface TextoLabelFormulárioUiProps extends Omit<TextProps, 'fontSize'> {}
