import { BarraProgresso } from '@/componentes/BarraProgresso/BarraProgresso'
import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { CardOutlineUi } from '@/componentes/Card/CardOutline/CardOutlineUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { RadioUi } from '@/componentes/RadioUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { rotasApp } from '@/configurações/rotasApp'
import { usePesquisaProximaPergunta } from '@/hooks/API/quiz-pesquisa/usePesquisaProximaPergunta'
import { usePesquisaRespostaCriar } from '@/hooks/API/quizResposta/usePesquisaRespostaCriar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { toastUi } from '@/utilitários/toast'
import { Flex, Grid } from '@chakra-ui/react'
import { X } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { QuizLoadingPergunta } from '../responderQuiz/QuizLoadingPergunta'

export const PesquisaPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Responder Pesquisa',
    isBotãoEsquerdo: true,
    íconeDireita: X,
  })
  const [searchBarParams] = useSearchParams()
  const pesquisaId = searchBarParams.get('pesquisaId') || undefined
  const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const navigate = useNavigate()

  const { pesquisaProximaPerguntaIsLoading, pesquisaProximaPergunta } = usePesquisaProximaPergunta({
    pesquisaId,
  })

  const { enviarRespostasPesquisa, isLoadingEnviarRespostasPesquisa, pesquisaRespostasContext, statusEnviarRespostasPesquisa } =
    usePesquisaRespostaCriar()
  const { setValue, watch } = pesquisaRespostasContext

  useEffect(() => {
    if (!pesquisaProximaPergunta) return
    setValue('perguntaId', pesquisaProximaPergunta.id)
  }, [JSON.stringify(pesquisaProximaPergunta)])

  useEffect(() => {
    if (statusEnviarRespostasPesquisa === 'success') {
      if (pesquisaProximaPergunta?.indice === pesquisaProximaPergunta?.total) {
        toastUi({
          title: 'Parabéns! Você completou a pesquisa',
          description: 'Você poderá ver sua pontuação no histórico',
          status: 'success',
          duration: null,
        })
        navigate(rotasApp.início)
      } else {
        navigate(0)
      }
    }
  }, [statusEnviarRespostasPesquisa])

  if (!pesquisaProximaPergunta) return <QuizLoadingPergunta hidden={false} />

  const lidaComClickAlternativa = (alternativaId: string) => {
    const selectedAlternativas = watch('alternativasIds') || []
    const isSelected = selectedAlternativas.includes(alternativaId)

    if (isSelected) {
      setValue(
        'alternativasIds',
        selectedAlternativas.filter((id) => id !== alternativaId),
      )
    } else {
      setValue('alternativasIds', [...selectedAlternativas, alternativaId])
    }
  }

  return (
    <ContainerAppUi position="relative" ResetCSS>
      <QuizLoadingPergunta hidden={!pesquisaProximaPerguntaIsLoading} />

      <FlexColumnUi gap={gapUi['2xl']}>
        <FlexColumnUi gap={gapUi.lg}>
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <TextoMdUi fontWeight="medium">{`${pesquisaProximaPergunta.indice}/${pesquisaProximaPergunta.total}`}</TextoMdUi>
          </Flex>
          <BarraProgresso
            corBarra={coresUi['primaria-ativo']}
            progressoEmPorcentagem={(pesquisaProximaPergunta.indice / pesquisaProximaPergunta.total) * 100}
          />
        </FlexColumnUi>
        <FormProvider {...pesquisaRespostasContext}>
          <FormulárioUi.Form callback={enviarRespostasPesquisa}>
            <FlexColumnUi gap={gapUi['2xl']}>
              <FlexColumnUi gap={gapUi.sm}>
                <Flex gap={gapUi.sm}>
                  {<TextoMdUi>{pesquisaProximaPergunta.indice}.</TextoMdUi>}
                  <TextoMdUi lineHeight={lineHeightUi.lg}>{pesquisaProximaPergunta.pergunta}</TextoMdUi>
                </Flex>
                <CardOutlineUi>
                  {pesquisaProximaPergunta.alternativas.sort().map((alternativa, index) => (
                    <Grid
                      key={alternativa.id}
                      alignItems="start"
                      gap="10px"
                      width="full"
                      gridTemplateColumns={'15px 1fr'}
                      onClick={() => lidaComClickAlternativa(alternativa.id)}
                      cursor="pointer"
                    >
                      <RadioUi selecionado={watch('alternativasIds')?.includes(alternativa.id)} respondendo />

                      <TextoMdUi lineHeight={lineHeightUi.lg}>
                        {letras.charAt(index)}. {alternativa.alternativa}
                      </TextoMdUi>
                    </Grid>
                  ))}
                </CardOutlineUi>
                <FormulárioUi.Container name="alternativasIds" textAlign="center">
                  <FormulárioUi.MensagemErro />
                </FormulárioUi.Container>
              </FlexColumnUi>

              <BotãoFormulárioUi isLoading={isLoadingEnviarRespostasPesquisa} type="submit">
                {pesquisaProximaPergunta?.indice === pesquisaProximaPergunta?.total ? 'Finalizar' : 'Continuar'}
              </BotãoFormulárioUi>
            </FlexColumnUi>
          </FormulárioUi.Form>
        </FormProvider>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
