import { AlunoModel } from '@/@types/Models'
import { api } from '@/api/api'
import Yup from '@/utilitários/Yup/Yup'
import { abstractSchema } from '@/utilitários/Yup/abstractSchema'
import { mensagemErroSchema } from '@/utilitários/Yup/mensagemErroSchema'
import { responseError, responseSuccess } from '@/utilitários/handler/responseHandler'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { rotasApiAutenticadoV5 } from '../../../configurações/rotasApi/rotasApiAutenticadoV5'

const { path } = rotasApiAutenticadoV5.completarVerificacao

export const useAlunoCadastroPrimeiroAcesso = () => {
  const {
    mutate: cadastroAluno,
    isLoading: cadastroAlunoIsLoading,
    status: cadastroAlunoStatus,
  } = useMutation(endPoint, {
    onSuccess: () => {
      responseSuccess('Cadastro realizado')
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const cadastroAlunoContext = useForm({
    resolver: yupResolver(cadastroAlunoSchema),
    reValidateMode: 'onSubmit',
  })

  return {
    cadastroAluno,
    cadastroAlunoContext,
    cadastroAlunoIsLoading,
    cadastroAlunoStatus,
  }
}

async function endPoint(data: CadastroAlunoSchema): Promise<AlunoModel> {
  const result = await api().post(path, data)
  return result.data.data
}

export const cadastroAlunoSchema = Yup.object({
  matricula: abstractSchema.matrícula.required(mensagemErroSchema.required),
  instituicaoId: Yup.string().required('Informe uma instituição'),
  email: abstractSchema.email.required(mensagemErroSchema.required).when('isMenorIdade', {
    is: true,
    then: (schema) =>
      schema
        .required(mensagemErroSchema.required)
        .test('email-diferente', 'O e-mail do aluno deve ser diferente do e-mail do responsável', function (value) {
          const alunoEmail = this.resolve(Yup.ref('emailResponsável'))
          return value !== alunoEmail
        }),
  }),
  senha: abstractSchema.senha.required(mensagemErroSchema.required).label('Senha'),
  confirmacaoSenha: Yup.string()
    .required('campo obrigatorio')
    .label('Confirmar senha')
    .test('senha-diferente', 'A senha deve ser igual ao informado no campo anterior', function (value) {
      const password = this.resolve(Yup.ref('senha'))
      return value === password
    }),
})

export type CadastroAlunoSchema = Yup.InferType<typeof cadastroAlunoSchema>
