import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { useMostrarSenha } from '@/componentes/CompositionsPatterns/Formulário/useMostrarSenha'
import { gapUi } from '@/configurações/estilização/medidas'
import { fontSizeUi } from '@/configurações/estilização/tipografia'
import { useDeletarPublicoAluno } from '@/hooks/API/aluno/usePublicoDeletarAluno'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { Box, Flex } from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'

export const ExcluirContaForm = () => {
  const { context, mutate } = useDeletarPublicoAluno()
  const { MostrarSenha, verSenha } = useMostrarSenha()

  return (
    <FormProvider {...context}>
      <FormulárioUi.Form callback={mutate}>
        <Flex width="100%" flexDirection="column" gap={gapUi['4xl']}>
          <Flex width="100%" flexDirection="column" gap={gapUi.xl}>
            <FormulárioUi.Container name="email">
              <FormulárioUi.Label título="Email" />
              <FormulárioUi.Input placeholder="Digite seu email" autoComplete="email" />
              <FormulárioUi.MensagemErro />
            </FormulárioUi.Container>
            <FormulárioUi.Container name="senha">
              <FormulárioUi.Label título="Senha" />
              <Box position="relative">
                <FormulárioUi.Input
                  placeholder="Digite sua senha"
                  autoComplete="current-password"
                  type={verSenha ? 'text' : 'password'}
                  fontSize={fontSizeUi.md}
                />
              </Box>
              <MostrarSenha />
              <FormulárioUi.MensagemErro />
            </FormulárioUi.Container>
          </Flex>
          <Flex width="100%" flexDirection="column" gap={gapUi.xl}>
            <BotãoFormulárioUi isLoading={false} type="submit" isDisabled={isErrosFormulário(context.formState.errors)}>
              Excluir Conta
            </BotãoFormulárioUi>
          </Flex>
        </Flex>
      </FormulárioUi.Form>
    </FormProvider>
  )
}
