export const fontFamilyUi = {
  /** # Montserrat, sans-serif */ primary: 'Montserrat, sans-serif',
}

const fontSizeBasesUi = { xs: 12, sm: 14, md: 16, lg: 18, xl: 20, xxl: 24, '2xl': 32 }

export const fontSizeUi = {
  /** # 12px */ xs: `${fontSizeBasesUi.xs}px`,
  /** # 14px */ sm: `${fontSizeBasesUi.sm}px`,
  /** # 16px */ md: `${fontSizeBasesUi.md}px`,
  /** # 18px */ lg: `${fontSizeBasesUi.lg}px`,
  /** # 20px */ xl: `${fontSizeBasesUi.xl}px`,
  /** # 24px */ xxl: `${fontSizeBasesUi.xxl}px`,
  /** # 32px */ '2xl': `${fontSizeBasesUi['2xl']}px`,
}

export const lineHeightUi = {
  /** # 12px */ xs: `${fontSizeBasesUi.xs}px`,
  /** # 14px */ sm: `${fontSizeBasesUi.sm}px`,
  /** # 16px */ md: `${fontSizeBasesUi.md}px`,
  /** # 18px */ lg: `${fontSizeBasesUi.lg}px`,
  /** # 20px */ xl: `${fontSizeBasesUi.xl}px`,
  /** # 24px */ xxl: `${fontSizeBasesUi.xxl}px`,
  /** # 32px */ '2xl': `${fontSizeBasesUi['2xl']}px`,
}
