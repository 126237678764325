import { ReforceComentarioModel } from '@/@types/Models'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresUi } from '@/configurações/estilização/cores'
import { paddingUi } from '@/configurações/estilização/medidas'
import { DateBR } from '@/utilitários/DateBR'
import { Flex } from '@chakra-ui/react'
import moment from 'moment'

export const _Comentario = (props: ComentariosProps) => {
  return (
    <Flex
      width="100%"
      height="fit-content"
      alignItems="center"
      padding="5px 0"
      justifyContent="space-between"
      paddingX={paddingUi.xl}
    >
      <Flex gap="5px">
        <TextoSmUi color={coresUi.texto.primaria} fontWeight="bold">
          {props.Comentario.Aluno?.nome}
        </TextoSmUi>
        <TextoSmUi color={coresUi.texto.primaria} fontWeight="thin">
          {props.Comentario.comentario}
        </TextoSmUi>
      </Flex>
      <TextoXsUi color={coresUi['secundaria-ativo']} style={{ fontSize: '10px' }}>
        {moment(props.Comentario.criadoEm).from(DateBR.dataNova())}
      </TextoXsUi>
    </Flex>
  )
}

interface ComentariosProps {
  Comentario: ReforceComentarioModel
}
