import { coresUi } from '@/configurações/estilização/cores'
import { Flex, Grid, Icon } from '@chakra-ui/react'
import { CaretLeft, Icon as IconProps } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { TextoMdUi } from './Textos/TextoMdUi'
import { TextoSmUi } from './Textos/TextoSmUi'

export const NavegaçãoUi = (props: INavegaçãoProps) => {
  const { callbackBotãoDireito, isBotãoEsquerdo, título, íconeDireito } = props
  const navigate = useNavigate()
  return (
    <Grid alignItems="center" justifyContent="space-between" width="100%" gridTemplateColumns="repeat(3,1fr)" paddingBottom={0}>
      <Flex alignItems="center" justifySelf="start" onClick={() => navigate(-1)} paddingY="16px">
        {isBotãoEsquerdo && (
          <>
            <Icon as={CaretLeft} color={coresUi['primaria-ativo']} />
            <TextoSmUi color={coresUi['primaria-ativo']}>Voltar</TextoSmUi>
          </>
        )}
      </Flex>
      <TextoMdUi fontWeight="medium" justifySelf="center" width="max-content">
        {título}
      </TextoMdUi>
      <Flex alignItems="center" justifySelf="end" onClick={callbackBotãoDireito}>
        {íconeDireito && <Icon as={íconeDireito} color={coresUi['primaria-ativo']} />}
      </Flex>
    </Grid>
  )
}

interface INavegaçãoProps {
  isBotãoEsquerdo?: boolean
  callbackBotãoDireito?: () => void
  título?: string
  íconeDireito?: IconProps
}
