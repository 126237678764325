import { CardQuizPesquisasUi } from '@/componentes/Card/CardQuizPesquisasUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { ScrollHorizontalUi } from '@/componentes/ScrollHorizontalUi/ScrollHorizontalUi'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresCard, coresUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { ResponseBaseQuizPesquisaRecentes, useQuizPesquisaRecentes } from '@/hooks/API/quiz-pesquisa/useQuizPesquisaRecentes'
import { corAleatóriaSequencial } from '@/utilitários/corAleatóriaSequencial'
import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModalConferirQuizPesquisa } from '../../quizPesquisas/useModalConferirQuizPesquisa'
import { useAlertaModalQuizPesquisa } from '../../quizPesquisas/useAlertaModalQuizPesquisa'
export const QuizPesquisas = () => {
  const navigate = useNavigate()
  const { dataQuizzesPesquisasRecentes } = useQuizPesquisaRecentes()
  const [quizPesquisaSelecionada, setQuizPesquisaSelecionada] = useState<ResponseBaseQuizPesquisaRecentes | undefined>()
  const { ModalConferir, onOpen } = useModalConferirQuizPesquisa()
  const { AlertaModal } = useAlertaModalQuizPesquisa()

  useEffect(() => {
    if (dataQuizzesPesquisasRecentes) {
      setQuizPesquisaSelecionada(dataQuizzesPesquisasRecentes[0])
    }
  }, [dataQuizzesPesquisasRecentes])

  return (
    <>
      <ModalConferir quizPesquisaSelecionada={quizPesquisaSelecionada} />
      <AlertaModal quizPesquisaSelecionada={quizPesquisaSelecionada} />

      <Flex width="100%" flexDirection="column" gap={gapUi.lg}>
        <ContainerAppUi>
          <Flex width="full" justifyContent="space-between" alignItems="center">
            <TextoMdUi>Quiz / Pesquisas</TextoMdUi>
            <TextoXsUi color={coresUi['primaria-ativo']} onClick={() => navigate(rotasApp.quizPesquisas)}>
              Ver todos
            </TextoXsUi>
          </Flex>
        </ContainerAppUi>
        {dataQuizzesPesquisasRecentes?.length === 0 && <SvgSemDados />}
        <ScrollHorizontalUi id="scroll-horizontal-quiz">
          {dataQuizzesPesquisasRecentes?.map((quizPesquisa, index) => {
            return (
              <Flex
                {...(dataQuizzesPesquisasRecentes.length - 1 === index && {
                  paddingRight: paddingUi.xl,
                })}
                key={quizPesquisa.id}
              >
                <CardQuizPesquisasUi
                  onClickBotão={() => {
                    setQuizPesquisaSelecionada(quizPesquisa)
                    onOpen()
                  }}
                  width="200px"
                  variação={'aberto'}
                  texto={quizPesquisa.tipo === 'quiz' ? 'Novo Quiz no Ar!' : 'Nova Pesquisa no Ar!'}
                  conteúdo={quizPesquisa.tema}
                  background={corAleatóriaSequencial({ index, cores: coresCard })}
                />
              </Flex>
            )
          })}
        </ScrollHorizontalUi>
      </Flex>
    </>
  )
}
