import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useRecuperarSenha } from '@/hooks/API/autenticação/useRecuperarSenha'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useModalUi } from '@/hooks/useModalUi'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const RecuperarSenhaMatrículaPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Recuperar senha', isBotãoEsquerdo: true })

  const navigate = useNavigate()
  const storage = new LocalStorage()
  const { recuperarSenha, recuperarSenhaContext, recuperarSenhaIsLoading, recuperarSenhaStatus, recuperarSenhaData } =
    useRecuperarSenha()

  const { formState } = recuperarSenhaContext || {}
  const { errors } = formState || {}

  const { UiModal, onOpen } = useModalUi()

  useEffect(() => {
    if (recuperarSenhaStatus === 'success' && recuperarSenhaData) {
      onOpen()
      storage.set('email', recuperarSenhaData.email)
    }
  }, [recuperarSenhaStatus, JSON.stringify(recuperarSenhaData)])

  return (
    <ContainerAppUi ResetCSS>
      <UiModal
        callbackBotão={() => navigate(rotasApp.recuperarSenhaCódigo)}
        textoBotão="Entendido"
        descrição={`Enviamos um código de 6 dígitos para o e-mail ${recuperarSenhaData?.email}. Informe esse código para concluir sua alteração.`}
        título="Código de recuperação enviado com sucesso!"
      />

      <FlexColumnUi gap={gapUi['4xl']}>
        <FlexColumnUi gap={gapUi.md}>
          <TextoXxlUi fontWeight="medium" color={coresUi['primaria-ativo']}>
            Recuperar senha
          </TextoXxlUi>
          <TextoSmUi>Informe seu email para receber o código de recuperação da senha.</TextoSmUi>
        </FlexColumnUi>
        <FormProvider {...recuperarSenhaContext}>
          <FormulárioUi.Form callback={recuperarSenha}>
            <FlexColumnUi gap={gapUi['4xl']}>
              <FormulárioUi.Container name="email">
                <FormulárioUi.Label título="Email" />
                <FormulárioUi.Input placeholder="aluno@dominio.com" />
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <BotãoFormulárioUi
                isLoading={false}
                type="submit"
                isDisabled={isErrosFormulário(errors) || recuperarSenhaIsLoading}
              >
                {recuperarSenhaIsLoading ? 'Carregando...' : 'Continuar'}
              </BotãoFormulárioUi>
            </FlexColumnUi>
          </FormulárioUi.Form>
        </FormProvider>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
