import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { Img } from '@chakra-ui/react'
import { FlexColumnUi } from './FlexColumnUi'
import { TextoMdUi } from './Textos/TextoMdUi'

export const SvgSemDados = () => {
  return (
    <FlexColumnUi paddingY={paddingUi['5xl']} width="100%" justifyContent="center" alignItems="center" gap={gapUi.xl}>
      <TextoMdUi>Nada por aqui...</TextoMdUi>
      <Img src="/undraw_empty.svg" width="300px" />
    </FlexColumnUi>
  )
}
