import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import Yup from '@/utilitários/Yup/Yup'
import { responseError } from '@/utilitários/handler/responseHandler'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'

export const usePesquisaRespostaCriar = () => {
  const pesquisaRespostasContext = useForm<Schema>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const {
    mutate: enviarRespostasPesquisa,
    isLoading: isLoadingEnviarRespostasPesquisa,
    status: statusEnviarRespostasPesquisa,
  } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
  })

  return {
    enviarRespostasPesquisa,
    isLoadingEnviarRespostasPesquisa,
    pesquisaRespostasContext,
    statusEnviarRespostasPesquisa,
  }
}

async function endPoint(data: Schema): Promise<void> {
  const result = await api().post(rotasApi.pesquisaRepostaCriar, data)
  return result.data.data
}

const schema = Yup.object().shape({
  alternativasIds: Yup.array()
    .of(Yup.string())
    .min(1, ' É necessário selecionar pelo menos uma alternativa.')
    .required('É necessário selecionar pelo menos uma alternativa.'),
  perguntaId: Yup.string().required().label('Pergunta Id'),
})

type Schema = Yup.InferType<typeof schema>
