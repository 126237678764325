export const coresUi = {
  'primaria-ativo': '#E9FF1A',
  'primaria-inativo': '#748100',
  'secundaria-ativo': '#CC4090',
  'secundaria-inativo': '#611942',
  texto: {
    primaria: '#ECEDEE',
    secundaria: '#b6b6b6',
    placeholder: '#3b3b3b',
    'com-fundo-primário': '#242424',
    erro: '#F44E4E',
    info: '#05A2C2',
    sucesso: '#28A745',
  },
  fundo: {
    black: '#393939',
    primaria: '#0F0F0F',
    secundaria: '#424242',
    erro: '#291415',
    info: '#073844',
  },
  borda: {
    input: '#424242',
    erro: '#3C181A',
    info: '#064150',
    card: '#424242',
  },
  void: '#FF9417',
  gradiente: {
    rosa: 'linear-gradient(129.88deg, #1B1536 17.88%, #8E1F5F 109.35%)',
    azul: 'linear-gradient(129.88deg, #1E173C 13.69%, #00677C 109.35%)',
    vermelho: 'linear-gradient(129.88deg, #1B1536 17.88%, #C52626 109.35%)',
    verde: 'linear-gradient(129.88deg, #1E173C 13.69%, #00923A 109.35%)',
    cinza: 'linear-gradient(129.88deg, #1F1F1F 17.88%, #3a3a3a 109.35%)',
  },
  icones: {
    'lixeira-foto': '#F44E4E',
  },
}

export const corTextoUi = coresUi.texto
export const corFundoUi = coresUi.fundo
export const corBordaUi = coresUi.borda
export const corIconesUi = coresUi.icones
export const corGradienteUi = coresUi.gradiente
export const corPrimariaAtivoUi = coresUi['primaria-ativo']
export const corPrimariaInativoUi = coresUi['primaria-inativo']
export const corSecundariaAtivoUi = coresUi['secundaria-ativo']
export const corSecundariaInativoUi = coresUi['secundaria-inativo']
export const coresCard = [coresUi.gradiente.azul, coresUi.gradiente.verde, coresUi.gradiente.vermelho, coresUi.gradiente.rosa]
