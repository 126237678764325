import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { corPrimariaAtivoUi, corSecundariaAtivoUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { DateBR } from '@/utilitários/DateBR'
import { Flex } from '@chakra-ui/react'
import moment from 'moment'
import { _Avatar } from './Avatar'

export const _Header = (props: HeaderProps) => (
  <Flex width="full" alignItems="center" justifyContent="space-between" paddingX={paddingUi.xl}>
    <Flex alignItems="center" gap={gapUi.md}>
      <_Avatar nome={props.data.alunoNome as string} src={props.data.alunoAvatarUrl as string} />
      <FlexColumnUi gap={gapUi.xs}>
        <TextoSmUi color={corPrimariaAtivoUi} fontWeight="bold">
          {props.data.alunoNome}
        </TextoSmUi>
        <TextoXsUi color={corPrimariaAtivoUi} fontWeight="thin" fontStyle="italic">
          {props.data.instituicaoNome}
        </TextoXsUi>
      </FlexColumnUi>
    </Flex>
    <TextoXsUi color={corSecundariaAtivoUi}>{moment(props.data.criadoEm).from(DateBR.dataNova())}</TextoXsUi>
  </Flex>
)

interface HeaderProps {
  data: {
    alunoNome: string
    alunoAvatarUrl: string
    instituicaoNome: string
    criadoEm?: Date
  }
}
