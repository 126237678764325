import { InstituicaoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { pathAuth } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useInstituicao = () => {
  const { data } = useQuery({
    queryKey: ['instituição'],
    queryFn: async () => await endPoint(),
  })

  return { data }
}

async function endPoint(): Promise<InstituicaoModel> {
  const result = await api().get(`/${pathAuth}/instituicao/aluno`)
  return result.data.data
}
