export const colors = {
  primary: {
    1: '#fdfcfe',
    2: '#fbfaff',
    3: '#f5f2ff',
    4: '#ede9fe',
    5: '#e4defc',
    6: '#d7cff9',
    7: '#c4b8f3',
    8: '#aa99ec',
    9: '#6e56cf',
    10: '#644fc1',
    11: '#5746af',
    12: '#20134b',
    dark1: '#17151f',
    dark2: '#1c172b',
    dark3: '#251e40',
    dark4: '#2c2250',
    dark5: '#32275f',
    dark6: '#392c72',
    dark7: '#443592',
    dark8: '#5842c3',
    dark9: '#6e56cf',
    dark10: '#7c66dc',
    dark11: '#9e8cfc',
    dark12: '#f1eefe',
  },
  success: {
    1: '#fbfefc',
    2: '#f2fcf5',
    3: '#e9f9ee',
    4: '#ddf3e4',
    5: '#ccebd7',
    6: '#b4dfc4',
    7: '#92ceac',
    8: '#5bb98c',
    9: '#30a46c',
    10: '#299764',
    11: '#18794e',
    12: '#153226',
    dark1: '#0d1912',
    dark2: '#0c1f17',
    dark3: '#0f291e',
    dark4: '#113123',
    dark5: '#133929',
    dark6: '#164430',
    dark7: '#1b543a',
    dark8: '#236e4a',
    dark9: '#30a46c',
    dark10: '#3cb179',
    dark11: '#4cc38a',
    dark12: '#e5fbeb',
  },
  info: {
    1: '#fafdfe',
    2: '#f2fcfd',
    3: '#e7f9fb',
    4: '#d8f3f6',
    5: '#c4eaef',
    6: '#aadee6',
    7: '#84cdda',
    8: '#3db9cf',
    9: '#05a2c2',
    10: '#0894b3',
    11: '#0c7792',
    12: '#04313c',
    dark1: '#07191d',
    dark2: '#061e24',
    dark3: '#072830',
    dark4: '#07303b',
    dark5: '#073844',
    dark6: '#064150',
    dark7: '#045063',
    dark8: '#00647d',
    dark9: '#05a2c2',
    dark10: '#00b1cc',
    dark11: '#00c2d7',
    dark12: '#e1f8fa',
  },
  warning: {
    1: '#fdfdf9',
    2: '#fffce8',
    3: '#fffbd1',
    4: '#fff8bb',
    5: '#fef2a4',
    6: '#f9e68c',
    7: '#efd36c',
    8: '#ebbc00',
    9: '#f5d90a',
    10: '#f7ce00',
    11: '#946800',
    12: '#35290f',
    dark1: '#1c1500',
    dark2: '#221a00',
    dark3: '#2c2100',
    dark4: '#352800',
    dark5: '#3e3000',
    dark6: '#493c00',
    dark7: '#594a05',
    dark8: '#705e00',
    dark9: '#f5d90a',
    dark10: '#ffef5c',
    dark11: '#f0c000',
    dark12: '#fffad1',
  },
  danger: {
    1: '#fffcfc',
    2: '#fff8f8',
    3: '#ffefef',
    4: '#ffe5e5',
    5: '#fdd8d8',
    6: '#f9c6c6',
    7: '#f3aeaf',
    8: '#eb9091',
    9: '#e5484d',
    10: '#dc3d43',
    11: '#cd2b31',
    12: '#381316',
    dark1: '#1f1315',
    dark2: '#291415',
    dark3: '#3c181a',
    dark4: '#481a1d',
    dark5: '#541b1f',
    dark6: '#671e22',
    dark7: '#822025',
    dark8: '#aa2429',
    dark9: '#e5484d',
    dark10: '#f2555a',
    dark11: '#ff6369',
    dark12: '#feecee',
  },
  gray: {
    1: '#fdfcfd',
    2: '#f9f8f9',
    3: '#f4f2f4',
    4: '#eeedef',
    5: '#e9e8ea',
    6: '#e4e2e4',
    7: '#dcdbdd',
    8: '#c8c7cb',
    9: '#908e96',
    10: '#86848d',
    11: '#6f6e77',
    12: '#1a1523',
    dark1: '#161618',
    dark2: '#1c1c1f',
    dark3: '#232326',
    dark4: '#28282c',
    dark5: '#2e2e32',
    dark6: '#34343a',
    dark7: '#3e3e44',
    dark8: '#504f57',
    dark9: '#706f78',
    dark10: '#7e7d86',
    dark11: '#a09fa6',
    dark12: '#ededef',
  },
}
