import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { Regex } from '@/utilitários/Regex'
import Yup from '@/utilitários/Yup/Yup'
import { responseError } from '@/utilitários/handler/responseHandler'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'

export const useRecuperarSenha = () => {
  const {
    mutate: recuperarSenha,
    isLoading: recuperarSenhaIsLoading,
    status: recuperarSenhaStatus,
    data: recuperarSenhaData,
  } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
  })

  const recuperarSenhaContext = useForm({
    resolver: yupResolver(recuperarSenhaSchema),
    reValidateMode: 'onChange',
  })

  return {
    recuperarSenha,
    recuperarSenhaIsLoading,
    recuperarSenhaContext,
    recuperarSenhaStatus,
    recuperarSenhaData,
  }
}

async function endPoint(data: RecuperarSenhaSchema): Promise<{ email: string }> {
  const result = await api().post(rotasApi.recuperarSenha, data)
  return result.data.data
}

const recuperarSenhaSchema = Yup.object({
  email: Yup.string().matches(Regex.EMAIL, 'E-mail inválido').required(),
})

type RecuperarSenhaSchema = Yup.InferType<typeof recuperarSenhaSchema>
