import { coresUi } from '@/configurações/estilização/cores'
import { fontFamilyUi } from '@/configurações/estilização/tipografia'

export const styles = {
  global: () => ({
    'html, body': {
      bg: coresUi.fundo.primaria,
      color: coresUi.texto.primaria,
      height: '100vh',
      width: '100vw',
      fontFamily: fontFamilyUi.primary,
    },

    '::-webkit-scrollbar': {
      display: 'none',
      width: '5px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '999px',
      display: 'none',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
      display: 'none',
    },

    '& .jotai-devtools-UnstyledButton-root': {
      background: 'transparent !important',
      '& img': {
        position: 'absolute',
        left: '10px !important',
        bottom: '-8px !important',
        height: '17px !important',
        opacity: '0.4 !important',
      },
    },

    '& .btn-react-query-dev-tools': {
      position: 'absolute !important',
      left: '-10px !important',
      bottom: '-20px !important',
      width: '20px !important',
      opacity: '0.4 !important',
    },
  }),
}
