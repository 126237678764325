import { borderRadiusUi } from '@/configurações/estilização/medidas'
import { Box, ChakraProps, Image, ImageProps } from '@chakra-ui/react'

export const ImagemProdutoUi = ({ src, boxProps, imagemProps }: ImagemProdutoUiProps) => (
  <Box position="relative" width="100%" height="auto" paddingTop="75%" overflow="hidden" {...boxProps}>
    <Image
      src={src}
      position="absolute"
      top={0}
      left={0}
      width="full"
      height="full"
      objectFit="cover"
      objectPosition="center"
      borderRadius={borderRadiusUi.xxl}
      {...imagemProps}
    />
  </Box>
)
interface ImagemProdutoUiProps {
  imagemProps?: ImageProps
  boxProps?: ChakraProps
  src: string
}
