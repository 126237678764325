import { PremioModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const usePremios = (condição?: Atributos) => {
  const { data: premios } = useQuery({
    queryKey: ['premios', condição],
    queryFn: async () => notaListar(condição),
  })

  return { premios }
}

async function notaListar(condição?: Atributos): Promise<PremioModel[]> {
  const result = await api().get(rotasApi.premioListar, {
    headers: { where: JSON.stringify(condição) || '{}' },
  })
  return result.data.data
}

interface Atributos {
  nome?: string
  selecionados?: string[]
  escolhidos?: string[]
}
