export const Regex = {
  MAIÚSCULO: /[A-Z]/,
  MINÚSCULO: /[a-z]/,
  CARACTERE_ESPECIAL: /\W|_/,
  NÚMERO: /[0-9]/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  'DD/MM/YYYY':
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g,

  RG_MASCARA: /\d{2,2}.\d{3,3}.\d{3,3}-\d{1,1}/g,
  TELEFONE_MASCARA: /\(\d{2,2}\).\d{4,4}-\d{4,4}/g,
  CELULAR_MASCARA: /\(\d{2,2}\).\d{5,5}-\d{4,4}/g,
  CEP_MASCARA: /\d{5,5}-\d{3,3}/g,
  CPF_MASCARA: /\d{3,3}.\d{3,3}.\d{3,3}-\d{2,2}/g,
  CNPJ_MASCARA: /\d{2,2}.\d{3,3}.\d{3,3}\/\d{4,4}-\d{2,2}/g,
}
