import { SlidePaginacaoUi } from '@/componentes/Slide/SlidePaginacaoUi'
import { aspectRatioUi, borderRadiusUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useBannerListarRecentes } from '@/hooks/API/banner/useBannerListarRecentes'
import { Flex, Image } from '@chakra-ui/react'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

export const CarrosselPrêmios = () => {
  const { banners } = useBannerListarRecentes()
  const navigate = useNavigate()

  return (
    <Flex width="full" justifyContent="stretch">
      {!!banners?.length && (
        <AutoPlaySwipeableViews interval={5000} slideStyle={{ padding: '0 16px', width: '100vw', maxWidth: '635px' }}>
          {banners?.map((banner, index, arr) => (
            <Fragment key={banner.id}>
              <Flex
                position="relative"
                width="100%"
                height="0"
                paddingBottom={aspectRatioUi['16:9']}
                overflow="hidden"
                borderRadius={borderRadiusUi.lg}
                onClick={() => {
                  if (banner.irPara === 'link') window.open(banner.link, '_blank')
                  else if (banner.irPara === 'premio') {
                    navigate(rotasApp.visualizarPrêmio(banner.premioId!))
                  }
                }}
              >
                <Flex position="absolute" top="0" left="0" width="100%" height="100%" alignItems="center" justifyContent="center">
                  <Image
                    src={banner.arquivoLink}
                    maxWidth="100%"
                    width="100%"
                    height="100%"
                    maxHeight="100%"
                    objectFit={banner.arquivoLink ? 'cover' : 'contain'}
                  />
                </Flex>
              </Flex>

              <SlidePaginacaoUi index={index} array={arr} />
            </Fragment>
          ))}
        </AutoPlaySwipeableViews>
      )}
    </Flex>
  )
}
