import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { UsuárioAutenticadoContexto } from '@/contextos/UsuárioAutenticado'
import { IContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Flex } from '@chakra-ui/react'
import { Icon } from '@phosphor-icons/react'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import { ContainerAppUi } from './ContainerAppUi'
import { FlexColumnUi } from './FlexColumnUi'
import { NavegaçãoUi } from './NavegaçãoUi'

export const LayoutComNavegação = () => {
  const [isBotãoEsquerdo, setIsBotãoEsquerdo] = useState<boolean>(false)
  const [callbackBotãoDireito, setCallbackBotãoDireito] = useState<() => void>()
  const [íconeDireita, setÍconeDireita] = useState<Icon | undefined>(undefined)
  const [título, setTítulo] = useState<string>('')

  const contextoOutlet: IContextoOutletLayoutComNavegação = {
    setCallbackBotãoDireito,
    setIsBotãoEsquerdo,
    setTítulo,
    setÍconeDireita,
  }

  const isLoading = useContextSelector(UsuárioAutenticadoContexto, (value) => value.isLoading)
  if (isLoading) return <Outlet />

  return (
    <FlexColumnUi
      height="100vh"
      alignItems="center"
      overflow="hidden"
      paddingBottom={paddingUi.xxl}
      paddingTop={paddingUi['3xl']}
      maxWidth="500px"
      margin="0 auto"
    >
      <ContainerAppUi>
        <NavegaçãoUi
          callbackBotãoDireito={callbackBotãoDireito}
          isBotãoEsquerdo={isBotãoEsquerdo}
          título={título}
          íconeDireito={íconeDireita}
        />
      </ContainerAppUi>

      <Flex width="full" gap={gapUi.lg} flexDirection="column" height="100%" overflow="auto" paddingBottom={paddingUi['3xl']}>
        <Outlet context={contextoOutlet} />
      </Flex>
    </FlexColumnUi>
  )
}
