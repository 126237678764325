import { corPrimariaAtivoUi } from '@/configurações/estilização/cores'
import { Icon } from '@chakra-ui/react'
import { Eye, EyeSlash } from '@phosphor-icons/react'
import { useCallback } from 'react'
import { BotãoÍconeInput } from './BotãoÍconeInput'

interface BotãoInputSenhaProps {
  verSenha: boolean
  callback: () => void
}

export const BotãoInputSenha = (props: BotãoInputSenhaProps) => {
  const RenderÍconeSenha = useCallback(() => {
    if (props.verSenha) {
      return (
        <BotãoÍconeInput onClick={props.callback}>
          <Icon as={EyeSlash} fontSize="18px" color={corPrimariaAtivoUi} />
        </BotãoÍconeInput>
      )
    }
    return (
      <BotãoÍconeInput onClick={props.callback}>
        <Icon as={Eye} fontSize="18px" color={corPrimariaAtivoUi} />
      </BotãoÍconeInput>
    )
  }, [props.verSenha])

  return <RenderÍconeSenha />
}
