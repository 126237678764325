import { api } from '@/api/api'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useOuvidoria = () => {
  const { mutate, isLoading, status } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
  })

  return {
    enviarMensagem: mutate,
    isLoadingEnviarMensagem: isLoading,
    statusEnviarMensagem: status,
  }
}

async function endPoint(data: { mensagem: string }): Promise<boolean> {
  const result = await api().post('/autenticado/ouvidoria', data)
  return result.data.data
}
