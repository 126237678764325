import { PopupModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useEffect, useState } from 'react'

export const usePopupMostrar = () => {
  const [popupData, setPopupData] = useState<PopupModel[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await endPoint()
        setPopupData(data)
      } catch (error) {
        console.error('Erro ao buscar dados do popup:', error)
      }
    }
    fetchData()
  }, [])

  return { popupsList: popupData }
}

async function endPoint(): Promise<PopupModel[]> {
  const result = await api().get(rotasApi.popupMostrar)
  return result.data.data
}
