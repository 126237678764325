import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, paddingUi } from '@/configurações/estilização/medidas'
import { fontSizeUi } from '@/configurações/estilização/tipografia'
import { Textarea as TextareaChakra, TextareaProps as TextareaPropsChakra } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { useContextSelector } from 'use-context-selector'
import { ContainerContexto } from './Container'

export const Textarea = (props: TextareaProps) => {
  const formContext = useFormContext()
  const { register } = formContext || {}

  const required = useContextSelector(ContainerContexto, (value) => value.required)
  const name = useContextSelector(ContainerContexto, (value) => value.name)
  const disabled = useContextSelector(ContainerContexto, (value) => value.disabled)

  return (
    <TextareaChakra
      id={name}
      {...(register ? register(name, { required }) : {})}
      readOnly={disabled}
      borderColor={coresUi.borda.input}
      borderRadius={borderRadiusUi.lg}
      padding={paddingUi.xl}
      _placeholder={{ color: coresUi.texto.secundaria, fontSize: fontSizeUi.sm }}
      {...props}
    />
  )
}

interface TextareaProps extends TextareaPropsChakra {}
