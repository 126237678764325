import { BackgroundRadial } from '@/componentes/BackgroundRadial'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { Texto2xlUi } from '@/componentes/Textos/Texto2xlUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, marginUi } from '@/configurações/estilização/medidas'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Button, Card, Flex, Img } from '@chakra-ui/react'
import { X } from '@phosphor-icons/react'

export const BoasVindasPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Boas vindas',
    íconeDireita: X,
  })

  return (
    <ContainerAppUi ResetCSS>
      <BackgroundRadial />
      <FlexColumnUi justifyContent="space-between" height="full">
        <Img src="/se.svg" alt="se" width="195px" alignSelf="center" marginTop={marginUi['10xl']} />
        <FlexColumnUi gap={gapUi['6xl']}>
          <FlexColumnUi gap={gapUi['3xl']}>
            <Texto2xlUi color={coresUi['secundaria-ativo']}>Olá, Ana!</Texto2xlUi>
            <Texto2xlUi color={coresUi.texto.primaria}>Aqui seu mérito aparece.</Texto2xlUi>
          </FlexColumnUi>
          <FlexColumnUi gap={gapUi.xl}>
            <TextoSmUi>
              Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
              literature from 45 BC, making it over 2000 years old.
            </TextoSmUi>
            <Flex gap={gapUi.sm}>
              <Card width="15px" height="6px" background="white" />
              <Card width="6px" height="6px" background="white" />
              <Card width="6px" height="6px" background="white" />
              <Card width="6px" height="6px" background="white" />
            </Flex>
          </FlexColumnUi>
          <Button
            color={coresUi.texto['com-fundo-primário']}
            width="100%"
            height="48px"
            background={coresUi['primaria-ativo']}
            fontWeight="medium"
            borderRadius={borderRadiusUi.lg}
          >
            Avançar
          </Button>
        </FlexColumnUi>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
