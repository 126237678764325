import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { Card, CardProps, Flex } from '@chakra-ui/react'
import { BotãoCardUi } from '../Botões/BotãoCardUi'
import { TextoLgUi } from '../Textos/TextoLgUi'
import { TextoXsUi } from '../Textos/TextoXsUi'

export const CardQuizPesquisasUi = (props: CardQuizPesquisasInícioProps) => {
  const { variação, texto, conteúdo, onClickBotão, background, ...rest } = props
  return (
    <Card
      background={props.variação === 'fechado' ? coresUi.fundo.secundaria : background}
      borderRadius={borderRadiusUi['2xl']}
      padding={paddingUi.xl}
      gap={gapUi['2xl']}
      justifyContent="space-between"
      {...rest}
    >
      <Flex flexDirection="column" gap={gapUi.lg}>
        <TextoXsUi
          fontWeight="normal"
          fontStyle="italic"
          color={variação === 'aberto' ? coresUi.texto.primaria : coresUi.texto.primaria}
        >
          {texto}
        </TextoXsUi>
        <TextoLgUi color={variação === 'aberto' ? coresUi.texto.primaria : coresUi.texto.primaria} fontWeight="medium">
          {conteúdo}
        </TextoLgUi>
      </Flex>

      <BotãoCardUi onClick={onClickBotão} texto={variação === 'aberto' ? 'Conferir' : 'Visualizar'} />
    </Card>
  )
}

interface CardQuizPesquisasInícioProps extends CardProps {
  texto: string
  conteúdo: string
  background: string
  variação: 'aberto' | 'fechado'
  width?: string
  onClickBotão: () => void
}
