import { LogoIcon } from '@/assets/LogoIcon'
import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, paddingUi, íconeSizeUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { MenuAtivoType, useMenuAtivoAtom } from '@/hooks/jotai/useMenuAtivoAtom'
import { Flex, Icon } from '@chakra-ui/react'
import { ChartBar, House, Icon as IconType, Trophy } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { TextoXsUi } from '../Textos/TextoXsUi'

interface IMenuItem {
  itemAtivo: MenuAtivoType
  ícone: IconType
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

export const BarraMenu = () => {
  const { menuAtivo } = useMenuAtivoAtom()
  const navigate = useNavigate()

  const MenuItem = ({ itemAtivo, ícone, ...rest }: IMenuItem) => {
    return (
      <Flex
        cursor="pointer"
        color={menuAtivo === itemAtivo ? coresUi['primaria-ativo'] : coresUi['primaria-inativo']}
        flexDirection="column"
        alignItems="center"
        {...rest}
      >
        <Icon as={ícone} fontSize={íconeSizeUi.menu} />
        <TextoXsUi color={menuAtivo === itemAtivo ? coresUi['primaria-ativo'] : coresUi['primaria-inativo']}>
          {itemAtivo}
        </TextoXsUi>
      </Flex>
    )
  }

  return (
    <Flex
      width="100%"
      justifyContent="space-around"
      padding={paddingUi.md}
      paddingBottom="25px"
      borderRadius={borderRadiusUi['2xl']}
    >
      <MenuItem itemAtivo="Início" ícone={House} onClick={() => navigate(rotasApp.início)} />
      <MenuItem itemAtivo="Pontos" ícone={ChartBar} onClick={() => navigate(rotasApp.pontos)} />
      <MenuItem itemAtivo="Prêmios" ícone={Trophy} onClick={() => navigate(rotasApp.prêmio)} />
      <MenuItem itemAtivo="reForce" ícone={LogoIcon} onClick={() => navigate(rotasApp.reforce)} />
    </Flex>
  )
}
