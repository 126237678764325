import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoXlUi } from '@/componentes/Textos/TextoXlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { Spinner } from '@chakra-ui/react'

export const QuizLoadingPergunta = (props: QuizLoadingPerguntaProps) => (
  <FlexColumnUi
    bg="rgba(0, 0, 0, 0.85)"
    backdropFilter="auto"
    backdropBlur={`5px`}
    filter={`grayscale(70%)`}
    position="fixed"
    left={0}
    top={0}
    width="100vw"
    height="100vh"
    zIndex={9}
    justifyContent="center"
    alignItems="center"
    gap={gapUi.lg}
    hidden={props.hidden}
  >
    <TextoXlUi>Carregando pergunta</TextoXlUi>
    <Spinner color={coresUi['primaria-ativo']} />
  </FlexColumnUi>
)

interface QuizLoadingPerguntaProps {
  hidden?: boolean
}
