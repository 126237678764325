import { PremioModel } from '@/@types/Models'
import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Divisor } from '@/componentes/Divisor/Divisor'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { usePrêmioBuscarAlunoPrêmio } from '@/hooks/API/premio/usePrêmioBuscarAlunoPrêmio'
import { usePremios } from '@/hooks/API/premio/usePrêmios'
import { useResgatarPremios } from '@/hooks/API/premio/useResgatarPremios'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useModalUi } from '@/hooks/useModalUi'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { Flex, Icon, Image } from '@chakra-ui/react'
import { Minus, Plus } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IItemCarrinhoLocalStorage } from './VisualizarPrêmio.página'

export const CarrinhoPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Carrinho', isBotãoEsquerdo: true })
  const navigate = useNavigate()
  const { UiModal: UiModalResgate, onOpen: onOpenResgate } = useModalUi()
  const { usuárioAtivo } = useUsuárioAtivoAtom()
  const localStorage = new LocalStorage()

  const [carrinhoLocal, setCarrinhoLocal] = useState<IItemCarrinhoLocalStorage[]>(
    JSON.parse(localStorage.get('carrinho') as string) || [],
  )

  const tempCarrinhoLocal = carrinhoLocal.map((item) => ({ id: item.id }))
  const { premios } = usePremios({
    escolhidos: tempCarrinhoLocal?.length > 0 ? tempCarrinhoLocal.map((i) => i.id) : ['-'],
  })

  const obterCarrinhoLocalPorId = (id: string): IItemCarrinhoLocalStorage => {
    const carrinhoLocal: IItemCarrinhoLocalStorage[] = JSON.parse(localStorage.get('carrinho') as string) || []
    return (carrinhoLocal.find((itemLocal) => itemLocal.id === id) || []) as IItemCarrinhoLocalStorage
  }
  const { resgatarPremios, statusResgatarPremios } = useResgatarPremios()
  const handleResgatarPremios = () => {
    resgatarPremios(carrinhoLocal)
  }

  useEffect(() => {
    if (statusResgatarPremios === 'success') {
      localStorage.set('carrinho', JSON.stringify([]))
      setCarrinhoLocal([])
      onOpenResgate()
    }
  }, [statusResgatarPremios])

  const saldoTotalCarrinho =
    premios?.reduce((acc, item) => acc + (obterCarrinhoLocalPorId(item.id)?.quantidade || 0) * item.valorEmPontos, 0) || 0
  const saldoTotalConta = usuárioAtivo?.Perfil?.Aluno?.saldoEmPontos || 0

  const removerQuantidade = ({ id }: { id: string }) => {
    const carrinhoLocal: IItemCarrinhoLocalStorage[] = JSON.parse(localStorage.get('carrinho') as string) || []
    let quantidade = carrinhoLocal.find((itemLocal) => itemLocal.id === id)!.quantidade

    if (quantidade <= 1) {
      carrinhoLocal.splice(
        carrinhoLocal.findIndex((itemLocal) => itemLocal.id === id),
        1,
      )
      localStorage.set('carrinho', JSON.stringify(carrinhoLocal))
      setCarrinhoLocal(carrinhoLocal)
      return
    }
    carrinhoLocal.find((itemLocal) => itemLocal.id === id)!.quantidade--
    localStorage.set('carrinho', JSON.stringify(carrinhoLocal))
    setCarrinhoLocal(carrinhoLocal)
  }

  const adicionarQuantidade = ({ id }: { id: string }) => {
    const carrinhoLocal: IItemCarrinhoLocalStorage[] = JSON.parse(localStorage.get('carrinho') as string) || []

    carrinhoLocal.find((itemLocal) => itemLocal.id === id)!.quantidade++
    localStorage.set('carrinho', JSON.stringify(carrinhoLocal))
    setCarrinhoLocal(carrinhoLocal)
  }

  return (
    <ContainerAppUi ResetCSS>
      <UiModalResgate
        callbackBotão={() => navigate(rotasApp.prêmio)}
        textoBotão="Entendido"
        descrição="Seu resgate foi efetuado com sucesso!"
        título="Parabéns!"
      />
      <FlexColumnUi justifyContent="space-between" height="full" gap={gapUi['2xl']} userSelect="none">
        <FlexColumnUi gap={gapUi['6xl']}>
          {premios?.map((premio) => (
            <RenderPrêmio
              key={premio.id}
              premio={premio}
              removerQuantidade={removerQuantidade}
              adicionarQuantidade={adicionarQuantidade}
              obterCarrinhoLocalPorId={obterCarrinhoLocalPorId}
            />
          ))}
          <Divisor />
          <Flex width="100%" justifyContent="space-between">
            <FlexColumnUi gap={gapUi.sm}>
              <TextoSmUi color={coresUi['primaria-ativo']}>Valor do resgate</TextoSmUi>
              <TextoSmUi fontWeight="medium">
                {saldoTotalCarrinho} ponto
                {saldoTotalCarrinho ? 's' : ''}
              </TextoSmUi>
            </FlexColumnUi>
            <FlexColumnUi gap={gapUi.sm} alignItems="end">
              <TextoSmUi color={coresUi['primaria-ativo']}>Seu saldo</TextoSmUi>
              <TextoSmUi fontWeight="medium">
                {saldoTotalConta} ponto
                {saldoTotalConta !== 1 ? 's' : ''}
              </TextoSmUi>
            </FlexColumnUi>
          </Flex>
        </FlexColumnUi>
        <FlexColumnUi gap={gapUi.xl}>
          <BotãoFormulárioUi
            isLoading={statusResgatarPremios === 'loading'}
            callback={handleResgatarPremios}
            {...((saldoTotalConta < saldoTotalCarrinho || !premios?.length || statusResgatarPremios === 'loading') && {
              isDisabled: true,
            })}
          >
            {saldoTotalConta < saldoTotalCarrinho ? 'Saldo insuficiente' : 'Finalizar resgate'}
          </BotãoFormulárioUi>
        </FlexColumnUi>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}

const RenderPrêmio = (props: RenderPrêmioProps) => {
  const { premio, removerQuantidade, adicionarQuantidade, obterCarrinhoLocalPorId } = props
  const { prêmioBuscarAluno } = usePrêmioBuscarAlunoPrêmio({ premioId: premio.id })

  return (
    <Flex key={premio.id} gap={gapUi['2xl']}>
      <Flex maxWidth="50%">
        <Image borderRadius={borderRadiusUi.md} src={premio.Fotos?.[0].arquivoLink || ''} objectFit="cover" />
      </Flex>
      <FlexColumnUi justifyContent="start" gap={gapUi.md}>
        <TextoSmUi color={coresUi['primaria-ativo']}>{premio.nome}</TextoSmUi>
        <FlexColumnUi gap={gapUi.xl}>
          <FlexColumnUi gap={gapUi.sm}>
            <Flex
              width="110px"
              height="48px"
              justifyContent="space-around"
              border={`1.5px solid ${coresUi.borda.input}`}
              alignItems="center"
              borderRadius={borderRadiusUi.lg}
            >
              <Icon
                color={coresUi['primaria-ativo']}
                fontSize="15px"
                as={Minus}
                onClick={() => removerQuantidade({ id: premio.id })}
              />
              <TextoMdUi color={coresUi['primaria-ativo']}>{obterCarrinhoLocalPorId(premio.id)?.quantidade || 0}</TextoMdUi>
              <Icon
                color={coresUi['primaria-ativo']}
                fontSize="15px"
                as={Plus}
                {...((obterCarrinhoLocalPorId(premio.id)?.quantidade || 0) <
                premio?.limitePorAluno - (prêmioBuscarAluno?.quantidade || 0)
                  ? { onClick: () => adicionarQuantidade({ id: premio.id }) }
                  : {
                      opacity: 0.5,
                      cursor: 'not-allowed',
                    })}
              />
            </Flex>
            <TextoXsUi>Máximo: {premio?.limitePorAluno - (prêmioBuscarAluno?.quantidade || 0)}</TextoXsUi>
          </FlexColumnUi>
          <TextoSmUi fontWeight="medium">
            {(obterCarrinhoLocalPorId(premio.id)?.quantidade || 0) * premio.valorEmPontos} ponto
            {(obterCarrinhoLocalPorId(premio.id)?.quantidade || 0) * premio.valorEmPontos >= 2 ? 's' : ''}
          </TextoSmUi>
        </FlexColumnUi>
      </FlexColumnUi>
    </Flex>
  )
}

interface RenderPrêmioProps {
  premio: PremioModel
  removerQuantidade: ({ id }: { id: string }) => void
  adicionarQuantidade: ({ id }: { id: string }) => void
  obterCarrinhoLocalPorId: (id: string) => IItemCarrinhoLocalStorage
}
