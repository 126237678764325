import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { BotãoInputSenha } from '@/componentes/Botões/BotãoInputSenha'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { rotasApp } from '@/configurações/rotasApp'
import { useRedefinirSenha } from '@/hooks/API/autenticação/useRedefinirSenha'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useModalUi } from '@/hooks/useModalUi'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { Box } from '@chakra-ui/react'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const RecuperarSenhaNovaSenhaPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Recuperar senha' })

  const { isLoadingRedefinirSenha, redefinirSenha, redefinirSenhaSenhaContext, statusRedefinirSenha } = useRedefinirSenha()

  const { formState, watch, setValue } = redefinirSenhaSenhaContext
  const { errors } = formState
  const { UiModal, onOpen } = useModalUi()
  const navigate = useNavigate()
  const storage = new LocalStorage()

  const verSenhaWatch = watch('verSenha')
  const cbSenha = () => {
    setValue('verSenha', !verSenhaWatch)
  }

  const verConfirmarSenhaWatch = watch('verConfirmarSenha')
  const cbConfirmarSenha = () => {
    setValue('verConfirmarSenha', !verConfirmarSenhaWatch)
  }

  useEffect(() => {
    if (statusRedefinirSenha === 'success') {
      onOpen()
    }
  }, [statusRedefinirSenha])

  useEffect(() => {
    setValue('matricula', storage.get('matricula'))
    setValue('codigoAcesso', storage.get('codigoAcesso'))
  }, [])

  return (
    <ContainerAppUi ResetCSS>
      <UiModal
        callbackBotão={() => navigate(rotasApp.login)}
        textoBotão="Entendido"
        descrição="Sua nova senha de acesso foi criada com sucesso. Utilize-a para acessar sua conta."
        título="Tudo certo!"
      />
      <FlexColumnUi gap={gapUi['4xl']}>
        <FlexColumnUi gap={gapUi.md}>
          <TextoXxlUi fontWeight="medium" color={coresUi['primaria-ativo']}>
            Nova senha de acesso
          </TextoXxlUi>
          <TextoSmUi lineHeight={lineHeightUi.xl}>Estamos quase lá! Digite abaixo sua nova senha.</TextoSmUi>
        </FlexColumnUi>
        <FormProvider {...redefinirSenhaSenhaContext}>
          <FormulárioUi.Form callback={redefinirSenha}>
            <FlexColumnUi gap={gapUi['4xl']}>
              <FormulárioUi.Container name="senha">
                <FormulárioUi.Label título="Nova senha" />
                <Box position="relative">
                  <FormulárioUi.Input
                    autoComplete="current-password"
                    placeholder="Digite sua senha"
                    type={verSenhaWatch ? 'text' : 'password'}
                  />
                  <BotãoInputSenha verSenha={verSenhaWatch} callback={cbSenha} />
                </Box>
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="confirmarSenha">
                <FormulárioUi.Label título="Confirme sua senha" />
                <Box position="relative">
                  <FormulárioUi.Input
                    autoComplete="current-password"
                    placeholder="Digite sua senha"
                    type={verConfirmarSenhaWatch ? 'text' : 'password'}
                  />
                  <BotãoInputSenha verSenha={verConfirmarSenhaWatch} callback={cbConfirmarSenha} />
                </Box>
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <BotãoFormulárioUi
                isLoading={false}
                type="submit"
                isDisabled={isErrosFormulário(errors) || isLoadingRedefinirSenha}
              >
                Redefinir senha
              </BotãoFormulárioUi>
            </FlexColumnUi>
          </FormulárioUi.Form>
        </FormProvider>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
