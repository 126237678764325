import { Cabeçalho } from '@/componentes/Cabeçalho/Cabeçalho'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi, íconeSizeUi } from '@/configurações/estilização/medidas'
import { useMenuAtivo } from '@/hooks/useMenuAtivo'
import { CSSReset, Flex, Icon } from '@chakra-ui/react'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { CarrosselPrêmios } from './CarrosselPrêmios'
import { QuizPesquisas } from './QuizPesquisas'
import { Serviços } from './Serviços'
import { Popup } from '@/componentes/Popup/Popup'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { rotasApp } from '../../../../configurações/rotasApp'
import { useAlertaModalPerfil } from '../../meuPerfil/MeuPerfil/useAlertaModalPerfil'

export const InícioPágina = () => {
  useMenuAtivo('Início')
  const navigate = useNavigate()
  const { AlertaModal } = useAlertaModalPerfil()
  const formContext = useForm<{
    busca: string
  }>()
  const buscaWatch = formContext.watch('busca')

  function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      localStorage.setItem('searchParam', buscaWatch)
      return navigate(rotasApp.prêmio)
    }
  }

  function hanldeToPremios() {
    localStorage.setItem('searchParam', buscaWatch)
    return navigate(rotasApp.prêmio)
  }
  return (
    <>
      <AlertaModal />
      <CSSReset />
      <Flex width="100%" bg={coresUi.fundo.primaria} height="100%" alignItems="center" flexDirection="column" gap={gapUi['3xl']}>
        <Cabeçalho />
        <CarrosselPrêmios />
        <ContainerAppUi flexDirection="column" gap="8">
          <FormProvider {...formContext}>
            <FormulárioUi.Container name="busca" position="relative">
              <FormulárioUi.Input placeholder="Buscar seu prêmio favorito..." onKeyDown={(event) => onKeyPress(event)} />
              <Icon
                as={MagnifyingGlass}
                position="absolute"
                right="12px"
                top="14px"
                color={coresUi['primaria-ativo']}
                fontSize={íconeSizeUi.padrão}
                onClick={hanldeToPremios}
                cursor="pointer"
              />
            </FormulárioUi.Container>
          </FormProvider>
        </ContainerAppUi>
        <Serviços />

        <QuizPesquisas />
      </Flex>
      <Popup />
    </>
  )
}
