import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: true }, mutations: { retry: 0 } },
})

const TIMEOUT_SEGUNDOS = 30
const IP = import.meta.env.VITE_BACK_HOST
const PORTA = import.meta.env.VITE_BACK_PORT
const PATH = import.meta.env.VITE_BACK_PATH
const PATH_ARQUIVO = import.meta.env.VITE_BACK_PATH_ARQUIVO
export const VERSAO_BUILD = import.meta.env.VITE_VERSAO_BUILD

export const configuraçõesApi = {
  ip: IP,
  port: PORTA,
  path: PATH,
  pathArquivo: PATH_ARQUIVO,
  baseArquivo: (arquivoUrl: string) => `${IP}${PORTA}${PATH_ARQUIVO}/${arquivoUrl}`,

  timeout: 1000 * TIMEOUT_SEGUNDOS,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
}
