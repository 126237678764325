import { PremioAlunoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const usePrêmioBuscarAlunoPrêmio = (props: UsePrêmioBuscarAlunoPrêmioProps) => {
  const { premioId } = props
  const { data: prêmioBuscarAluno } = useQuery({
    queryKey: [`prêmioBuscarAluno-${premioId}`, premioId],
    queryFn: async () => endPoint(premioId),
    enabled: !!premioId,
  })

  return { prêmioBuscarAluno }
}

async function endPoint(premioId?: string): Promise<PremioAlunoModel> {
  const result = await api().get(rotasApi.premioAlunoBuscarAlunoPremio(premioId))
  if (!result?.data?.data) return {} as PremioAlunoModel
  return result.data.data
}

interface UsePrêmioBuscarAlunoPrêmioProps {
  premioId?: string
}
