import { AvatarUsuárioAtivo } from '@/componentes/AvatarUsuárioAtivo'
import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { BotãoInputSenha } from '@/componentes/Botões/BotãoInputSenha'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { gapUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useAtualizarSenha } from '@/hooks/API/useAtualizarSenha'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useModalUi } from '@/hooks/useModalUi'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { Box } from '@chakra-ui/react'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const AlterarSenhaPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Alterar senha', isBotãoEsquerdo: true })

  const { atualizarSenhaContext, atualizarSenha, atualizarSenhaStatus, atualizarSenhaIsLoading } = useAtualizarSenha()
  const { watch, setValue, formState } = atualizarSenhaContext
  const { errors } = formState

  const alterarSenhaWatch = watch('alterarSenha')
  const verSenhaAtualWatch = watch('verSenhaAtual')
  const cbVerSenhaAtual = () => setValue('verSenhaAtual', !verSenhaAtualWatch)
  const verSenhaNovaWatch = watch('verSenhaNova')
  const cbVerSenhaNova = () => setValue('verSenhaNova', !verSenhaNovaWatch)
  const verConfirmarSenhaNovaWatch = watch('verConfirmarSenhaNova')
  const cbVerConfirmarSenhaNova = () => setValue('verConfirmarSenhaNova', !verConfirmarSenhaNovaWatch)

  const { UiModal, onOpen } = useModalUi()
  const navigate = useNavigate()

  useEffect(() => {
    if (atualizarSenhaStatus === 'success') onOpen()
  }, [atualizarSenhaStatus])

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi gap={gapUi['6xl']}>
        <UiModal
          título="Tudo certo!"
          descrição="Sua senha de acesso foi alterada com sucesso. Utilize-a para acessar sua conta"
          textoBotão="Entendido"
          callbackBotão={() => navigate(rotasApp.meuPerfil)}
        />
        <AvatarUsuárioAtivo alignSelf="center" />
        <FormProvider {...atualizarSenhaContext}>
          <FormulárioUi.Form callback={atualizarSenha}>
            <FlexColumnUi gap={gapUi.xl}>
              <FormulárioUi.Container name="senhaAtual" required={alterarSenhaWatch}>
                <FormulárioUi.Label título="Senha atual" />
                <Box position="relative">
                  <FormulárioUi.Input placeholder="*******" type={verSenhaAtualWatch ? 'text' : 'password'} />
                  <BotãoInputSenha verSenha={verSenhaAtualWatch} callback={cbVerSenhaAtual} />
                </Box>
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="senhaNova" required={alterarSenhaWatch}>
                <FormulárioUi.Label título="Nova senha" />
                <Box position="relative">
                  <FormulárioUi.Input placeholder="*******" type={verSenhaNovaWatch ? 'text' : 'password'} />
                  <BotãoInputSenha verSenha={verSenhaNovaWatch} callback={cbVerSenhaNova} />
                </Box>
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="confirmarSenhaNova" required={alterarSenhaWatch}>
                <FormulárioUi.Label título="Confirmar nova senha" />
                <Box position="relative">
                  <FormulárioUi.Input placeholder="*******" type={verConfirmarSenhaNovaWatch ? 'text' : 'password'} />
                  <BotãoInputSenha verSenha={verConfirmarSenhaNovaWatch} callback={cbVerConfirmarSenhaNova} />
                </Box>
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <BotãoFormulárioUi isLoading={atualizarSenhaIsLoading} type="submit" isDisabled={isErrosFormulário(errors)}>
                Salvar alterações
              </BotãoFormulárioUi>
            </FlexColumnUi>
          </FormulárioUi.Form>
        </FormProvider>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
