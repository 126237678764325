import { Flex, Progress } from '@chakra-ui/react'
import { TextoSmUi } from '../Textos/TextoSmUi'

export const RankingBarraProgresso: React.FC<RankingBarraProgressoProps> = ({ local, colocacao, progresso }) => {
  return (
    <Flex flexDirection="column" gap={1}>
      <Flex justifyContent="space-between">
        <TextoSmUi>{local}</TextoSmUi>
        <TextoSmUi># {colocacao}</TextoSmUi>
      </Flex>
      <Progress value={progresso} borderRadius={30} h={2} />
    </Flex>
  )
}

interface RankingBarraProgressoProps {
  local: string
  colocacao: number
  progresso: number
}
