import { ListaExpansívelUi } from '@/componentes/CompositionsPatterns/ListaExpansível/ListaExpansívelUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { coresUi } from '@/configurações/estilização/cores'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { opacidadeCorHexadecimal } from '@/utilitários/opacidadeCorHexadecimal'

export const PerguntasFrequentesPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Perguntas frequentes', isBotãoEsquerdo: true })

  return (
    <ContainerAppUi ResetCSS>
      <ListaExpansívelUi.Container width="full">
        {perguntasFrequentes.map((perguntaFrequente, index) => (
          <ListaExpansívelUi.Item
            key={index}
            {...(index === 0 && { borderTop: 0 })}
            {...(index === perguntasFrequentes.length - 1 && { borderBottom: 0 })}
          >
            <ListaExpansívelUi.Título justifyContent="space-between">
              <TextoSmUi textAlign="left" fontWeight="semibold" lineHeight={lineHeightUi.lg}>
                {perguntaFrequente.pergunta}
              </TextoSmUi>
            </ListaExpansívelUi.Título>
            <ListaExpansívelUi.Conteúdo>
              <TextoSmUi
                textAlign="left"
                lineHeight={lineHeightUi.lg}
                color={`${coresUi.texto.primaria}${opacidadeCorHexadecimal('80%')}`}
              >
                {perguntaFrequente.resposta}
              </TextoSmUi>
            </ListaExpansívelUi.Conteúdo>
          </ListaExpansívelUi.Item>
        ))}
      </ListaExpansívelUi.Container>
    </ContainerAppUi>
  )
}

const perguntasFrequentes = [
  {
    pergunta: 'Como troco meus pontos?',
    resposta:
      'Os pontos conquistados são utilizados na própria plataforma, podendo receber os prêmios em casa ou retirando nos parceiros credenciados.',
  },
  {
    pergunta: 'Se eu reprovar eu perco meus pontos?',
    resposta:
      'Sim, os pontos são trocados bimestralmente e só valem para notas acima da média. Caso seja reprovado por falta por exemplo, os pontos serão perdidos em todas as matérias.',
  },
  {
    pergunta: 'Levei uma advertência e agora?',
    resposta:
      'O ideal é que isso não aconteça, por hora não terá penalidades, mas futuramente isso pode acontecer. Então, foque nos estudos e seja um aluno acima da média e veja seus méritos ganharem prêmios.',
  },
  {
    pergunta: 'Eu consigo dar meus pontos para alguém?',
    resposta: 'Não. A plataforma foca em você aluno que se esforça e por isso não podemos compartilhar nossos pontos.',
  },
  {
    pergunta: 'Meus pais precisam concordar com os termos para que eu possa acessar os prêmios?',
    resposta:
      'Se você for menor de idade, sim. Após efetuar o cadastro em nosso sistema, as informações serão confirmadas no banco de dados e seus pais ou responsável receberão um e-mail e/ou sms para autorizar o uso. Isso é válido somente para menores de idade.',
  },
  {
    pergunta: 'Fui expulso e agora?',
    resposta: 'Infelizmente os pontos serão zerados automaticamente. Nossa plataforma é uma forma de incentivo ao aluno.',
  },
  {
    pergunta: 'Meus pontos expiram?',
    resposta:
      'Sim. Os pontos são inseridos e utilizados anualmente, ao final de cada ano os pontos são zerados. Caso haja algo excepcional comunicaremos através do próprio aplicativo.',
  },
  {
    pergunta: 'Atraso nas mensalidades cancelam minha conta?',
    resposta:
      'Sim, a sua conta ficará congelada até que seja confirmado o pagamento ou negociação. Isso só vale para instituições privadas.',
  },
  {
    pergunta: 'Como funciona o Quiz?',
    resposta:
      'Para cada Quiz é atribuído pontos e prazo de validade para responder. Ao final do prazo, para os alunos que acertarem todas as questões, receberão os pontos em sua conta.',
  },
  {
    pergunta: 'Como funciona a Pesquisa?',
    resposta:
      'Para cada Pesquisa é atribuído pontos e prazo de validade para responder. Ao final do prazo, para os alunos que responderem todas as questões, receberão os pontos em sua conta.',
  },
  {
    pergunta: 'Mudei de escola, o que faço agora?',
    resposta:
      'Mudança de escola durante o ano letivo leva ao cancelamento da conta. Se a sua nova escola for parceira Esforce, pode criar uma nova conta e seguir ganhando pontos.',
  },
  {
    pergunta: 'Posso transformar meus pontos em dinheiro?',
    resposta: 'Não. A plataforma contempla os alunos através de prêmios pré-estabelecidos por cada instituição de ensino.',
  },
]
