import { useEffect } from 'react'
import { useMenuAtivoAtom } from './jotai/useMenuAtivoAtom'

type IMenuAtivoType = 'Início' | 'reForce' | 'Pontos' | 'Prêmios' | undefined

export const useMenuAtivo = (menu: IMenuAtivoType) => {
  const { setMenuAtivo } = useMenuAtivoAtom()

  useEffect(() => setMenuAtivo(menu), [])
}
