import { AvatarUsuárioAtivo } from '@/componentes/AvatarUsuárioAtivo'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoLgUi } from '@/componentes/Textos/TextoLgUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi, íconeSizeUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useLogout } from '@/hooks/API/autenticação/useLogout'
import { useInstituicao } from '@/hooks/API/instituicao/useInstituicao'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import { useMenuAtivo } from '@/hooks/useMenuAtivo'
import { useModalCustomUi } from '@/hooks/useModalCustomUi'
import { useModalUi } from '@/hooks/useModalUi'
import { Flex, FlexProps, Icon } from '@chakra-ui/react'
import {
  CaretRight,
  FileText,
  Files,
  Icon as IconType,
  Info,
  Receipt,
  ShieldCheck,
  SignOut,
  Trash,
  UserGear,
  WarningOctagon,
} from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { ConteudoModalDeletarConta } from './ConteudoModalDeletarConta'

interface ItemProps extends Omit<FlexProps, 'children'> {
  íconeMenu: IconType
  título: string
  descrição: string
}

export const MeuPerfilPágina = () => {
  const navigate = useNavigate()
  useMenuAtivo(undefined)
  const { logout } = useLogout()
  const { usuárioAtivo } = useUsuárioAtivoAtom()
  const { data } = useInstituicao()

  const Item = ({ título, íconeMenu, descrição, ...rest }: ItemProps) => (
    <Flex width="full" alignItems="center" justifyContent="space-between" gap={gapUi.xxl} paddingY={paddingUi.md} {...rest}>
      <Icon as={íconeMenu} fontSize={íconeSizeUi['padrão']} color={coresUi['primaria-ativo']} />
      <FlexColumnUi gap={gapUi.sm}>
        <TextoSmUi color={coresUi.texto.primaria}>{título}</TextoSmUi>
        <TextoXsUi color={coresUi.texto.secundaria}>{descrição}</TextoXsUi>
      </FlexColumnUi>
      <Icon as={CaretRight} color={coresUi['primaria-ativo']} />
    </Flex>
  )

  const { UiModal, onOpen, onClose } = useModalUi()
  const { UiModal: UiModalDeletarConta, onOpen: onOpenDeletarConta, onClose: onCloseDeletarConta } = useModalCustomUi()

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi alignItems="center" gap={gapUi['5xl']} paddingTop={paddingUi.xxl}>
        <FlexColumnUi alignItems="center" gap={gapUi.xl}>
          <AvatarUsuárioAtivo />
          <TextoLgUi textTransform="capitalize">{usuárioAtivo?.Perfil?.Aluno?.nome}</TextoLgUi>
        </FlexColumnUi>
        <FlexColumnUi gap={gapUi.md}>
          <TextoSmUi fontWeight="medium">Aplicativo</TextoSmUi>
          <Item
            íconeMenu={FileText}
            título="Termos de uso"
            descrição="Visualize os termos de uso"
            onClick={() => navigate(rotasApp.termosDeUso)}
          />
          <Item
            íconeMenu={FileText}
            título="Privacidade"
            descrição="Visualize a política de privacidade"
            onClick={() => navigate(rotasApp.privacidade)}
          />
          <Item
            íconeMenu={FileText}
            título="Regulamentos aplicativo"
            descrição="Visualize os regulamentos do aplicativo"
            onClick={() => navigate(rotasApp.regulamentosAplicativo)}
          />
          <Item
            íconeMenu={FileText}
            título="Regulamentos"
            descrição="Visualize os regulamentos"
            onClick={() => navigate(rotasApp.regulamentosInstituicoes)}
          />
          <Item
            íconeMenu={WarningOctagon}
            título="Perguntas Frequentes"
            descrição="Visualize as perguntas frequentes"
            onClick={() => navigate(rotasApp.perguntasFrequentes)}
          />
          {!!data?.InstituicaoPeriodos?.length && (
            <Item
              íconeMenu={Receipt}
              título="Planos"
              descrição="Visualize os planos disponíveis"
              onClick={() => navigate(rotasApp.planos)}
            />
          )}

          <TextoSmUi fontWeight="medium">Perfil</TextoSmUi>
          <Item
            íconeMenu={UserGear}
            título="Editar perfil"
            descrição="Edite as informações da sua conta"
            onClick={() => navigate(rotasApp.editarPerfil)}
          />
          <Item
            íconeMenu={ShieldCheck}
            título="Alterar senha"
            descrição="Altere sua senha de acesso atual"
            onClick={() => navigate(rotasApp.alterarSenha)}
          />
          <Item
            íconeMenu={Files}
            título="Histórico"
            descrição="Visualize os quizzes/pesquisas realizados"
            onClick={() => navigate(rotasApp.histórico)}
          />
          <Item
            íconeMenu={Trash}
            título="Excluir Perfil"
            descrição="Exclua a sua conta do aplicativo."
            onClick={onOpenDeletarConta}
          />
          <Item íconeMenu={Info} título="Sobre" descrição="Informações do app" onClick={() => navigate(rotasApp.sobre)} />
          <Item íconeMenu={SignOut} título="Sair" descrição="Realizar log out da sua conta" onClick={onOpen} />
        </FlexColumnUi>
        <UiModal
          título="Sair da conta"
          descrição="Deseja mesmo sair? Será necessário realizar um novo login para acessar sua conta novamente."
          textoBotão="Confirmar"
          callbackBotão={logout}
        >
          <TextoSmUi onClick={onClose}>Cancelar</TextoSmUi>
        </UiModal>
        <UiModalDeletarConta>
          <ConteudoModalDeletarConta onClose={onCloseDeletarConta} />
        </UiModalDeletarConta>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
