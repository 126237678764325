import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { corPrimariaAtivoUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { useRegulamentoInstituicaoGet } from '@/hooks/API/useRegulamentoInstituicaoGet'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Flex, Spinner } from '@chakra-ui/react'

export const RegulamentosInstituicaoPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Regulamentos', isBotãoEsquerdo: true })
  const { data } = useRegulamentoInstituicaoGet()

  return (
    <ContainerAppUi>
      {data ? (
        <FlexColumnUi
          width="full"
          gap={gapUi.lg}
          dangerouslySetInnerHTML={{
            __html: data ?? 'Carregando...',
          }}
          __css={{
            gap: `${gapUi.lg} !important`,
            a: {
              color: '#91b5ff',
              textDecoration: 'underline',
            },
            div: {
              display: 'flex',
              flexDirection: 'column',
              gap: `${gapUi.lg} !important`,
            },
          }}
        />
      ) : (
        <Flex justifyContent="center" alignItems="center" gap={gapUi.lg} width="full">
          <TextoMdUi>Carregando...</TextoMdUi>
          <Spinner size="sm" color={corPrimariaAtivoUi} />
        </Flex>
      )}
    </ContainerAppUi>
  )
}
