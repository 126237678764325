import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useQuizStatus = (props: EndPointAtributos & { enabled: boolean }) => {
  const { data: quizStatus } = useQuery({
    queryKey: ['quiz-status'],
    queryFn: async () => await endPoint({ quizId: props.quizId }),
    enabled: props.enabled,
  })

  return { quizStatus }
}

async function endPoint(params: EndPointAtributos): Promise<EndPointResponse> {
  const result = await api().get(rotasApi.quizStatus({ quizId: params.quizId }))
  return result.data.data
}

interface EndPointAtributos {
  quizId?: string
}

interface EndPointResponse {
  respostasCount: number
  proximaPerguntaId: string
}
