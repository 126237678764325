import { AutenticaçãoCallback } from '@/componentes/Autenticação/AutenticaçãoCallback'
import { LayoutComMenu } from '@/componentes/LayoutComMenu'
import { LayoutComNavegação } from '@/componentes/LayoutComNavegação'
import { rotasApp } from '@/configurações/rotasApp'
import { UsuárioAutenticadoProvider } from '@/contextos/UsuárioAutenticado'
import { InícioPágina } from '@/páginas/autenticadas/Início/Início/Início.página'
import { OuvidoriaPágina } from '@/páginas/autenticadas/Início/Ouvidoria.página'
import { AlterarSenhaPágina } from '@/páginas/autenticadas/meuPerfil/AlterarSenha.página'
import { EditarPerfilPágina } from '@/páginas/autenticadas/meuPerfil/EditarPerfil.página'
import { HistóricoPágina } from '@/páginas/autenticadas/meuPerfil/Histórico.página'
import { HistóricoPesquisaPágina } from '@/páginas/autenticadas/meuPerfil/HistóricoPesquisa.página'
import { HistóricoQuizPágina } from '@/páginas/autenticadas/meuPerfil/HistóricoQuiz.página'
import { MeuPerfilPágina } from '@/páginas/autenticadas/meuPerfil/MeuPerfil/MeuPerfil.página'
import { PerguntasFrequentesPágina } from '@/páginas/autenticadas/meuPerfil/PerguntasFrequentes.página'
import { PlanoVisualizarPagina } from '@/páginas/autenticadas/meuPerfil/PlanoVisualizar/PlanoVisualizar.pagina'
import { PlanosPagina } from '@/páginas/autenticadas/meuPerfil/Planos.pagina'
import { PrivacidadePágina } from '@/páginas/autenticadas/meuPerfil/Privacidade.página'
import { RegulamentosPágina } from '@/páginas/autenticadas/meuPerfil/Regulamentos.página'
import { RegulamentosInstituicaoPágina } from '@/páginas/autenticadas/meuPerfil/RegulamentosInstituicao.página'
import { SobrePágina } from '@/páginas/autenticadas/meuPerfil/Sobre.página'
import { TermosDeUsoPágina } from '@/páginas/autenticadas/meuPerfil/TermosDeUso.página'
import { ExtratoNotasPágina } from '@/páginas/autenticadas/notas/ExtratoNotas.página'
import { PontosPágina } from '@/páginas/autenticadas/pontos/Pontos.página'
import { CarrinhoPágina } from '@/páginas/autenticadas/prêmios/Carrinho.página'
import { VisualizarPrêmioPágina } from '@/páginas/autenticadas/prêmios/VisualizarPrêmio.página'
import { QuizPesquisasPágina } from '@/páginas/autenticadas/quizPesquisas/QuizPesquisas.página'
import { PesquisaPágina } from '@/páginas/autenticadas/quizPesquisas/responderPesquisa/Pesquisa.página'
import { QuizPágina } from '@/páginas/autenticadas/quizPesquisas/responderQuiz/Quiz.página'
import { ReforcePágina } from '@/páginas/autenticadas/reforce/Reforce.página'
import { ReforceVisualizarPagina } from '@/páginas/autenticadas/reforce/ReforceVisualizar.página'

import { RouteObject } from 'react-router-dom'

const rotasLayoutComMenu: RouteObject[] = [
  {
    path: rotasApp.meuPerfil,
    element: <AutenticaçãoCallback PageElement={MeuPerfilPágina} redirectPathSuccess={rotasApp.meuPerfil} />,
  },
  {
    path: rotasApp.início,
    element: <AutenticaçãoCallback PageElement={InícioPágina} redirectPathSuccess={rotasApp.início} />,
  },
  {
    path: rotasApp.pontos,
    element: <AutenticaçãoCallback PageElement={PontosPágina} redirectPathSuccess={rotasApp.pontos} />,
  },
  {
    path: rotasApp.reforce,
    element: <AutenticaçãoCallback PageElement={ReforcePágina} redirectPathSuccess={rotasApp.reforce} />,
  },
]

const rotasLayoutComNavegação: RouteObject[] = [
  {
    path: rotasApp.quizPesquisas,
    element: <AutenticaçãoCallback PageElement={QuizPesquisasPágina} redirectPathSuccess={rotasApp.quizPesquisas} />,
  },
  {
    path: rotasApp.quiz,
    element: <AutenticaçãoCallback PageElement={QuizPágina} redirectPathSuccess={rotasApp.quiz} />,
  },
  {
    path: rotasApp.reforceVisualizar(':id'),
    element: (
      <AutenticaçãoCallback PageElement={ReforceVisualizarPagina} redirectPathSuccess={rotasApp.reforceVisualizar(':id')} />
    ),
  },
  {
    path: rotasApp.pesquisa,
    element: <AutenticaçãoCallback PageElement={PesquisaPágina} redirectPathSuccess={rotasApp.pesquisa} />,
  },
  {
    path: rotasApp.extratoNotas,
    element: <AutenticaçãoCallback PageElement={ExtratoNotasPágina} redirectPathSuccess={rotasApp.extratoNotas} />,
  },
  {
    path: rotasApp.visualizarPrêmio(':id'),
    element: <AutenticaçãoCallback PageElement={VisualizarPrêmioPágina} redirectPathSuccess={rotasApp.visualizarPrêmio(':id')} />,
  },
  {
    path: rotasApp.ouvidoria,
    element: <AutenticaçãoCallback PageElement={OuvidoriaPágina} redirectPathSuccess={rotasApp.ouvidoria} />,
  },

  {
    path: rotasApp.carrinho,
    element: <AutenticaçãoCallback PageElement={CarrinhoPágina} redirectPathSuccess={rotasApp.carrinho} />,
  },
  {
    path: rotasApp.editarPerfil,
    element: <AutenticaçãoCallback PageElement={EditarPerfilPágina} redirectPathSuccess={rotasApp.editarPerfil} />,
  },
  {
    path: rotasApp.histórico,
    element: <AutenticaçãoCallback PageElement={HistóricoPágina} redirectPathSuccess={rotasApp.histórico} />,
  },
  {
    path: rotasApp.historicoPesquisa(':id'),
    element: (
      <AutenticaçãoCallback PageElement={HistóricoPesquisaPágina} redirectPathSuccess={rotasApp.historicoPesquisa(':id')} />
    ),
  },
  {
    path: rotasApp.historicoQuiz(':id'),
    element: <AutenticaçãoCallback PageElement={HistóricoQuizPágina} redirectPathSuccess={rotasApp.historicoQuiz(':id')} />,
  },
  {
    path: rotasApp.termosDeUso,
    element: <AutenticaçãoCallback PageElement={TermosDeUsoPágina} redirectPathSuccess={rotasApp.termosDeUso} />,
  },
  {
    path: rotasApp.privacidade,
    element: <AutenticaçãoCallback PageElement={PrivacidadePágina} redirectPathSuccess={rotasApp.privacidade} />,
  },
  {
    path: rotasApp.regulamentosAplicativo,
    element: <AutenticaçãoCallback PageElement={RegulamentosPágina} redirectPathSuccess={rotasApp.regulamentosAplicativo} />,
  },
  {
    path: rotasApp.regulamentosInstituicoes,
    element: (
      <AutenticaçãoCallback PageElement={RegulamentosInstituicaoPágina} redirectPathSuccess={rotasApp.regulamentosInstituicoes} />
    ),
  },
  {
    path: rotasApp.sobre,
    element: <AutenticaçãoCallback PageElement={SobrePágina} redirectPathSuccess={rotasApp.sobre} />,
  },
  {
    path: rotasApp.perguntasFrequentes,
    element: <AutenticaçãoCallback PageElement={PerguntasFrequentesPágina} redirectPathSuccess={rotasApp.perguntasFrequentes} />,
  },
  {
    path: rotasApp.planos,
    element: <AutenticaçãoCallback PageElement={PlanosPagina} redirectPathSuccess={rotasApp.planos} />,
  },
  {
    path: rotasApp.planoVisualizar(':id'),
    element: <AutenticaçãoCallback PageElement={PlanoVisualizarPagina} redirectPathSuccess={rotasApp.planoVisualizar(':id')} />,
  },
  {
    path: rotasApp.alterarSenha,
    element: <AutenticaçãoCallback PageElement={AlterarSenhaPágina} redirectPathSuccess={rotasApp.alterarSenha} />,
  },
]

const rotasSemLayout: RouteObject[] = []

export const rotasAuth: RouteObject[] = [
  {
    element: <UsuárioAutenticadoProvider />,
    children: [
      {
        element: <LayoutComMenu />,
        children: [...rotasLayoutComMenu],
      },
      {
        element: <LayoutComNavegação />,
        children: [...rotasLayoutComNavegação],
      },
      ...rotasSemLayout,
    ],
  },
]
