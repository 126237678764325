import { BotãoOutlineUi } from '@/componentes/Botões/BotãoOutlineUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { ImagemProdutoUi } from '@/componentes/ImagemProdutoUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXlUi } from '@/componentes/Textos/TextoXlUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { configuraçõesApi } from '@/configurações/configuraçõesApi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { usePlanoListar } from '@/hooks/API/plano/usePlanoListar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Flex, Grid, Img } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

export const PlanosPagina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Planos', isBotãoEsquerdo: true })
  const { data } = usePlanoListar()

  return (
    <ContainerAppUi ResetCSS direction="column" gap={gapUi.lg}>
      {!data?.length && (
        <FlexColumnUi paddingY={paddingUi['5xl']} width="100%" justifyContent="center" alignItems="center" gap={gapUi.xl}>
          <TextoMdUi>Em breve...</TextoMdUi>
          <Img src="/undraw_empty.svg" width="300px" />
        </FlexColumnUi>
      )}
      <Grid
        gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))"
        width="100%"
        justifyContent="space-between"
        gap={gapUi.xl}
      >
        {data?.map((plano) => (
          <PrêmioRender
            key={plano.id}
            {...{
              nome: plano.nome,
              urlFoto: plano.fotoUrl,
              id: plano.id,
              valor: plano.valor,
            }}
          />
        ))}
      </Grid>
    </ContainerAppUi>
  )
}

const PrêmioRender = (props: PrêmioRenderProps) => {
  const navigate = useNavigate()
  return (
    <FlexColumnUi gap={gapUi.md} width="100%">
      <ImagemProdutoUi src={configuraçõesApi.baseArquivo(props.urlFoto as string)} />
      <FlexColumnUi gap={gapUi.sm}>
        <TextoSmUi>{props.nome}</TextoSmUi>
        <Flex alignItems="center" gap="3px">
          <TextoMdUi>R$</TextoMdUi>
          <Flex alignItems="start">
            <TextoXlUi fontWeight="bold">{props.valor.toFixed(2).split('.')[0]}</TextoXlUi>
            <TextoXsUi fontWeight="bold" lineHeight={'15px'}>
              ,{props.valor.toFixed(2).split('.')[1]}
            </TextoXsUi>
          </Flex>
        </Flex>
      </FlexColumnUi>
      <BotãoOutlineUi
        height="32px"
        width="100%"
        color={coresUi['primaria-ativo']}
        callback={() => navigate(rotasApp.planoVisualizar(props.id))}
      >
        <TextoXsUi color="inherit">Ver detalhes</TextoXsUi>
      </BotãoOutlineUi>
    </FlexColumnUi>
  )
}

interface PrêmioRenderProps {
  nome: string
  urlFoto?: string
  id: string
  valor: number
}
