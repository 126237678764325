import { atom, useAtom } from 'jotai'

const INICIAL_STATE: MenuAtivoType = 'Início'
const menuAtivoAtom = atom<MenuAtivoType>(INICIAL_STATE)
menuAtivoAtom.debugLabel = 'menuAtivoAtom'

export type MenuAtivoType = 'Início' | 'Pontos' | 'Prêmios' | 'reForce' | undefined

export const useMenuAtivoAtom = () => {
  const [menuAtivo, setMenuAtivo] = useAtom(menuAtivoAtom)

  return { menuAtivo, setMenuAtivo }
}
