import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { RadioUi } from '@/componentes/RadioUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { Flex, Grid } from '@chakra-ui/react'
import { CardOutlineUi } from '../CardOutline/CardOutlineUi'

export const CardVisualizarQuizPesquisaResultadoUi = ({
  pergunta,
  alternativas,
  index,
}: {
  pergunta: string
  alternativas: IQuizPerguntasAlternativas[]
  index: number
}) => {
  return (
    <FlexColumnUi gap={gapUi.sm}>
      <Flex gap={gapUi.sm}>
        <TextoMdUi>{index + 1}.</TextoMdUi>
        <TextoMdUi lineHeight={lineHeightUi.lg}>{pergunta}</TextoMdUi>
      </Flex>
      <CardOutlineUi>
        {alternativas.map((alternativa, index) => (
          <Grid key={alternativa.id} alignItems="start" gap="10px" width="full" gridTemplateColumns={'15px 1fr'}>
            <RadioUi selecionado={alternativa.selecionada} errado={alternativa.errada} />
            <TextoMdUi lineHeight={lineHeightUi.lg} {...(!alternativa.selecionada && { color: coresUi.texto.secundaria })}>
              {String.fromCharCode(65 + index)}. {alternativa.texto}
            </TextoMdUi>
          </Grid>
        ))}
      </CardOutlineUi>
    </FlexColumnUi>
  )
}

export interface IQuizPerguntasAlternativas {
  id: number
  texto: string
  selecionada: boolean
  errada?: boolean
}

export interface IQuizPerguntas {
  id: number
  pergunta: string
  alternativas: IQuizPerguntasAlternativas[]
}
