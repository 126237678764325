import { LazyImageUi } from '@/componentes/LazyImageUi'
import { aspectRatioUi } from '@/configurações/estilização/medidas'
import { Flex } from '@chakra-ui/react'

export const _Imagem = (props: ImagemProps) => (
  <Flex position="relative" width="100%" height="0" paddingBottom={aspectRatioUi['16:9']} overflow="hidden">
    <Flex position="absolute" top="0" left="0" width="100%" height="100%" alignItems="center" justifyContent="center">
      <LazyImageUi
        alt={props.data.premioNome}
        src={props.data.premioFotoUrl}
        maxWidth="100%"
        width="100%"
        height="100%"
        maxHeight="100%"
        objectFit="cover"
      />
    </Flex>
  </Flex>
)

interface ImagemProps {
  data: {
    premioNome: string
    premioFotoUrl: string
  }
}
