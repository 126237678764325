import { _Avatar } from './Avatar'
import { _BarraInferior } from './BarraInferior'
import { _Comentario } from './Comentario'
import { _ComentarioFoto } from './ComentarioFoto'
import { _Header } from './Header'
import { _Imagem } from './Imagem'
import { _InputEmoji } from './InputEmoji'
import { _MensagemComentario } from './MensagemComentario'
import { _Root } from './Root'

export const ReforceCardPatternsUi = {
  Root: _Root,
  Header: _Header,
  Avatar: _Avatar,
  Imagem: _Imagem,
  BarraInferior: _BarraInferior,
  Comentario: _Comentario,
  ComentarioFoto: _ComentarioFoto,
  InputEmoji: _InputEmoji,
  MensagemComentario: _MensagemComentario,
}
