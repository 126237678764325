import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { rotasApp } from '@/configurações/rotasApp'
import { useCódigoAcesso } from '@/hooks/API/autenticação/useCódigoAcesso'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useModalUi } from '@/hooks/useModalUi'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const RecuperarSenhaCódigoPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Recuperar senha', isBotãoEsquerdo: true })

  const { códigoAcesso, códigoAcessoContext, códigoAcessoIsLoading, códigoAcessoStatus } = useCódigoAcesso()
  const { UiModal, onOpen } = useModalUi()

  const { formState } = códigoAcessoContext || {}
  const { errors } = formState || {}
  const navigate = useNavigate()
  const storage = new LocalStorage()

  useEffect(() => {
    if (códigoAcessoStatus === 'success') onOpen()
  }, [códigoAcessoStatus])

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi gap={gapUi['4xl']}>
        <FlexColumnUi gap={gapUi.md}>
          <TextoXxlUi fontWeight="medium" color={coresUi['primaria-ativo']}>
            Código de verificação
          </TextoXxlUi>
          <TextoSmUi lineHeight={lineHeightUi.xl}>
            Informe o código de recuperação de senha que enviamos para {storage.get('email')}
          </TextoSmUi>
        </FlexColumnUi>
        <FormProvider {...códigoAcessoContext}>
          <FormulárioUi.Form callback={códigoAcesso}>
            <FlexColumnUi gap={gapUi['4xl']}>
              <FormulárioUi.Container name="codigoAcesso">
                <FormulárioUi.Label título="Código de verificação" />
                <FormulárioUi.Input placeholder="Digite o código de verificação" />
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <BotãoFormulárioUi isLoading={false} type="submit" isDisabled={isErrosFormulário(errors) || códigoAcessoIsLoading}>
                Continuar
              </BotãoFormulárioUi>
            </FlexColumnUi>
          </FormulárioUi.Form>
        </FormProvider>
      </FlexColumnUi>
      <UiModal
        callbackBotão={() => navigate(rotasApp.recuperarSenhaNovaSenha)}
        descrição="Você será redirecionado para redefinir sua senha."
        título="Código de acesso verificado"
        textoBotão="Entendido"
      />
    </ContainerAppUi>
  )
}
