import { useState } from 'react'
import { FormulárioUi } from './FormulárioUi'

export const useMostrarSenha = () => {
  const [verSenha, setVerSenha] = useState<boolean>(false)

  const MostrarSenha = () => (
    <FormulárioUi.Container name="exibirSenha_" width="fit-content">
      <FormulárioUi.Label
        título="Exibir Senha"
        size={'xs'}
        LabelProps={{ flexDir: 'row-reverse', justifyContent: 'start', gap: '5px' }}
      >
        <FormulárioUi.CheckBox isChecked={verSenha} onChange={() => setVerSenha(!verSenha)} />
      </FormulárioUi.Label>
    </FormulárioUi.Container>
  )

  return { MostrarSenha, verSenha }
}
