import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useReforceCriarComentario = () => {
  const { mutate, status, isLoading } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
    onSuccess: () => {
      queryClient.invalidateQueries(['premios'])
      queryClient.invalidateQueries(['reforce-visualizar'])
    },
  })

  return { mutate, status, isLoading }
}

async function endPoint(data: EndPoint): Promise<boolean> {
  const result = await api().post(rotasApi.reforceCriarComentario, data)
  return result.data.data
}

interface EndPoint {
  reforceId: string
  comentario: string
}
