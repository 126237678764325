import { ReforceModel } from '@/@types/Models'
import { Cabeçalho } from '@/componentes/Cabeçalho/Cabeçalho'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { Popup } from '@/componentes/Popup/Popup'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { useReforceListar } from '@/hooks/API/reforce/useReforceListar'
import { useMenuAtivo } from '@/hooks/useMenuAtivo'
import { Flex } from '@chakra-ui/react'
import { Fragment, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { ReforceCardPatternsUi } from './components/CardPatterns/ReforceCardPatternsUi'

export const ReforcePágina = () => {
  useMenuAtivo('reForce')
  const { data, fetchNextPage, hasNextPage, refetch } = useReforceListar({ take: 10 })
  const { ref, inView } = useInView()
  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage()
  }, [hasNextPage, inView])

  return (
    <Flex width="100%" bg={coresUi.fundo.primaria} height="100%" alignItems="center" flexDirection="column" gap={gapUi['3xl']}>
      <Cabeçalho />
      <ContainerAppUi flexDirection="column" gap={gapUi['3xl']} paddingX={0}>
        {!!data?.pages.length && <SvgSemDados />}
        {data?.pages.map((page) =>
          page.data.map((reforce, index) => {
            if (page.data.length === index + 1) {
              return (
                <Flex key={reforce.id} ref={ref}>
                  <CardReforce reforce={reforce} />
                </Flex>
              )
            } else {
              return (
                <Fragment key={reforce.id}>
                  <CardReforce reforce={reforce} />
                  <Flex width="full" borderBottom={`1px dashed #2e2e2e`} />
                </Fragment>
              )
            }
          }),
        )}
        {!hasNextPage && !!data?.pages[0].data.length && (
          <FlexColumnUi width="100%" height="fit-content" alignItems="center" gap={gapUi.lg}>
            <TextoMdUi color={coresUi['primaria-inativo']} cursor="pointer" onClick={() => refetch()}>
              Fim dos resultados
            </TextoMdUi>
          </FlexColumnUi>
        )}
      </ContainerAppUi>
      <Popup />
    </Flex>
  )
}

const CardReforce = ({ reforce }: { reforce: ReforceModel }) => {
  return (
    <ReforceCardPatternsUi.Root key={reforce.id}>
      <ReforceCardPatternsUi.Header
        data={{
          alunoNome: reforce.Aluno?.nome as string,
          alunoAvatarUrl: reforce.Aluno?.avatarArquivoLink as string,
          instituicaoNome: `${reforce.Instituicao?.nome} - ${reforce.Aluno?.Turma?.nome}` as string,
          criadoEm: reforce?.criadoEm,
        }}
      />
      <ReforceCardPatternsUi.Imagem
        data={{
          premioNome: reforce.Premio?.nome as string,
          premioFotoUrl: reforce.Premio?.Fotos?.[0]?.arquivoLink as string,
        }}
      />
      <ReforceCardPatternsUi.BarraInferior
        data={{
          isCurtida: reforce.usuarioAtivoCurtiu || false,
          reforceFavoritos: reforce.ReforceFavoritos?.length || 0,
          reforceId: reforce.id,
        }}
      />
      <TextoSmUi color={coresUi['primaria-ativo']} fontWeight="thin" paddingX={paddingUi.xl}>
        {reforce.descricao}
      </TextoSmUi>
      <FlexColumnUi gap="0" paddingX={paddingUi.xl}>
        {!!reforce.ReforceComentarios?.length && <ReforceCardPatternsUi.Comentario Comentario={reforce.ReforceComentarios[0]} />}
        <ReforceCardPatternsUi.MensagemComentario reforce={reforce} />
      </FlexColumnUi>
      <ReforceCardPatternsUi.InputEmoji data={{ reforceId: reforce.id }} />
    </ReforceCardPatternsUi.Root>
  )
}
