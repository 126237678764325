import { TextoLabelFormulárioUi, TextoLabelFormulárioUiProps } from '@/componentes/Textos/TextoLabelFormulárioUi'
import { FormLabel, FormLabelProps } from '@chakra-ui/react'
import { useContextSelector } from 'use-context-selector'
import { ContainerContexto } from './Container'

export const Label = (props: LabelProps) => {
  const required = useContextSelector(ContainerContexto, (value) => value.required)
  const name = useContextSelector(ContainerContexto, (value) => value.name)
  const disabled = useContextSelector(ContainerContexto, (value) => value.disabled)

  return (
    <FormLabel
      htmlFor={name}
      margin={0}
      paddingX="1px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...props.LabelProps}
    >
      <TextoLabelFormulárioUi size={props.size}>
        {props.título}
        {required && !disabled && '*'}
      </TextoLabelFormulárioUi>
      {props.children}
    </FormLabel>
  )
}

interface LabelProps {
  children?: React.ReactNode
  título: React.ReactNode
  LabelProps?: FormLabelProps
  size?: TextoLabelFormulárioUiProps['size']
}
