import { IMeuTema } from '@/@types/ChakraUi'
import { extendTheme } from '@chakra-ui/react'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { fonts } from './fonts'
import { progress } from './progress'
import { styles } from './styles'

const tema: IMeuTema = extendTheme({
  breakpoints,
  colors,
  styles,
  fonts,
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  components: {
    Progress: progress,
  },
})

export default tema
