import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useRegulamentoInstituicaoGet = () => {
  const { data } = useQuery({
    queryKey: ['regulamento-instituicao'],
    queryFn: async () => await endPoint(),
  })

  return { data }
}

async function endPoint(): Promise<string> {
  const result = await api().get(rotasApi.regulamentoInstituicaoBuscar)
  return result.data.data
}
