import { corPrimariaAtivoUi, coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi } from '@/configurações/estilização/medidas'
import { Card } from '@chakra-ui/react'

export const RadioUi = ({ selecionado, errado, respondendo }: RadioUiProps) => {
  let background = selecionado ? coresUi.texto.sucesso : coresUi.fundo.primaria
  let boxShadow = selecionado ? coresUi.texto.sucesso : coresUi.texto.primaria

  if (respondendo && selecionado && !errado) {
    background = corPrimariaAtivoUi
    boxShadow = corPrimariaAtivoUi
  } else if (errado) {
    background = coresUi.texto.erro
    boxShadow = coresUi.texto.erro
  }

  return (
    <Card
      width="15px"
      height="15px"
      borderRadius={borderRadiusUi.full}
      background={background}
      boxShadow={`0px 0px 0px 2px ${coresUi.fundo.primaria}, 0px 0px 0px 3.5px ${boxShadow}`}
    />
  )
}

interface RadioUiProps {
  selecionado?: boolean
  errado?: boolean
  respondendo?: boolean
}
