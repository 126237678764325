import { NotificacaoModel } from '@/@types/Models'
import { PaginacaoCursorResponse } from '@/@types/paginacaoResponse'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useInfiniteQuery } from '@tanstack/react-query'

export const useNotificacoes = (params?: Atributos) => {
  const { data, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryFn: async ({ pageParam = '' }) => {
      return await endPoint({ take: params?.take, cursorAtual: pageParam })
    },
    queryKey: ['notificacoes'],
    getNextPageParam: (lastPage) => (lastPage.hasNextPage ? lastPage.ultimoCursor : undefined),
  })

  return { notificacoes: data, hasNextPage, fetchNextPage, refetch }
}

async function endPoint(params?: Atributos): Promise<EndPointResponse> {
  const result = await api().get(rotasApi.notificacaoListar, { params })
  return result.data.data
}

interface Atributos {
  cursorAtual?: string
  take?: number
}

type EndPointResponse = PaginacaoCursorResponse<NotificacaoModel, string>
