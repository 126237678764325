import { RotasPublicRouterAppAtributos } from '@/@types/rotasRouterApp'
import { VerificarMatriculaPagina } from './VerificarMatricula/VerificarMatricula.pagina'
import { PrimeiroAcessoCadastroPágina } from './cadastro/PrimeiroAcessoCadastro.página'


export const primeiroAcessoRotasApp = {
  buscarMatricula: `/primeiro-acesso/buscar-matricula`,
  cadastro: `/primeiro-acesso/cadastro`,
}

export const primeiroAcessoRoutes: RotasPublicRouterAppAtributos[] = [
  { path: primeiroAcessoRotasApp.buscarMatricula, Element: VerificarMatriculaPagina },
  { path: primeiroAcessoRotasApp.cadastro, Element: PrimeiroAcessoCadastroPágina },
]
