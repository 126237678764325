import { getErroHookForm } from '@/utilitários/getErroHookForm'
import { Checkbox as CheckboxChakra, CheckboxProps as CheckboxPropsChakra } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { useContextSelector } from 'use-context-selector'
import { ContainerContexto } from './Container'

export const CheckBox = (props: CheckBoxPatternProps) => {
  const required = useContextSelector(ContainerContexto, (value) => value.required)
  const name = useContextSelector(ContainerContexto, (value) => value.name)
  const disabled = useContextSelector(ContainerContexto, (value) => value.disabled)

  const formContext = useFormContext()
  const { register, formState } = formContext
  const { errors } = formState
  const { ...rest } = props

  const inputError = getErroHookForm(errors, name)
  const inputRegister = register(name, { required })

  return (
    <CheckboxChakra
      id={name}
      {...inputRegister}
      onChange={(e) => {
        inputRegister.onChange(e)
        props.onChange && props.onChange(e)
      }}
      isDisabled={disabled}
      isInvalid={!!inputError}
      autoFocus={!!inputError}
      {...rest}
    />
  )
}

CheckBox.displayName = 'Input'
type OmitInputProps = 'name' | 'disabled'
export interface CheckBoxPatternProps extends Omit<CheckboxPropsChakra, OmitInputProps> {}
