import { BotãoServiçoUi } from '@/componentes/Botões/BotãoServiçosUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { ScrollHorizontalUi } from '@/componentes/ScrollHorizontalUi/ScrollHorizontalUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { ÍconesBotãoServiçosUi } from '@/componentes/Ícones/ÍconesBotãoServiçosUi'
import { gapUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { Flex } from '@chakra-ui/react'
import { Headset, Megaphone} from '@phosphor-icons/react'
import { Link, useNavigate } from 'react-router-dom'

export const Serviços = () => {
  const navigate = useNavigate()

  return (
    <Flex width="100%" flexDirection="column" gap={gapUi.lg}>
      <ContainerAppUi>
        <Flex justifyContent="space-between" alignItems="center">
          <TextoMdUi>Serviços</TextoMdUi>
        </Flex>
      </ContainerAppUi>

      <ScrollHorizontalUi id="scroll-horizontal-serviços">
        <BotãoServiçoUi texto="Ouvidoria" inverterChildren onClick={() => navigate(rotasApp.ouvidoria)}>
          <ÍconesBotãoServiçosUi ícone={Megaphone} />
        </BotãoServiçoUi>

        <Link
          to="https://api.whatsapp.com/send?phone=5511945665619&text=Ol%C3%A1,%20preciso%20de%20ajuda%20para%20recuperar%20minha%20matr%C3%ADcula%20!"
          target="_blank"
        >
          <BotãoServiçoUi texto="Suporte" inverterChildren>
            <ÍconesBotãoServiçosUi ícone={Headset} />
          </BotãoServiçoUi>
        </Link>
      </ScrollHorizontalUi>
    </Flex>
  )
}
