import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { TextoLgUi } from '@/componentes/Textos/TextoLgUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { fontSizeUi, lineHeightUi } from '@/configurações/estilização/tipografia'
import { useDeletarAluno } from '@/hooks/API/aluno/useDeletarAluno'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { Box, Flex, Grid } from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'

export const ConteudoModalDeletarConta = (props: IConteudoModalDeletarContaProps) => {
  const { contextDeletarAluno, mutateDeletarAluno } = useDeletarAluno()
  const verSenhaWatch = contextDeletarAluno.watch('verSenha')
  const cbSenha = () => contextDeletarAluno.setValue('verSenha', !verSenhaWatch)

  return (
    <Grid gap={gapUi.xl} gridTemplateAreas='"titulo" "descricao" "aviso" "formulario" "botao"'>
      <TextoLgUi gridArea="titulo" color={coresUi['primaria-ativo']}>
        Excluir Perfil
      </TextoLgUi>
      <TextoXsUi gridArea="descricao" lineHeight={lineHeightUi.md}>
        Tem certeza de que deseja excluir sua conta? Isso resultará na perda de todo o seu progresso e pontuação na plataforma, e
        não será possível fazer login novamente.
      </TextoXsUi>
      <TextoXsUi gridArea="aviso" lineHeight={lineHeightUi.md} color={coresUi['primaria-ativo']}>
        Esta ação é irreversível!
      </TextoXsUi>

      <FormProvider {...contextDeletarAluno}>
        <FormulárioUi.Form callback={mutateDeletarAluno}>
          <Flex width="100%" flexDirection="column" gap={gapUi['4xl']}>
            <Flex width="100%" flexDirection="column" gap={gapUi.lg}>
              <FormulárioUi.Container name="senha">
                <FormulárioUi.Label título="Senha" />
                <Box position="relative">
                  <FormulárioUi.Input
                    placeholder="Digite sua senha"
                    autoComplete="current-password"
                    type={verSenhaWatch ? 'text' : 'password'}
                    fontSize={fontSizeUi.md}
                  />
                </Box>
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="exibirSenha">
                <FormulárioUi.Label
                  título="Exibir Senha"
                  size={'xs'}
                  LabelProps={{ flexDir: 'row-reverse', justifyContent: 'start', gap: '5px' }}
                >
                  <FormulárioUi.CheckBox isChecked={verSenhaWatch} onChange={cbSenha} />
                </FormulárioUi.Label>
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
            </Flex>
            <Flex width="100%" flexDirection="column" gap={gapUi.xl}>
              <BotãoFormulárioUi
                isLoading={false}
                type="submit"
                isDisabled={isErrosFormulário(contextDeletarAluno.formState.errors)}
                height="42px"
              >
                <TextoSmUi color={coresUi.texto['com-fundo-primário']} fontWeight="medium">
                  Deletar Conta
                </TextoSmUi>
              </BotãoFormulárioUi>
            </Flex>
          </Flex>
        </FormulárioUi.Form>
      </FormProvider>
      <TextoSmUi onClick={props.onClose}>Cancelar</TextoSmUi>
    </Grid>
  )
}

interface IConteudoModalDeletarContaProps {
  onClose: () => void
}
