import { BotãoCardUi } from '@/componentes/Botões/BotãoCardUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { Button, Flex, Icon, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { Play } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useMe } from '../../../../hooks/API/autenticação/useMe'
import { useAlunoBuscarPorId } from '../../../../hooks/API/aluno/useAlunoBuscarPorId'
import { TextoMdUi } from '../../../../componentes/Textos/TextoMdUi'
import { TextoSmUi } from '../../../../componentes/Textos/TextoSmUi'

export const useAlertaModalPerfil = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false)
  const navigate = useNavigate()

  const AlertaModal = () => {
    const { dataMe } = useMe()
    const { aluno } = useAlunoBuscarPorId({
      alunoId: dataMe?.Perfil?.alunoId,
    })

    useEffect(() => {
      if (aluno && aluno.idade < 18) {
        const responsavelHasRequiredInfo =
          aluno.Responsavel === undefined ||
          aluno.Responsavel?.cpf === '' ||
          aluno.Responsavel?.nome === '' ||
          aluno.Responsavel?.telefone === '' ||
          aluno.Responsavel?.email === ''
        if (dataMe && responsavelHasRequiredInfo && isPopupVisible) {
          onOpen()
        } else {
          onClose()
        }
      }
    })

    useEffect(() => {
      const lastClosedTime = localStorage.getItem(`@Esforce:popupFillProfile`)
      if (lastClosedTime) {
        const currentTime = new Date().getTime()
        const timeDifference = currentTime - parseInt(lastClosedTime, 10)
        if (timeDifference > 60 * 60 * 1000) {
          setIsPopupVisible(true)
        }
      } else {
        setIsPopupVisible(true)
      }
    }, [])

    const handleClosePopup = () => {
      setIsPopupVisible(false)
      localStorage.setItem(`@Esforce:popupFillProfile`, new Date().getTime().toString())
      onClose()
    }
    const goToProfileEdit = () => {
      setIsPopupVisible(false)
      localStorage.setItem(`@Esforce:popupFillProfile`, new Date().getTime().toString())
      onClose()
      navigate('/meu-perfil/editar')
    }

    return (
      <Modal isOpen={isOpen} onClose={() => {}} isCentered={true} size={{ base: 'xs', md: 'sm' }}>
        <ModalOverlay backdropFilter="auto" backdropBlur={`10px`} filter={`grayscale(0%)`} id="overlay-close-modal" />
        <ModalContent
          bg={coresUi.fundo.primaria}
          borderRadius={borderRadiusUi.lg}
          shadow="xs"
          paddingY={paddingUi['6xl']}
          paddingX={paddingUi['4xl']}
        >
          <>
            <FlexColumnUi gap={gapUi['4xl']}>
              <FlexColumnUi gap={gapUi.lg}>
                <TextoXxlUi color={coresUi.texto.primaria} fontWeight="bold">
                  Preencha seus dados e ganhe pontos !
                </TextoXxlUi>
                <TextoMdUi fontWeight="bold" color={coresUi['primaria-ativo']}>
                  Você ganhará 150 pontos extras
                </TextoMdUi>
                <TextoSmUi fontWeight="regular" color={coresUi.texto.primaria}>
                  As informações dos dados pessoais do responsável ainda estão pendentes. Preencha os dados solicitados. Não perca
                  essa chance de aumentar sua pontuação e manter seus dados atualizados!
                </TextoSmUi>
              </FlexColumnUi>

              <Flex width="full" justifyContent="space-between" direction="column" gap="2">
                <Button
                  onClick={goToProfileEdit}
                  bg={coresUi['primaria-ativo']}
                  color={coresUi.texto.placeholder}
                  size="md"
                  _hover={{ bg: coresUi['primaria-ativo'] }}
                >
                  Preencher
                </Button>
                <Button onClick={handleClosePopup} bg={coresUi.fundo.black} size="md">
                  Fechar
                </Button>
              </Flex>
            </FlexColumnUi>
          </>
        </ModalContent>
      </Modal>
    )
  }

  return {
    AlertaModal,
  }
}

const IconPlay = () => <Icon as={() => <Play weight="fill" size="12px" color={coresUi.texto['com-fundo-primário']} />} />
