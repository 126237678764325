import { ComponentWithAs } from '@chakra-ui/react'
import { useMemo } from 'react'
import InputMask from 'react-input-mask'
import { Input, InputPatternProps } from './Input'

export const InputMáscara: ComponentWithAs<'input', InputMáscaraProps> = (props: InputMáscaraProps) => {
  const selecionarMask = useMemo(
    () => (tipo: InputMáscaraProps['tipo']) => {
      let mask: string
      let placeholder: string

      switch (tipo) {
        case 'cpf':
          mask = '999.999.999-99'
          placeholder = '123.456.789-10'
          break
        case 'cnpj':
          mask = '99.999.999/9999-99'
          placeholder = '12.345.678/0001-90'
          break
        case 'telefone':
          mask = '(99) 9999-99999'
          placeholder = '(99) 9999-9999'
          break
        case 'celular':
          mask = '(99) 99999-9999'
          placeholder = '(99) 99999-9999'
          break
        case 'cep':
          mask = '99999-999'
          placeholder = '99999-999'
          break
        case 'DD/MM/YYYY':
          mask = '99/99/9999'
          placeholder = 'dd/mm/aaaa'
          break
        case 'placa':
          mask = 'AAA-9999'
          placeholder = 'AAA-9999'
          break

        default:
          mask = ''
          placeholder = ''
      }

      return { mask, placeholder }
    },
    [props.tipo],
  )

  return <Input maskChar={null} as={InputMask} {...selecionarMask(props.tipo)} {...props} />
}

InputMáscara.displayName = 'InputMáscara'

interface InputMáscaraProps extends InputPatternProps {
  tipo: 'cpf' | 'cnpj' | 'telefone' | 'celular' | 'cep' | 'DD/MM/YYYY' | 'placa'
  maskChar?: string
}
