import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import Yup from '@/utilitários/Yup/Yup'
import { abstractSchema } from '@/utilitários/Yup/abstractSchema'
import { mensagemErroSchema } from '@/utilitários/Yup/mensagemErroSchema'
import { responseError } from '@/utilitários/handler/responseHandler'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'

export const useRedefinirSenha = () => {
  const {
    mutate: redefinirSenha,
    isLoading: isLoadingRedefinirSenha,
    status: statusRedefinirSenha,
  } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
  })

  const redefinirSenhaSenhaContext = useForm({
    resolver: yupResolver(redefinirSenhaSenhaSchema),
    reValidateMode: 'onBlur',
  })

  return {
    redefinirSenha,
    isLoadingRedefinirSenha,
    redefinirSenhaSenhaContext,
    statusRedefinirSenha,
  }
}

async function endPoint(data: RedefinirSenhaSenhaSchema): Promise<boolean> {
  const result = await api().post(rotasApi.redefinirSenha, data)
  return result.data.data
}

export const redefinirSenhaSenhaSchema = Yup.object({
  matricula: Yup.string().required().label('Matrícula'),
  codigoAcesso: Yup.string().required().label('Código de acesso'),
  senha: abstractSchema.senha.required().label('Senha'),
  confirmarSenha: Yup.string()
    .label('Confirmar senha')
    .oneOf([Yup.ref('senha')], mensagemErroSchema.senhasDiferentes)
    .optional()
    .defined(),
  verSenha: Yup.boolean().default(false),
  verConfirmarSenha: Yup.boolean().default(false),
})

export type RedefinirSenhaSenhaSchema = Yup.InferType<typeof redefinirSenhaSenhaSchema>
