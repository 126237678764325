import { BotãoModalUi } from '@/componentes/Botões/BotãoModalUi'
import { TextoLgUi } from '@/componentes/Textos/TextoLgUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { Grid, GridProps, Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { ReactNode } from 'react'

export const useModalUi = (): IModalUiReturn => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const UiModal = (props: IModalUiProps) => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={'xs'}>
      <ModalOverlay backdropFilter="auto" backdropBlur={`10px`} filter={`grayscale(0%)`} id="overlay-close-modal" />
      <ModalContent bg={coresUi.fundo.secundaria} shadow="xs" padding={paddingUi['2xl']} alignItems="center" textAlign="center">
        <Grid
          gap={gapUi.xl}
          gridTemplateAreas='"titulo" "descricao" "botao"'
          {...(props.gridTemplateAreas && {
            gridTemplateAreas: props.gridTemplateAreas,
          })}
        >
          <TextoLgUi gridArea="titulo" color={coresUi['primaria-ativo']}>
            {props.título}
          </TextoLgUi>
          <TextoXsUi gridArea="descricao" lineHeight={lineHeightUi.md}>
            {props.descrição}
          </TextoXsUi>
          <BotãoModalUi gridArea="botao" callback={props.callbackBotão}>
            {props.textoBotão}
          </BotãoModalUi>
          {props.children}
        </Grid>
      </ModalContent>
    </Modal>
  )

  return { UiModal, isOpen, onOpen, onClose }
}

export interface IModalUiProps {
  título: string
  descrição: string
  textoBotão: string
  callbackBotão: () => void
  children?: ReactNode
  gridTemplateAreas?: GridProps['gridTemplateAreas']
}

export interface IModalUiReturn {
  UiModal: (props: IModalUiProps) => JSX.Element
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}
