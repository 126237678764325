import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { useReforceFavoritar } from '@/hooks/API/reforce/useReforceFavoritar'
import { plural } from '@/utilitários/plural'
import { Flex, Icon } from '@chakra-ui/react'
import { Heart } from '@phosphor-icons/react'

export const _BarraInferior = (props: BarraInferiorProps) => {
  const { mutate, isLoading } = useReforceFavoritar()
  return (
    <Flex alignItems="center" gap={gapUi.sm} paddingX={paddingUi.xl}>
      <Icon
        as={Heart}
        fontSize={25}
        cursor="pointer"
        color={props.data.isCurtida ? '#ff0000' : '#ffffff'}
        weight="duotone"
        onClick={() => {
          if (props.data.isCurtida || isLoading) return
          mutate({ reforceId: props.data.reforceId })
        }}
        _active={{
          transform: 'scale(1.3)',
          transition: 'transform 200ms',
        }}
      />

      <TextoMdUi fontWeight="medium">
        {props.data.reforceFavoritos} curtida
        {plural({ total: props.data.reforceFavoritos, plural: 's' })}
      </TextoMdUi>
    </Flex>
  )
}

interface BarraInferiorProps {
  data: {
    reforceId: string
    reforceFavoritos: number
    isCurtida: boolean
  }
}
