import { PlanoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApiPlano } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const usePlanoBuscarId = ({ id }: Props) => {
  const { data } = useQuery({
    queryKey: ['plano-id', id],
    queryFn: async () => await endPoint({ id }),
    enabled: !!id,
  })

  return { data }
}

async function endPoint({ id }: Props): Promise<PlanoModel> {
  const result = await api().get(rotasApiPlano.buscarPorId(id ?? ''))
  return result.data.data
}

interface Props {
  id?: string
}
