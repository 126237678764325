import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { IItemCarrinhoLocalStorage } from '@/páginas/autenticadas/prêmios/VisualizarPrêmio.página'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useResgatarPremios = () => {
  const { mutate: resgatarPremios, status: statusResgatarPremios } = useMutation(resgatarPremiosApi, {
    onError: (erro: AxiosError) => responseError(erro),
  })

  return { resgatarPremios, statusResgatarPremios }
}

async function resgatarPremiosApi(data: IItemCarrinhoLocalStorage[]): Promise<boolean> {
  const result = await api().post(rotasApi.premioResgatar, data)
  return result.data.data
}
