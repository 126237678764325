import { differenceInSeconds, format, formatDistanceToNow, isAfter } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export function obterMensagemTempoExpiracao(dataExpiracao: string) {
  const dataExpiracaoDate = new Date(dataExpiracao)
  const agora = new Date()

  const diffInSeconds = differenceInSeconds(dataExpiracaoDate, agora)

  if (diffInSeconds > 0) {
    if (diffInSeconds >= 60) {
      return `Encerra ${formatDistanceToNow(dataExpiracaoDate, {
        locale: ptBR,
        addSuffix: true,
        includeSeconds: true,
      })}`
    } else {
      return `${formatDistanceToNow(dataExpiracaoDate, { locale: ptBR, addSuffix: true })}`
    }
  } else {
    const isExpiredMoreThanOneDay = isAfter(dataExpiracaoDate, new Date(agora.getTime() - 86400000))
    if (isExpiredMoreThanOneDay) {
      return `Encerrado em ${format(dataExpiracaoDate, 'dd/MM/yyyy', { locale: ptBR })}`
    } else {
      return `Encerrado ${formatDistanceToNow(dataExpiracaoDate, { locale: ptBR })} atrás`
    }
  }
}
