import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useResponsávelAutorizarAluno = () => {
  const {
    mutate: autorizarAlunoMutate,
    isLoading: autorizarAlunoIsLoading,
    status: autorizarAlunoStatus,
    data: autorizarAlunoData,
  } = useMutation(endPoint, { onError: (error: AxiosError) => responseError(error) })

  return { autorizarAlunoMutate, autorizarAlunoIsLoading, autorizarAlunoStatus, autorizarAlunoData }
}

async function endPoint(data: AutorizarAlunoDTO): Promise<boolean> {
  const result = await api().post(rotasApi.responsávelAutorizarAluno, data)
  return result.data.data
}

export interface AutorizarAlunoDTO {
  alunoId: string
  responsavelCPF: string
  autorizacaoStatus: boolean
}
