type TypeOpacidadeCorHexadecimalAtributos =
  | '5%'
  | '10%'
  | '15%'
  | '20%'
  | '25%'
  | '30%'
  | '35%'
  | '40%'
  | '45%'
  | '50%'
  | '55%'
  | '60%'
  | '65%'
  | '70%'
  | '75%'
  | '80%'
  | '85%'
  | '90%'
  | '95%'

export const opacidadeCorHexadecimal = (opacidade: TypeOpacidadeCorHexadecimalAtributos) => {
  switch (opacidade) {
    case '5%':
      return '0C'
    case '10%':
      return '19'
    case '15%':
      return '26'
    case '20%':
      return '33'
    case '25%':
      return '3F'
    case '30%':
      return '4C'
    case '35%':
      return '59'
    case '40%':
      return '66'
    case '45%':
      return '72'
    case '50%':
      return '7F'
    case '55%':
      return '8C'
    case '60%':
      return '99'
    case '65%':
      return 'A5'
    case '70%':
      return 'B2'
    case '75%':
      return 'BF'
    case '80%':
      return 'CC'
    case '85%':
      return 'D8'
    case '90%':
      return 'E5'
    case '95%':
      return 'F2'
    default:
      return '00'
  }
}
