import { paddingUi } from '@/configurações/estilização/medidas'
import { CSSReset, Flex, FlexProps } from '@chakra-ui/react'

export const ContainerAppUi = (props: ContainerAppProps) => {
  const { ResetCSS, ...resto } = props
  return (
    <>
      {ResetCSS && <CSSReset />}
      <Flex width="full" paddingX={paddingUi.xl} {...resto} />
    </>
  )
}

interface ContainerAppProps extends FlexProps {
  ResetCSS?: boolean
}
