import { PlanoPagamentoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApiPlano } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const usePontezaComprar = () => {
  const { mutate, status, isLoading, data } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
    onSuccess: () => {
      queryClient.invalidateQueries(['instituição'])
      queryClient.invalidateQueries(['planoPagamento-plano-id'])
    },
  })

  return { mutate, status, isLoading, data }
}

async function endPoint(data: { instituicaoPeriodoId: string }): Promise<PlanoPagamentoModel> {
  const result = await api().post(rotasApiPlano.comprar, data)
  return result.data.data
}
