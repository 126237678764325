import { coresUi } from '@/configurações/estilização/cores'
import { fontSizeUi, lineHeightUi } from '@/configurações/estilização/tipografia'
import { Text, TextProps } from '@chakra-ui/react'

/** # 32px */
export const Texto2xlUi = (props: Omit<TextProps, 'fontSize'>) => (
  <Text
    color={coresUi.texto.primaria}
    fontSize={fontSizeUi['2xl']}
    lineHeight={lineHeightUi['2xl']}
    fontWeight="semibold"
    {...props}
  />
)
