import { BotãoCardUi } from '@/componentes/Botões/BotãoCardUi'
import { Cabeçalho } from '@/componentes/Cabeçalho/Cabeçalho'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Popup } from '@/componentes/Popup/Popup'
import { RankingBarraProgresso } from '@/componentes/RankingBarraProgresso/RankingBarraProgresso'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresCard, coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useNotas } from '@/hooks/API/nota/useNotas'
import { useRanking } from '@/hooks/API/ranking/useRanking'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import { useMenuAtivo } from '@/hooks/useMenuAtivo'
import { useModalUi } from '@/hooks/useModalUi'
import { corAleatóriaSequencial } from '@/utilitários/corAleatóriaSequencial'
import { CSSReset, Flex, Grid, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { CardNotaDisciplina } from './CardNotaDisciplina'

export const PontosPágina = () => {
  useMenuAtivo('Pontos')
  const navigate = useNavigate()
  const { UiModal, onClose } = useModalUi()

  const { usuárioAtivo } = useUsuárioAtivoAtom()

  const { notas } = useNotas({
    alunoId: usuárioAtivo?.Perfil?.alunoId ?? '-',
  })

  const { rankingAluno } = useRanking()

  return (
    <Flex width="100%" bg={coresUi.fundo.primaria} height="100%" alignItems="center" flexDirection="column" gap={gapUi['3xl']}>
      <CSSReset />
      <Cabeçalho />
      <ContainerAppUi flexDirection="column" gap={gapUi['3xl']} height="100%" alignItems="center">
        <Flex
          backgroundColor={coresUi['primaria-ativo']}
          color={coresUi.texto['com-fundo-primário']}
          borderRadius={borderRadiusUi.xxl}
          padding={paddingUi.xl}
          width="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Flex flexDirection="column" gap={gapUi.lg}>
            <TextoSmUi color={coresUi.texto['com-fundo-primário']}>Pontuação</TextoSmUi>
            <Flex justifyContent="space-between">
              <TextoXxlUi color={coresUi.texto['com-fundo-primário']} fontWeight="medium">
                {usuárioAtivo?.Perfil?.Aluno?.saldoEmPontos
                  ? usuárioAtivo?.Perfil?.Aluno?.saldoEmPontos.toLocaleString('pt-BR')
                  : 0}{' '}
                pontos
              </TextoXxlUi>
              <BotãoCardUi
                texto="Ver extrato"
                onClick={() => {
                  navigate(rotasApp.extratoPontos)
                }}
                bordaArredondada={12}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex w="full" flexDirection="column" gap={8}>
          <TextoMdUi>Posição no Ranking</TextoMdUi>
          <Stack px={2}>
            <RankingBarraProgresso
              local="Turma"
              colocacao={Number(rankingAluno?.turmaPosicao)}
              progresso={Number(rankingAluno?.turmaPorcentagem)}
            />
            <RankingBarraProgresso
              local="Escola"
              colocacao={Number(rankingAluno?.escolaPosicao)}
              progresso={Number(rankingAluno?.escolaPorcentagem)}
            />
            <RankingBarraProgresso
              local="Cidade"
              colocacao={Number(rankingAluno?.cidadePosicao)}
              progresso={Number(rankingAluno?.cidadePorcentagem)}
            />
            <RankingBarraProgresso
              local="Todos os alunos"
              colocacao={Number(rankingAluno?.todosAlunosPosicao)}
              progresso={Number(rankingAluno?.todosAlunosPorcentagem)}
            />
          </Stack>
        </Flex>
        <Flex width="100%" flexDirection="column" gap={gapUi.lg}>
          <Flex justifyContent="space-between" alignItems="center">
            <TextoMdUi>Notas disponíveis</TextoMdUi>
            <TextoXsUi
              color={coresUi['primaria-ativo']}
              onClick={() => {
                navigate(rotasApp.extratoNotas)
              }}
            >
              Ver todos
            </TextoXsUi>
          </Flex>
        </Flex>
        {notas?.length === 0 && <SvgSemDados />}
        <Grid width="100%" gridTemplateColumns="1fr 1fr" gap={gapUi.xl}>
          {notas?.slice(0, 4).map((nota, i) => {
            return (
              <CardNotaDisciplina
                key={JSON.stringify(nota) + i.toString()}
                cor={corAleatóriaSequencial({ index: i, cores: coresCard })}
                nota={nota}
              />
            )
          })}
        </Grid>
        <UiModal
          callbackBotão={() => {
            onClose()
            navigate(rotasApp.início)
          }}
          título="Ops!"
          descrição="Você ainda não possui pontos atribuídos
no sistema."
          textoBotão="Entendido"
        />
      </ContainerAppUi>
      <Popup />
    </Flex>
  )
}
