import { api } from '@/api/api'
import { useQuery } from '@tanstack/react-query'

export const usePesquisaProximaPergunta = (params: EndPoint) => {
  const { data: pesquisaProximaPergunta, isLoading: pesquisaProximaPerguntaIsLoading } = useQuery({
    queryKey: ['proxima-pergunta-pesquisa'],
    queryFn: async () => endPoint(params),
  })

  return { pesquisaProximaPergunta, pesquisaProximaPerguntaIsLoading }
}

async function endPoint(params: EndPoint): Promise<EndPointResponse> {
  const result = await api().get(`/autenticado/pesquisa/${params.pesquisaId}/proxima`)
  return result.data.data
}

interface EndPoint {
  pesquisaId?: string
}

interface EndPointResponse {
  id: string
  indice: number
  total: number
  pergunta: string
  alternativas: { id: string; alternativa: string }[]
}
