import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import Yup from '@/utilitários/Yup/Yup'
import { abstractSchema } from '@/utilitários/Yup/abstractSchema'
import { responseError } from '@/utilitários/handler/responseHandler'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'

export const useAtualizarSenha = () => {
  const {
    mutate: atualizarSenha,
    isLoading: atualizarSenhaIsLoading,
    status: atualizarSenhaStatus,
  } = useMutation(endPoint, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['me'])
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const atualizarSenhaContext = useForm({
    resolver: yupResolver(atualizarSenhaSchema),
  })

  return { atualizarSenhaContext, atualizarSenha, atualizarSenhaIsLoading, atualizarSenhaStatus }
}

async function endPoint(data: AtualizarSenhaSchemaType): Promise<void> {
  const result = await api().put(rotasApi.alunoAtualizarSenha, data)
  return result.data.data
}

const atualizarSenhaSchema = Yup.object().shape({
  verSenhaAtual: Yup.boolean().default(false).optional(),
  verSenhaNova: Yup.boolean().default(false).optional(),
  verConfirmarSenhaNova: Yup.boolean().default(false).optional(),
  senhaAtual: Yup.string().required().label('Senha atual'),
  senhaNova: abstractSchema.senha.required().label('Senha nova'),
  confirmarSenhaNova: abstractSchema.senha
    .required()
    .oneOf([Yup.ref('senhaNova')], 'As senhas não coincidem')
    .label('Confirmar senha nova'),
})

type AtualizarSenhaSchemaType = Yup.InferType<typeof atualizarSenhaSchema>
