import { PlanoPagamentoModel, PontezaAtributosDTO } from '@/@types/Models'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { LinkUi } from '@/componentes/LinkUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresCard, coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi } from '@/configurações/estilização/medidas'
import { corAleatóriaSequencial } from '@/utilitários/corAleatóriaSequencial'
import { Flex } from '@chakra-ui/react'
import moment from 'moment'

export const CardPlanoAdquirido = (props: Props) => {
  return (
    <FlexColumnUi
      key={props.plano.id}
      width="full"
      height="full"
      padding={gapUi.lg}
      borderRadius={borderRadiusUi.md}
      background={corAleatóriaSequencial({ index: props.i, cores: coresCard })}
      justifyContent="space-between"
      gap={gapUi.md}
    >
      <Flex alignItems="center" alignSelf="center" justifyContent="space-between" width="100%">
        <TextoSmUi fontWeight="bold">{props.plano?.InstituicaoPeriodo?.nome}</TextoSmUi>
        <TextoXsUi marginLeft={gapUi.md} fontWeight="bold">
          {moment(props.plano?.PlanoPagamento?.criadoEm).utc().format('DD/MM/YY [ás] HH:mm')}
        </TextoXsUi>
      </Flex>
      <TextoSmUi
        alignSelf="center"
        fontStyle="italic"
        hidden={
          !(['Aguardando pagamento'] as PlanoPagamentoModel['status'][]).includes(
            props.plano?.PlanoPagamento?.status as PlanoPagamentoModel['status'],
          )
        }
        color={coresUi['primaria-ativo']}
      >
        {props.plano?.PlanoPagamento?.status}
      </TextoSmUi>
      <TextoXsUi
        alignSelf="center"
        fontStyle="italic"
        hidden={
          !(['Aguardando pagamento'] as PlanoPagamentoModel['status'][]).includes(
            props.plano?.PlanoPagamento?.status as PlanoPagamentoModel['status'],
          )
        }
        color={coresUi['secundaria-ativo']}
      >
        Expira em {moment(props.plano?.PlanoPagamento?.expiraEm).utc().format('DD/MM/YY [ás] HH:mm')}
      </TextoXsUi>
      <LinkUi
        rota={props.plano.PlanoPagamento?.urlPagamento as string}
        target="_blank"
        fontSize="15px"
        alignSelf="center"
        fontWeight="bold"
        padding="5px"
        paddingX="20px"
        borderRadius={borderRadiusUi.md}
        background={coresUi['primaria-ativo']}
        color={coresUi.texto['com-fundo-primário']}
        hidden={
          !(['Aguardando pagamento'] as PlanoPagamentoModel['status'][]).includes(
            props.plano?.PlanoPagamento?.status as PlanoPagamentoModel['status'],
          )
        }
      >
        Realizar pagamento
      </LinkUi>
    </FlexColumnUi>
  )
}

interface Props {
  plano: PontezaAtributosDTO
  i: number
}
