import { coresUi } from '@/configurações/estilização/cores'
import { Box } from '@chakra-ui/react'

export const BackgroundRadial = () => {
  return (
    <Box
      position="fixed"
      width="2527px"
      height="1743px"
      left="-1076px"
      top="-60px"
      background={`radial-gradient(42.18% 46.4% at 50% 60%, ${coresUi['primaria-inativo']} -65%, rgba(15, 15, 15, 0) 100%);`}
    />
  )
}
