import { borderRadiusUi } from '@/configurações/estilização/medidas'
import { Button, ButtonProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

export const BotãoOutlineUi = ({ callback, children, color, ...rest }: IBotãoOutlineProps) => {
  return (
    <Button
      as={'span'}
      {...rest}
      background="transparent"
      borderWidth="1.3px"
      borderColor={color}
      color={color}
      onClick={callback}
      borderRadius={borderRadiusUi.lg}
    >
      {children}
    </Button>
  )
}

interface IBotãoOutlineProps extends ButtonProps {
  callback?: () => void
  children: ReactNode
  isDisabled?: boolean
  type?: ButtonProps['type']
}
