import { Icon } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

export type IContextoOutletLayoutComNavegação = {
  /** ### definir se o botão voltar ficará visível */
  setIsBotãoEsquerdo: React.Dispatch<React.SetStateAction<boolean>>
  /** ### função para ação onClick botão x  */
  setCallbackBotãoDireito: React.Dispatch<React.SetStateAction<(() => void) | undefined>>
  /** ### definir título da página */
  setTítulo: React.Dispatch<React.SetStateAction<string>>
  /** ### ícone direita */
  setÍconeDireita: React.Dispatch<React.SetStateAction<Icon | undefined>>
}

export const useContextoOutletLayoutComNavegação = (props: UseContextoOutletLayoutComNavegaçãoProps) => {
  const navigate = useNavigate()
  const contextoOutlet = useOutletContext<IContextoOutletLayoutComNavegação>()
  const { setIsBotãoEsquerdo, setCallbackBotãoDireito, setTítulo, setÍconeDireita } = contextoOutlet
  const { título, isBotãoEsquerdo = false, íconeDireita, rotaBotãoDireito } = props

  useEffect(() => {
    setTítulo(título)
    setÍconeDireita(íconeDireita)
    if (isBotãoEsquerdo) {
      setIsBotãoEsquerdo(isBotãoEsquerdo)
    }

    if (íconeDireita !== undefined) {
      let callbackFechar = () => {}
      if (rotaBotãoDireito) callbackFechar = () => navigate(rotaBotãoDireito)
      else callbackFechar = () => navigate(-1)
      setCallbackBotãoDireito(() => callbackFechar)
    }
  }, [isBotãoEsquerdo, título, íconeDireita])
}

interface UseContextoOutletLayoutComNavegaçãoProps {
  /** ### título da página  */
  título: string
  /** ### botão esquerdo visível */
  isBotãoEsquerdo?: boolean
  /** ### rota botão esquerdo */
  rotaBotãoDireito?: string
  /** ### ícone direita */
  íconeDireita?: Icon
}
