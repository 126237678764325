import { TextoErroLabelUi } from '@/componentes/Textos/TextoErroLabelUi'
import { getErroHookForm } from '@/utilitários/getErroHookForm'
import { useFormContext } from 'react-hook-form'
import { useContextSelector } from 'use-context-selector'
import { ContainerContexto } from './Container'

export const MensagemErro = () => {
  const name = useContextSelector(ContainerContexto, (value) => value.name)
  const formContext = useFormContext()
  const { formState } = formContext || {}
  const { errors } = formState || {}

  const inputError = getErroHookForm(errors, name)
  if (!inputError) {
    return <></>
  }

  return <TextoErroLabelUi>{inputError.message?.toString()} </TextoErroLabelUi>
}
