import { api } from '@/api/api'
import { useQuery } from '@tanstack/react-query'

export const useQuizTimeResposta = (params: EndPoint) => {
  const { data: quizTimeResposta, isLoading: isLoadingQuizTimeResposta } = useQuery({
    queryKey: ['quiz-time-resposta'],
    queryFn: async () => endPoint(params),
  })

  return { quizTimeResposta, isLoadingQuizTimeResposta }
}

async function endPoint(params: EndPoint): Promise<EndPointResponse> {
  const result = await api().get(`/autenticado/quiz-time-resposta/quiz/${params.quizId}`)
  return result.data.data
}

interface EndPoint {
  quizId?: string
}

interface EndPointResponse {
  unixTimeEnd: number
  serverTime: number
  pergunta: {
    id: string
    indice: number
    total: number
    pergunta: string
    alternativas: { id: string; alternativa: string }[]
  }
}
