import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, paddingUi } from '@/configurações/estilização/medidas'
import { Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { ReactNode } from 'react'

export interface IModalUiProps {
  children?: ReactNode
}

export interface IModalUiReturn {
  UiModal: (props: IModalUiProps) => JSX.Element
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useModalQuizPesquisaUi = (): IModalUiReturn => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const UiModal = ({ children }: IModalUiProps) => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={'xs'}>
      <ModalOverlay backdropFilter="auto" backdropBlur={`10px`} filter={`grayscale(0%)`} id="overlay-close-modal" />
      <ModalContent
        bg={coresUi['primaria-ativo']}
        borderRadius={borderRadiusUi.xxl}
        shadow="xs"
        padding={paddingUi['2xl']}
        alignItems="center"
        textAlign="center"
      >
        {children}
      </ModalContent>
    </Modal>
  )

  return { UiModal, isOpen, onOpen, onClose }
}
