import { AutenticacaoModel } from '@/@types/Models'
import { atom, useAtom } from 'jotai'

const INICIAL_STATE = undefined
const usuárioAtivoAtom = atom<AutenticacaoModel | undefined>(INICIAL_STATE)
usuárioAtivoAtom.debugLabel = 'usuárioAtivoAtom'

export const useUsuárioAtivoAtom = () => {
  const [usuárioAtivo, setUsuárioAtivo] = useAtom(usuárioAtivoAtom)

  const resetUsuárioAtivo = () => setUsuárioAtivo(INICIAL_STATE)

  return { usuárioAtivo, setUsuárioAtivo, resetUsuárioAtivo }
}
