import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi } from '@/configurações/estilização/medidas'
import { opacidadeCorHexadecimal } from '@/utilitários/opacidadeCorHexadecimal'
import { Flex } from '@chakra-ui/react'

const BORDER_RADIUS = borderRadiusUi.lg

export const BarraProgresso = ({ progressoEmPorcentagem, corBarra }: { progressoEmPorcentagem: number; corBarra: string }) => {
  return (
    <Flex
      width="100%"
      height="8px"
      backgroundColor={`${coresUi.texto.secundaria}${opacidadeCorHexadecimal('60%')}`}
      borderRadius={BORDER_RADIUS}
    >
      <Flex height="100%" width={`${progressoEmPorcentagem}%`} backgroundColor={corBarra} borderRadius={BORDER_RADIUS}></Flex>
    </Flex>
  )
}
