import { QuizPesquisaModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useQuizPesquisaRecentes = () => {
  const { data: dataQuizzesPesquisasRecentes } = useQuery({
    queryKey: ['quiz-pesquisa-recentes'],
    queryFn: async () => await endPoint(),
  })

  return { dataQuizzesPesquisasRecentes }
}

async function endPoint(): Promise<ResponseBaseQuizPesquisaRecentes[]> {
  const result = await api().get(rotasApi.quizzesPesquisasRecentes)
  return result.data.data
}

export type ResponseBaseQuizPesquisaRecentes = QuizPesquisaModel
