import { ReforceModel } from '@/@types/Models'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { coresUi } from '@/configurações/estilização/cores'
import { paddingUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useNavigate } from 'react-router-dom'

export const _MensagemComentario = (props: MensagemComentarioProps) => {
  const comentarios = props.reforce.ReforceComentarios || []
  const navigate = useNavigate()

  return (
    <>
      {comentarios.length === 0 && (
        <TextoSmUi color={coresUi.texto.primaria} fontWeight="thin" fontStyle="italic" paddingX={paddingUi.xl}>
          Nenhum comentário ainda!!
        </TextoSmUi>
      )}
      {comentarios.length === 1 && (
        <TextoSmUi
          color={coresUi.texto.primaria}
          fontWeight="thin"
          cursor="pointer"
          onClick={() => navigate(rotasApp.reforceVisualizar(props.reforce.id))}
          paddingX={paddingUi.xl}
        >
          Ver 1 comentário
        </TextoSmUi>
      )}
      {comentarios.length > 1 && (
        <TextoSmUi
          color={coresUi.texto.primaria}
          fontWeight="thin"
          cursor="pointer"
          onClick={() => navigate(rotasApp.reforceVisualizar(props.reforce.id))}
          paddingX={paddingUi.xl}
        >
          Ver todos os {comentarios.length} comentários
        </TextoSmUi>
      )}
    </>
  )
}

interface MensagemComentarioProps {
  reforce: ReforceModel
}
