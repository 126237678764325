import { ReforceComentarioModel } from '@/@types/Models'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { coresUi } from '@/configurações/estilização/cores'
import { DateBR } from '@/utilitários/DateBR'
import { Flex } from '@chakra-ui/react'
import moment from 'moment'
import { _Avatar } from './Avatar'
export const _ComentarioFoto = (props: ComentariosProps) => {
  return (
    <FlexColumnUi width="100%" justifyContent="center" height="fit-content" padding="5px 0" gap="10px">
      <Flex gap="5px" alignItems="center">
        <_Avatar
          size="sm"
          nome={props.Comentario.Aluno?.nome as string}
          src={props.Comentario.Aluno?.avatarArquivoLink as string}
        />
        <FlexColumnUi gap="5px">
          <TextoSmUi color={coresUi.texto.primaria} fontWeight="semibold" fontStyle="italic">
            {props.Comentario.Aluno?.nome}
          </TextoSmUi>
          <TextoSmUi color={coresUi.texto.primaria} fontWeight="thin">
            {props.Comentario.comentario}
          </TextoSmUi>
        </FlexColumnUi>
      </Flex>
      <TextoSmUi color={coresUi['primaria-ativo']} style={{ fontSize: '10px' }}>
        {moment(props.Comentario.criadoEm).from(DateBR.dataNova())}
      </TextoSmUi>
    </FlexColumnUi>
  )
}

interface ComentariosProps {
  Comentario: ReforceComentarioModel
  comFoto?: boolean
}
