import { AutenticacaoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { rotasApp } from '@/configurações/rotasApp'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import Yup from '@/utilitários/Yup/Yup'
import { responseError, responseSuccess } from '@/utilitários/handler/responseHandler'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const useDeletarAluno = () => {
  const { setUsuárioAtivo } = useUsuárioAtivoAtom()
  const navigate = useNavigate()
  const storage = new LocalStorage()

  const { mutate: mutateDeletarAluno, isLoading: isLoadingDeletarAluno } = useMutation(DeletarAluno, {
    onSuccess: async () => {
      storage.remove('token')
      setUsuárioAtivo(undefined)
      await queryClient.invalidateQueries(['me'])
      responseSuccess('Conta excluída com sucesso')
      navigate(rotasApp.login)
    },
    onError: (error: AxiosError) => responseError(error),
  })

  const contextDeletarAluno = useForm({
    resolver: yupResolver(DeletarAlunoSchema),
    reValidateMode: 'onChange',
  })

  return { mutateDeletarAluno, isLoadingDeletarAluno, contextDeletarAluno }
}

async function DeletarAluno(data: DeleteAlunoSchema): Promise<AutenticacaoModel> {
  const result = await api().post(rotasApi.alunoDeletar, data)
  return result.data.data
}

const DeletarAlunoSchema = Yup.object({
  senha: Yup.string().required(),
  verSenha: Yup.boolean().default(false).optional(),
})

export type DeleteAlunoSchema = Yup.InferType<typeof DeletarAlunoSchema>
