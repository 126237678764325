import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { useReforceBuscarId } from '@/hooks/API/reforce/useReforceBuscarId'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Box, Flex } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useParams } from 'react-router-dom'
import { ReforceCardPatternsUi } from './components/CardPatterns/ReforceCardPatternsUi'

export const ReforceVisualizarPagina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Visualizar', isBotãoEsquerdo: true })
  const { id } = useParams()
  const { data, fetchNextPage, hasNextPage, refetch } = useReforceBuscarId({ id, take: 8 })
  const flexRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage()
  }, [hasNextPage, inView])

  return (
    <ContainerAppUi flexDirection="column" height="full" position="relative">
      {data && (
        <>
          <ReforceCardPatternsUi.Root height="calc(100% )">
            <ReforceCardPatternsUi.Header
              data={{
                alunoNome: data.pages[0].data.Aluno?.nome as string,
                alunoAvatarUrl: data.pages[0].data.Aluno?.avatarArquivoLink as string,
                instituicaoNome: `${data.pages[0].data.Instituicao?.nome} - ${data.pages[0].data.Aluno?.Turma?.nome}` as string,
                criadoEm: data.pages[0].data?.criadoEm,
              }}
            />
            <ReforceCardPatternsUi.Imagem
              data={{
                premioNome: data.pages[0].data.Premio?.nome as string,
                premioFotoUrl: data.pages[0].data.Premio?.Fotos?.[0]?.arquivoLink as string,
              }}
            />
            <ReforceCardPatternsUi.BarraInferior
              data={{
                isCurtida: data.pages[0].isCurtida,
                reforceFavoritos: data.pages[0].totalCurtidas,
                reforceId: data.pages[0].data.id,
              }}
            />
            <TextoSmUi color={coresUi['primaria-ativo']} fontWeight="thin">
              {data.pages[0].data.descricao}
            </TextoSmUi>

            <Box>
              <ReforceCardPatternsUi.InputEmoji data={{ reforceId: data.pages[0].data.id }} />
            </Box>
            <FlexColumnUi width="full" overflow="scroll" ref={flexRef}>
              {data?.pages.map((page) =>
                page.data.ReforceComentarios?.map((comentario, index) => {
                  if (page.data.ReforceComentarios?.length === index + 1) {
                    return (
                      <Flex key={comentario.id} ref={ref}>
                        <ReforceCardPatternsUi.ComentarioFoto Comentario={comentario} />
                      </Flex>
                    )
                  } else {
                    return <ReforceCardPatternsUi.ComentarioFoto Comentario={comentario} key={comentario.id} />
                  }
                }),
              )}

              {!hasNextPage && !!data.pages[0].data.ReforceComentarios?.length && (
                <FlexColumnUi width="100%" height="fit-content" alignItems="center" gap={gapUi.lg}>
                  <TextoMdUi color={coresUi['primaria-inativo']} cursor="pointer" onClick={() => refetch()}>
                    Fim dos comentários
                  </TextoMdUi>
                </FlexColumnUi>
              )}
            </FlexColumnUi>
          </ReforceCardPatternsUi.Root>
        </>
      )}
    </ContainerAppUi>
  )
}
