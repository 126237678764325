import { QuizPesquisaModel } from '@/@types/Models'
import { CardOutlineUi } from '@/componentes/Card/CardOutline/CardOutlineUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Divisor } from '@/componentes/Divisor/Divisor'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, marginUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { rotasApp } from '@/configurações/rotasApp'
import { useQuizPesquisaHistorico } from '@/hooks/API/quiz/useQuizPesquisaHistorico'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useModalUi } from '@/hooks/useModalUi'
import { obterMensagemTempoExpiracao } from '@/utilitários/dateFns/oberMensagemTempoExpirado'
import { opacidadeCorHexadecimal } from '@/utilitários/opacidadeCorHexadecimal'
import { Button } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const HistóricoPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Histórico', isBotãoEsquerdo: true })

  const navigate = useNavigate()
  const { UiModal, onClose, onOpen } = useModalUi()
  const { quizPesquisaHistorico } = useQuizPesquisaHistorico()

  const CardItem = ({ título, descrição }: { título: string; descrição?: string }) => (
    <FlexColumnUi gap={gapUi.lg}>
      <TextoSmUi fontStyle="italic">{título}</TextoSmUi>
      {descrição && (
        <TextoSmUi color={`${coresUi.texto.primaria}${opacidadeCorHexadecimal('65%')}`} lineHeight={lineHeightUi.md}>
          {descrição}
        </TextoSmUi>
      )}
    </FlexColumnUi>
  )

  const RenderModalSemHistórico = () => (
    <UiModal
      título="Ops!"
      descrição="Você ainda não possui nenhum histórico de quiz ou pesquisa."
      textoBotão="Entendido"
      callbackBotão={() => {
        onClose()
        navigate(rotasApp.meuPerfil)
      }}
    />
  )

  useEffect(() => {
    if (quizPesquisaHistorico && quizPesquisaHistorico?.length === 0) {
      onOpen()
    }
  }, [JSON.stringify(quizPesquisaHistorico)])

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi gap={gapUi.xxl}>
        <RenderModalSemHistórico />
        {quizPesquisaHistorico?.map((quizPesquisa) => {
          const status = verificarStatusQuiz(quizPesquisa)
          return (
            <CardOutlineUi key={quizPesquisa.id}>
              <TextoMdUi marginBottom={marginUi.lg} fontWeight="semibold">
                {quizPesquisa.tema}
              </TextoMdUi>
              <FlexColumnUi gap={gapUi.lg}>
                <CardItem título="Número de questões" descrição={quizPesquisa.Perguntas?.length?.toString() || '0'} />

                <Divisor />
                <CardItem título="Status" descrição={status} />
              </FlexColumnUi>

              <Button
                isDisabled={status !== 'Completo'}
                width="100%"
                color={coresUi.texto['com-fundo-primário']}
                height="48px"
                background={coresUi['primaria-ativo']}
                fontWeight="500"
                borderRadius={borderRadiusUi.lg}
                _active={{}}
                _hover={{}}
                _focus={{}}
                onClick={() =>
                  quizPesquisa.tipo === 'quiz'
                    ? navigate(rotasApp.historicoQuiz(quizPesquisa.id))
                    : navigate(rotasApp.historicoPesquisa(quizPesquisa.id))
                }
              >
                {status === 'Completo' && 'Visualizar'}
                {status === 'Aguardando finalização' && status}
                {status === 'Em andamento' && quizPesquisa.expiraEm && <>{obterMensagemTempoExpiracao(quizPesquisa.expiraEm)}</>}
              </Button>
            </CardOutlineUi>
          )
        })}
      </FlexColumnUi>
    </ContainerAppUi>
  )
}

function verificarStatusQuiz(quizPesquisa: QuizPesquisaModel) {
  const dataAtual = new Date()

  if (quizPesquisa.calculado === false) {
    if (quizPesquisa?.expiraEm && new Date(quizPesquisa.expiraEm) > dataAtual) {
      return 'Em andamento'
    } else {
      return 'Aguardando finalização'
    }
  } else {
    return 'Completo'
  }
}
