import { Text } from '@chakra-ui/react'

export const NotFound404Page = () => {
  const methodDoesNotExist = () => {
    throw new Error('Method does not exist')
  }
  return (
    <>
      <button onClick={() => methodDoesNotExist()} style={{ background: 'white', color: 'red' }}>
        Break the world
      </button>
      <Text>404 Not Found</Text>
    </>
  )
}
