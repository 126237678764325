import { BannerModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useBannerListarRecentes = () => {
  const { data: banners } = useQuery({
    queryKey: ['banner-listar'],
    queryFn: async () => await endPoint(),
  })

  return { banners }
}

async function endPoint(): Promise<BannerModel[]> {
  const result = await api().get(rotasApi.bannerListar)
  return result.data.data
}
