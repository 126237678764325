import { corPrimariaAtivoUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { useTermosDeUsoGet } from '@/hooks/API/useTermosDeUsoGet'
import { Flex, Spinner } from '@chakra-ui/react'
import { FlexColumnUi } from './FlexColumnUi'
import { TextoMdUi } from './Textos/TextoMdUi'

export const TermosDeUsoUi = () => {
  const { data } = useTermosDeUsoGet()
  return (
    <>
      {data ? (
        <FlexColumnUi
          width="full"
          gap={gapUi.lg}
          dangerouslySetInnerHTML={{
            __html: data,
          }}
          __css={{
            gap: `${gapUi.lg} !important`,
            a: {
              color: '#91b5ff',
              textDecoration: 'underline',
            },
            div: {
              display: 'flex',
              flexDirection: 'column',
              gap: `${gapUi.lg} !important`,
            },
          }}
        />
      ) : (
        <Flex justifyContent="center" alignItems="center" gap={gapUi.lg} width="full">
          <TextoMdUi>Carregando...</TextoMdUi>
          <Spinner size="sm" color={corPrimariaAtivoUi} />
        </Flex>
      )}
    </>
  )
}
