import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { useMostrarSenha } from '@/componentes/CompositionsPatterns/Formulário/useMostrarSenha'
import { ContainerLayoutSemLayout } from '@/componentes/ContainerLayoutSemLayout'
import { LinkUi } from '@/componentes/LinkUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { fontSizeUi } from '@/configurações/estilização/tipografia'
import { rotasApp } from '@/configurações/rotasApp'
import { useLogin } from '@/hooks/API/autenticação/useLogin'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { Flex, Img, Text } from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { primeiroAcessoRotasApp } from '../primeiroAcesso/primeiroAcesso.routes'

export const LoginPágina = () => {
  const { loginContext, login } = useLogin()
  const { MostrarSenha, verSenha } = useMostrarSenha()
  const navigate = useNavigate()

  return (
    <ContainerLayoutSemLayout>
      <Img src="/logo.png" alt="Logo da empresa" width="250px" alignSelf="center" />
      <TextoXxlUi color={coresUi['primaria-ativo']} alignSelf="start" fontWeight="500">
        Bem-vindo(a)
      </TextoXxlUi>

      <FormProvider {...loginContext}>
        <FormulárioUi.Form callback={login}>
          <Flex width="100%" flexDirection="column" gap={gapUi['4xl']}>
            <Flex width="100%" flexDirection="column" gap={gapUi.xl}>
              <FormulárioUi.Container name="email">
                <FormulárioUi.Label título="Email" />
                <FormulárioUi.Input placeholder="Digite seu email" autoComplete="username" />
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="senha">
                <FormulárioUi.Label título="Senha" />
                <FormulárioUi.Input
                  placeholder="Digite sua senha"
                  autoComplete="current-password"
                  type={verSenha ? 'text' : 'password'}
                />
                <Flex width="100%" alignItems="center" justifyContent="space-between">
                  <MostrarSenha />
                  <LinkUi rota={rotasApp.recuperarSenhaMatrícula} fontSize={fontSizeUi.sm} color={coresUi['primaria-ativo']}>
                    Esqueci minha senha
                  </LinkUi>
                </Flex>
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
            </Flex>
            <BotãoFormulárioUi isLoading={false} type="submit" isDisabled={isErrosFormulário(loginContext.formState.errors)}>
              Acessar minha conta
            </BotãoFormulárioUi>
            <TextoSmUi alignSelf="center">
              Não possui uma conta?
              <Text
                color={coresUi['primaria-ativo']}
                as="span"
                paddingY="10px"
                onClick={() => navigate(primeiroAcessoRotasApp.buscarMatricula)}
              >
                {' '}
                Realizar cadastro
              </Text>
            </TextoSmUi>
          </Flex>
        </FormulárioUi.Form>
      </FormProvider>
    </ContainerLayoutSemLayout>
  )
}
