import { CheckBox } from './CheckBox'
import { ContainerProvider } from './Container'
import { Form } from './Form'
import { Input } from './Input'
import { InputMáscara } from './InputMask'
import { Label } from './Label'
import { MensagemErro } from './MensagemErro'
import { Textarea } from './Textarea'

export const FormulárioUi = {
  Container: ContainerProvider,
  MensagemErro,
  Input,
  Label,
  Textarea,
  Form,
  InputMáscara,
  CheckBox,
}
