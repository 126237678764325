import { HistoricoPontuacaoModel } from '@/@types/Models'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresCard } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { useHistóricosPontuação } from '@/hooks/API/históricoPontuação/useHistóricosPontuação'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { corAleatóriaSequencial } from '@/utilitários/corAleatóriaSequencial'
import { Card, Flex } from '@chakra-ui/react'
import moment from 'moment'

export const ExtratoPontosPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Extrato de pontos', isBotãoEsquerdo: true })

  const { usuárioAtivo } = useUsuárioAtivoAtom()

  const { historicosPontuacao } = useHistóricosPontuação({
    alunoId: usuárioAtivo?.Perfil?.Aluno?.id || '-',
  })

  const RenderNomePonto = (props: { historicoPontuacaoModel: HistoricoPontuacaoModel }) => {
    if (props.historicoPontuacaoModel.origem === 'ponteza') {
      return (
        <TextoSmUi fontWeight="semibold" textTransform="capitalize" className="truncate">
          Potenza
        </TextoSmUi>
      )
    } else if (props.historicoPontuacaoModel.origem === 'ponto-extra') {
      return (
        <TextoSmUi fontWeight="semibold" textTransform="capitalize" className="truncate">
          {props.historicoPontuacaoModel.descricao}
        </TextoSmUi>
      )
    } else {
      return (
        <TextoSmUi fontWeight="semibold" textTransform="capitalize" className="truncate">
          {props.historicoPontuacaoModel.origem}
        </TextoSmUi>
      )
    }
  }

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi gap={gapUi.md}>
        {historicosPontuacao?.length === 0 && <SvgSemDados />}
        {historicosPontuacao?.map((historico, i) => {
          return (
            <Card
              key={historico.id}
              background={corAleatóriaSequencial({ index: i, cores: coresCard })}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              padding={paddingUi.xxl}
              borderRadius={borderRadiusUi.lg}
              gap={gapUi.md}
            >
              <Flex justifyContent="space-between" alignItems="center" gap={gapUi.sm}>
                <TextoSmUi fontWeight="semibold" textTransform="capitalize">
                  <RenderNomePonto historicoPontuacaoModel={historico} />
                </TextoSmUi>
                <TextoSmUi fontStyle="italic">-</TextoSmUi>
                <TextoSmUi fontStyle="italic">
                  {historico.pontuacao} ponto{historico.pontuacao > 1 && 's'}
                </TextoSmUi>
              </Flex>
              <TextoXsUi fontStyle="italic" lineHeight={0}>
                {moment(historico.criadoEm).format('DD/MM/YY')}
              </TextoXsUi>
            </Card>
          )
        })}
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
