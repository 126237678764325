import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useNotificacaoMarcarTodasComoLida = () => {
  const { mutate } = useMutation(endPoint, {
    onError: (erro: AxiosError) => responseError(erro),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['notificacoes'])
      await queryClient.invalidateQueries(['notificacoes-count'])
    },
  })

  return { marcarTodasComoLida: mutate }
}
async function endPoint(): Promise<number> {
  const result = await api().get(rotasApi.notificacaoMarcarTodasComoLidas)
  return result.data.data
}
