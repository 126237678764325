import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { Texto2xlUi } from '@/componentes/Textos/Texto2xlUi'
import { TextoLgUi } from '@/componentes/Textos/TextoLgUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { configuraçõesApi } from '@/configurações/configuraçõesApi'
import { coresUi } from '@/configurações/estilização/cores'
import { aspectRatioUi, borderRadiusUi, gapUi } from '@/configurações/estilização/medidas'
import { useInstituicao } from '@/hooks/API/instituicao/useInstituicao'
import { usePlanoBuscarId } from '@/hooks/API/plano/usePlanoBuscarId'
import { usePlanoPagamentoPlanoId } from '@/hooks/API/plano/usePlanoPagamentoPlanoId'
import { usePontezaComprar } from '@/hooks/API/plano/usePontezaComprar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useModalUi } from '@/hooks/useModalUi'
import { Flex, Image } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { CardPlanoAdquirido } from './CardPlanoAdquirido'

export const PlanoVisualizarPagina = () => {
  const { id } = useParams()
  const { data: plano } = usePlanoBuscarId({ id })
  const { data: instituicao } = useInstituicao()
  const { data: planoPagamento } = usePlanoPagamentoPlanoId({ planoId: id })
  const { mutate, isLoading, status, data: pedidoPagamento } = usePontezaComprar()
  const { UiModal, onOpen, isOpen, onClose } = useModalUi()
  const context = useForm()

  useContextoOutletLayoutComNavegação({
    título: `${plano?.nome ?? 'Plano'}`,
    isBotãoEsquerdo: true,
  })

  useEffect(() => {
    if (status === 'success' && pedidoPagamento) {
      context.reset()
      onOpen()
    }
  }, [status])

  const RenderModal = useCallback(
    () => (
      <UiModal
        callbackBotão={() => {
          window.open(pedidoPagamento?.urlPagamento as string, '_blank')
          onClose()
        }}
        textoBotão="Ir para pagamento"
        título="Pedido realizado com sucesso"
        descrição="O pedido da compra foi realizado com sucesso, agora você deve realizar o pagamento para que o plano seja ativado."
      >
        <TextoXsUi fontStyle="italic" lineHeight="120%" color="#bdbdbd">
          O pedido vai expirar em 15 minutos, caso o pagamento não seja realizado o pedido será cancelado.
        </TextoXsUi>
      </UiModal>
    ),
    [isOpen, pedidoPagamento],
  )

  const pedidosAguardandoPagamento = planoPagamento?.filter((plano) => plano.PlanoPagamento?.status === 'Aguardando pagamento')

  return (
    <ContainerAppUi ResetCSS direction="column" gap={gapUi.xxl}>
      <RenderModal />
      {plano && (
        <>
          <FlexColumnUi gap={gapUi.sm}>
            <Flex
              position="relative"
              width="100%"
              height="0"
              paddingBottom={aspectRatioUi['16:9']}
              overflow="hidden"
              borderRadius={borderRadiusUi.lg}
            >
              <Flex position="absolute" top="0" left="0" width="100%" height="100%" alignItems="center" justifyContent="center">
                <Image
                  src={configuraçõesApi.baseArquivo(plano?.fotoUrl as string)}
                  maxWidth="100%"
                  width="100%"
                  height="100%"
                  maxHeight="100%"
                  objectFit="cover"
                />
              </Flex>
            </Flex>
            <Texto2xlUi textAlign="center" fontWeight="bold" color={coresUi['primaria-ativo']}>
              {plano.nome}
            </Texto2xlUi>
            <FlexColumnUi
              width="full"
              dangerouslySetInnerHTML={{
                __html: substituirPropriedades(plano?.descricao as string, {
                  nome: plano.nome,
                  valor: plano.valor.toFixed(2),
                }),
              }}
              __css={{ a: { color: '#91b5ff', textDecoration: 'underline' } }}
            />
          </FlexColumnUi>

          {planoPagamento?.length !== instituicao?.InstituicaoPeriodos?.length && (
            <FlexColumnUi gap={gapUi.md}>
              <Controller
                control={context.control}
                name="periodo"
                render={({ field: { onChange, value, ...field } }) => {
                  return (
                    <Select
                      placeholder="Selecione um período"
                      colorScheme="purple"
                      options={
                        instituicao?.InstituicaoPeriodos?.map((periodo) => {
                          const planoPagamentoPeriodo = planoPagamento?.find((plano) => plano.instituicaoPeriodoId === periodo.id)
                          const valor = planoPagamentoPeriodo ? ` Adquirido` : ` R$ ${plano.valor.toFixed(2)}`

                          return {
                            label: `${periodo.nome} - ${valor}`,
                            value: periodo.id,
                            isDisabled: !!planoPagamentoPeriodo,
                          }
                        }) ?? []
                      }
                      onChange={onChange}
                      value={value}
                      {...field}
                    />
                  )
                }}
              />
              <BotãoFormulárioUi
                isLoading={isLoading}
                isDisabled={context.watch('periodo') === ''}
                callback={() => {
                  mutate({ instituicaoPeriodoId: context.watch('periodo').value })
                }}
              >
                Comprar
              </BotãoFormulárioUi>
            </FlexColumnUi>
          )}
          <FlexColumnUi gap={gapUi.md} hidden={planoPagamento?.length === 0}>
            <TextoLgUi fontWeight="bold">Períodos adquiridos</TextoLgUi>
            <FlexColumnUi gap={gapUi.sm} alignItems="start">
              {planoPagamento?.map((plano, i) => {
                if (plano.PlanoPagamento?.status === 'Cancelado') return null
                else if (plano.PlanoPagamento?.status === 'Expirado') return null
                return <CardPlanoAdquirido key={plano.id} plano={plano} i={i} />
              })}
            </FlexColumnUi>
          </FlexColumnUi>
          {!!pedidosAguardandoPagamento?.length && (
            <FlexColumnUi gap={gapUi.md}>
              <TextoSmUi textAlign="center" fontStyle="italic" fontWeight="bold" color={coresUi.texto.erro}>
                Você possui {pedidosAguardandoPagamento.length} período aguardando pagamento.
              </TextoSmUi>
              <TextoSmUi textAlign="center" fontStyle="italic">
                Você será redirecionado para um ambiente seguro do PagSeguro para realizar o pagamento.
              </TextoSmUi>
            </FlexColumnUi>
          )}
        </>
      )}
    </ContainerAppUi>
  )
}

function substituirPropriedades(texto: string, dados: Record<string, string>) {
  return texto?.replace(/{{(.*?)}}/g, (match, propriedade) => dados[propriedade.trim()] || match)
}
