import { AutenticacaoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { rotasApp } from '@/configurações/rotasApp'
import { responseError, responseSuccess } from '@/utilitários/handler/responseHandler'
import Yup from '@/utilitários/Yup/Yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const useDeletarPublicoAluno = () => {
  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(DeletarPublicoAluno, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['me'])
      responseSuccess('Conta excluída com sucesso')
      navigate(rotasApp.login)
    },
    onError: (error: AxiosError) => responseError(error),
  })

  const context = useForm({ resolver: yupResolver(DeletarPublicoAlunoSchema), reValidateMode: 'onChange' })

  return { mutate, isLoading, context }
}

async function DeletarPublicoAluno(data: DeletePublicoAlunoSchema): Promise<AutenticacaoModel> {
  const result = await api().post(rotasApi.alunoDeletarPublico, data)
  return result.data.data
}

const DeletarPublicoAlunoSchema = Yup.object({ email: Yup.string().required(), senha: Yup.string().required() })

export type DeletePublicoAlunoSchema = Yup.InferType<typeof DeletarPublicoAlunoSchema>
