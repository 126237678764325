import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useReforceFavoritar = () => {
  const { mutate, status, isLoading } = useMutation(resgatarPremiosApi, {
    onError: (erro: AxiosError) => responseError(erro),
    onSuccess: () => {
      queryClient.invalidateQueries(['premios'])
      queryClient.invalidateQueries(['reforce-visualizar'])
    },
  })

  return { mutate, status, isLoading }
}

async function resgatarPremiosApi(data: { reforceId: string }): Promise<boolean> {
  const result = await api().post(rotasApi.reforceFavoritar, data)
  return result.data.data
}
