import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useQuiz = ({ id }: QuizAtributos) => {
  const { data: quiz } = useQuery({
    queryKey: ['quiz', id],
    queryFn: async () => await endPoint({ id }),
  })

  return { quiz }
}
async function endPoint({ id }: QuizAtributos): Promise<any> {
  const result = await api().get(rotasApi.quizBuscarPorId({ quizId: id }))
  return result.data.data
}

interface QuizAtributos {
  id: string
}
