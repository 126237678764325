import { api } from '@/api/api'
import { rotasApiAutenticadoV5 } from '@/configurações/rotasApi/rotasApiAutenticadoV5'
import { primeiroAcessoRotasApp } from '@/páginas/publicas/primeiroAcesso/primeiroAcesso.routes'
import Yup from '@/utilitários/Yup/Yup'
import { responseError, responseSuccess } from '@/utilitários/handler/responseHandler'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

const { path } = rotasApiAutenticadoV5.buscarMatricula
export const useAuthConfirmarMatriculaV5 = () => {
  const navigate = useNavigate()
  const context = useForm({ resolver: yupResolver(schema), mode: 'onChange' })

  const { status, isLoading, mutate } = useMutation({
    mutationFn: endPoint,
    onError: (error: AxiosError) => responseError(error),
    onSuccess: () => {
      localStorage.setItem('matricula', context.getValues('matricula'))
      responseSuccess('Matrícula verificada')
      navigate(primeiroAcessoRotasApp.cadastro)
    },
  })

  return {
    statusAuthBuscarMatricula: status,
    isLoadingAuthBuscarMatricula: isLoading,
    mutateAuthBuscarMatricula: mutate,
    contextAuthBuscarMatricula: context,
  }
}

async function endPoint(data: Schema): Promise<ServiceResponseAuthBuscarMatriculaV5> {
  const result = await api().get(path(data.matricula))
  return result.data.data
}

const schema = Yup.object({
  matricula: Yup.string().required(),
})

export type Schema = Yup.InferType<typeof schema>

export interface ServiceResponseAuthBuscarMatriculaV5 {
  instituicoes: Array<{
    id: string
    nome: string
  }>
}
