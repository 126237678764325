import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import { Avatar, AvatarProps } from '@chakra-ui/react'
import { useCallback } from 'react'

export const AvatarUsuárioAtivo = (props: AvatarUsuárioAtivoProps) => {
  const { usuárioAtivo } = useUsuárioAtivoAtom()

  const RenderAvatar = useCallback(
    () => (
      <Avatar
        size="xl"
        {...(usuárioAtivo?.Perfil?.Aluno?.avatarArquivoLink && {
          src: usuárioAtivo?.Perfil?.Aluno?.avatarArquivoLink,
        })}
        {...props}
        name={usuárioAtivo?.Perfil?.Aluno?.nome?.trim()}
        // cursor={'pointer'}
      />
    ),
    [JSON.stringify(usuárioAtivo), props],
  )

  return <RenderAvatar />
}

interface AvatarUsuárioAtivoProps extends AvatarProps {}
