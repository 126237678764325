import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TermosDeUsoUi } from '@/componentes/TermosDeUsoUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { corPrimariaAtivoUi, corSecundariaAtivoUi, corTextoUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useAlunoAceitarTermos } from '@/hooks/API/aluno/useAlunoAceitarTermos'
import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import { SealCheck, ShareFat } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'

export const TermosDeUsoPagina = () => {
  const { mutate, isLoading } = useAlunoAceitarTermos()
  const navigate = useNavigate()

  const handleVoltar = () => {
    localStorage.remove('token')
    navigate(rotasApp.login)
  }

  return (
    <ContainerAppUi flexDirection="column" gap={gapUi['10xl']} paddingY={paddingUi['10xl']} maxWidth="635px" margin="0 auto">
      <TextoXxlUi textAlign="center" color={corPrimariaAtivoUi} lineHeight={0}>
        Termos de uso
      </TextoXxlUi>
      <TermosDeUsoUi />

      <FlexColumnUi gap={gapUi.lg}>
        <Button
          {...botaoStyle}
          background={corPrimariaAtivoUi}
          border={`1px solid ${corPrimariaAtivoUi}`}
          color={corTextoUi['com-fundo-primário']}
          isLoading={isLoading}
          loadingText="Aceitando..."
          onClick={() => mutate()}
        >
          <SealCheck size={18} weight="duotone" />
          <TextoMdUi color="inherit" lineHeight={0}>
            Aceitar termos de uso
          </TextoMdUi>
        </Button>
        <Button
          {...botaoStyle}
          background="transparent"
          border={`1px solid ${corSecundariaAtivoUi}`}
          color={corSecundariaAtivoUi}
          onClick={handleVoltar}
        >
          <Flex transform="scaleX(-1)">
            <ShareFat size={18} weight="duotone" />
          </Flex>
          <TextoMdUi color="inherit" lineHeight={0}>
            Voltar
          </TextoMdUi>
        </Button>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}

const botaoStyle: ButtonProps = {
  _hover: {
    transition: '350ms',
    transform: 'scale(0.98)',
  },
  _active: {
    transition: '350ms',
    transform: 'scale(0.95)',
  },
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  gap: gapUi.sm,
  lineHeight: 0,
  height: '50px',
  transition: '350ms',
}
