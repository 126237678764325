import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { FlexProps } from '@chakra-ui/react'

export const CardOutlineUi = (props: FlexProps) => (
  <FlexColumnUi
    border={`1.5px solid ${coresUi.borda.card}`}
    padding={paddingUi.xxl}
    borderRadius={borderRadiusUi.xxl}
    gap={gapUi.xxl}
    {...props}
  />
)
