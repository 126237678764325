import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { coresUi } from '@/configurações/estilização/cores'
import { paddingUi } from '@/configurações/estilização/medidas'
import { useReforceCriarComentario } from '@/hooks/API/reforce/useReforceCriarComentario'
import { useEmojiPickerUi } from '@/hooks/useEmojiPickerUi'
import { Flex, Icon, useDisclosure } from '@chakra-ui/react'
import { PaperPlaneRight, Smiley } from '@phosphor-icons/react'
import { useEffect } from 'react'

export const _InputEmoji = (props: InputEmojiProps) => {
  const { isOpen, onToggle } = useDisclosure()
  const { mutate, isLoading, status } = useReforceCriarComentario()
  const { EmojiRender, emojis, setEmojis } = useEmojiPickerUi({ multiplos: true })

  useEffect(() => {
    if (status === 'success') {
      setEmojis([])
      onToggle()
    }
  }, [status])

  return (
    <>
      <Flex position="relative" paddingX={paddingUi.xl}>
        <FormulárioUi.Input
          __css={{}}
          placeholder="Comente aqui"
          borderRadius="0"
          border="none"
          borderColor="transparent"
          padding="0"
          _focus={{ borderBottom: `1px solid ${coresUi['primaria-ativo']}` }}
          paddingRight="50px"
          value={emojis.map((emoji) => emoji?.emoji).join('')}
          focusBorderColor="transparent"
          borderBottom={`1px solid ${coresUi['secundaria-inativo']}`}
          onKeyDown={(e) => {
            if (e.key === 'Backspace') setEmojis((emojis) => emojis.slice(0, emojis.length - 1))
          }}
          onClick={() => {
            if (!isOpen) onToggle()
          }}
        />
        {!!emojis.length && (
          <Flex
            position="absolute"
            right="25px"
            bottom="50%"
            transform="translateY(50%)"
            zIndex={99}
            padding="5px"
            onClick={() => {
              if (isLoading) return
              mutate({
                reforceId: props.data.reforceId,
                comentario: emojis.map((emoji) => emoji?.emoji).join(''),
              })
            }}
            cursor="pointer"
          >
            <Icon as={PaperPlaneRight} fontSize={20} color={coresUi['primaria-ativo']} />
          </Flex>
        )}
        <Flex
          position="absolute"
          right="0px"
          bottom="50%"
          transform="translateY(50%)"
          zIndex={99}
          padding="5px"
          onClick={onToggle}
          cursor="pointer"
        >
          <Icon as={Smiley} fontSize={20} color={'#8c8c8c'} />
        </Flex>
      </Flex>
      {isOpen && <EmojiRender />}
    </>
  )
}

interface InputEmojiProps {
  data: {
    reforceId: string
  }
}
