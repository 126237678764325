import { coresUi } from '@/configurações/estilização/cores'
import { Flex, Img } from '@chakra-ui/react'
import { ReactNode, useEffect, useRef, useState } from 'react'

export const SplashPágina = ({ children }: { children?: ReactNode }) => {
  const [deg, setDeg] = useState(-50)

  const sentido = useRef<'indo' | 'voltando'>('indo')

  useEffect(() => {
    const interval = setInterval(() => {
      setDeg((deg) => {
        if (deg === -50) sentido.current = 'indo'
        if (deg === 100) sentido.current = 'voltando'
        if (sentido.current === 'indo') {
          return deg + 1
        } else return deg - 1
      })
    }, 20)

    return () => {
      clearInterval(interval)
    }
  }, [sentido])

  return (
    <Flex
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      position="fixed"
      zIndex={99}
      top={0}
      left={0}
      background={`linear-gradient(180deg, #1B1536 35.00%, ${coresUi['primaria-ativo']} 159.35%)`}
    >
      <Flex
        top={`${deg}%`}
        width="400px"
        height="400px"
        position="absolute"
        background={`${coresUi.gradiente.azul}; filter: blur(148.73px); transform: rotate(90deg);`}
      ></Flex>

      <Flex
        bottom={`${deg}%`}
        width="400px"
        height="400px"
        position="absolute"
        background={`${coresUi.gradiente.verde}; filter: blur(148.73px); transform: rotate(90deg);`}
      ></Flex>

      <Flex
        left={`${deg}%`}
        width="400px"
        height="400px"
        position="absolute"
        background={`${coresUi.gradiente.rosa}; filter: blur(148.73px); transform: rotate(90deg);`}
      ></Flex>
      <Flex
        right={`${deg}%`}
        width="400px"
        height="400px"
        position="absolute"
        background={`${coresUi.gradiente.vermelho}; filter: blur(148.73px); transform: rotate(90deg);`}
      ></Flex>

      {children || <Img src="/logo.png" alt="Logo da empresa" width="300px" position="absolute" />}
    </Flex>
  )
}
