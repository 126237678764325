import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi } from '@/configurações/estilização/medidas'
import { Button, ButtonProps } from '@chakra-ui/react'
import { TextoSmUi } from '../Textos/TextoSmUi'

export const BotãoServiçoUi = (props: IBotãoServiçosUiProps) => {
  const { inverterChildren, ...rest } = props
  return (
    <Button
      as={'span'}
      backgroundColor={coresUi.fundo.secundaria}
      borderRadius={borderRadiusUi.xl}
      minWidth="156px"
      height="47px"
      _hover={{}}
      _active={{}}
      _focus={{}}
      display="flex"
      gap={gapUi.md}
      alignItems="center"
      {...(inverterChildren && { flexDirection: 'row-reverse' })}
      {...rest}
    >
      <TextoSmUi>{props.texto}</TextoSmUi>
      {props.children && props.children}
    </Button>
  )
}

type ButtonPropsOmit = Omit<ButtonProps, 'borderRadius' | 'minWidth' | 'height' | 'display' | 'gap'>
interface IBotãoServiçosUiProps extends ButtonPropsOmit {
  texto: string
  inverterChildren?: boolean
}
