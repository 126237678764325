import { QuizPesquisaModel } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useQuizPesquisaHistorico = () => {
  const { data: quizPesquisaHistorico } = useQuery({
    queryKey: ['quiz-pesquisa-historico'],
    queryFn: async () => await endPoint(),
  })

  return { quizPesquisaHistorico }
}

async function endPoint(): Promise<QuizPesquisaModel[]> {
  const result = await api().get(rotasApi.quizPesquisaHistoricoAluno)
  return result.data.data
}
