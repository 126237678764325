import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi } from '@/configurações/estilização/medidas'
import { fontSizeUi } from '@/configurações/estilização/tipografia'
import { getErroHookForm } from '@/utilitários/getErroHookForm'
import { ComponentWithAs, Input as InputChakra, InputProps as InputPropsChakra } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { useContextSelector } from 'use-context-selector'
import { ContainerContexto } from './Container'

export const Input: ComponentWithAs<'input', InputPatternProps> = (props: InputPatternProps) => {
  const required = useContextSelector(ContainerContexto, (value) => value.required)
  const name = useContextSelector(ContainerContexto, (value) => value.name)
  const disabled = useContextSelector(ContainerContexto, (value) => value.disabled)

  const formContext = useFormContext()
  const { register, formState } = formContext || {}
  const { errors } = formState || {}

  const inputError = getErroHookForm(errors, name)
  const inputRegister = register ? register(name, { required }) : undefined

  return (
    <InputChakra
      id={name}
      {...inputRegister}
      onChange={(e) => {
        inputRegister?.onChange(e)
        props.onChange && props.onChange(e)
      }}
      readOnly={disabled}
      isDisabled={disabled}
      isInvalid={!!inputError}
      {...inputStyle}
      {...props}
    />
  )
}
Input.displayName = 'Input'

export const inputStyle = {
  borderWidth: '1.5px',
  focusBorderColor: coresUi['primaria-inativo'],
  errorBorderColor: coresUi.texto.erro,
  borderColor: coresUi.borda.input,
  borderRadius: borderRadiusUi.lg,
  height: '48px',
  _placeholder: { color: coresUi.texto.placeholder, fontSize: fontSizeUi.sm },
}

export interface InputPatternProps extends InputPropsChakra {}
