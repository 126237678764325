import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'

import { CadastroAlunoSchema, useAlunoCadastroPrimeiroAcesso } from '@/hooks/API/aluno/useCadastroPrimeiroAcesso'
import { Flex, FlexProps, Img, Text } from '@chakra-ui/react'

import { FormProvider } from 'react-hook-form'
import { useBackLogin } from '../@components/useBackLogin'
import { FormulárioBase } from './FormulárioBase'
import { BotãoFormulárioUi } from '../../../../componentes/Botões/BotãoFormulárioUi'
import 'sweetalert2/dist/sweetalert2.min.css'
import './SwallCustom.css'
import Swal from 'sweetalert2'
import { useLogin } from '../../../../hooks/API/autenticação/useLogin'
import { useEffect, useState } from 'react'
import { TextoMdUi } from '../../../../componentes/Textos/TextoMdUi'

interface loginprops {
  email: string
  senha: string
  verSenha: boolean
}
export const PrimeiroAcessoCadastroPágina = () => {
  const { backLogin } = useBackLogin()
  const [dataLogin, setDataLogin] = useState<loginprops>()
  const propsContainerForm: FlexProps = {
    width: '100%',
    flexDirection: 'column',
    gap: gapUi.xl,
  }
  const { cadastroAluno, cadastroAlunoContext, cadastroAlunoIsLoading, cadastroAlunoStatus } = useAlunoCadastroPrimeiroAcesso()
  const { login } = useLogin()

  const callCadastrar = (data: CadastroAlunoSchema) => {
    Swal.fire({
      title: ' Você tem certeza que os dados estão corretos ?',
      showDenyButton: true,
      width: 400,
      text: 'Ao confirmar os dados enviados não será possível muda-los, o que pode te impedir de prosseguir com seu cadastro, sendo assim, se não tiver certeza que os dados de e-mail e senha foram corretamente preenchidos, corrija-os e prossiga.',
      confirmButtonText: 'Confirmar e enviar',
      denyButtonText: `Revisar dados`,
      color: '#fff',
      background: '#424242',
      confirmButtonColor: '#E9FF1A',
      customClass: {
        denyButton: 'btn-color',
        title: 'title-color',
        confirmButton: 'text-color',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        cadastroAluno(data)
        setDataLogin({ email: data.email, senha: data.senha, verSenha: true })
      } else if (result.isDenied) return null
    })
  }

  useEffect(() => {
    if (cadastroAlunoStatus === 'success' && dataLogin) {
      login(dataLogin)
    }
  }, [cadastroAlunoStatus])
  return (
    <>
      <Img src="/logo.png" alt="Logo da empresa" width="250px" />
      <Flex width="full" flexDirection="column" gap={gapUi['4xl']}>
        <TextoXxlUi color={coresUi['secundaria-ativo']} alignSelf="start" fontWeight="500">
          Primeiro acesso
        </TextoXxlUi>
        <TextoMdUi alignSelf="start">
          * Por favor, cadastre um e-mail válido e uma senha segura. O e-mail será utilizado para acessar sua conta posteriormente
          e também para recuperação de senha, caso você a perca.
        </TextoMdUi>
        <FormProvider {...cadastroAlunoContext}>
          <FormulárioUi.Form callback={callCadastrar}>
            <Flex {...propsContainerForm} userSelect="none">
              <Flex {...propsContainerForm}>
                <FormulárioBase />
              </Flex>
              <BotãoFormulárioUi isLoading={false} type="submit" mt="1rem" isDisabled={cadastroAlunoIsLoading}>
                Concluir
              </BotãoFormulárioUi>
              <TextoSmUi alignSelf="center">
                Já possui uma conta?
                <Text color={coresUi['primaria-ativo']} as="span" paddingY="10px" onClick={backLogin}>
                  {' '}
                  Realizar login
                </Text>
              </TextoSmUi>
            </Flex>
          </FormulárioUi.Form>
        </FormProvider>
      </Flex>
    </>
  )
}
