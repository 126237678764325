import { coresUi } from '@/configurações/estilização/cores'
import { gapUi, íconeSizeUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useNotificacaoCount } from '@/hooks/API/notificacao/useNotificacaoCount'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import { Flex, Icon } from '@chakra-ui/react'
import { BellSimple, HandWaving, ShoppingCart } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { AvatarUsuárioAtivo } from '../AvatarUsuárioAtivo'
import { ContainerAppUi } from '../ContainerAppUi'
import { TextoLgUi } from '../Textos/TextoLgUi'

export const Cabeçalho = () => {
  const navigate = useNavigate()
  const { usuárioAtivo } = useUsuárioAtivoAtom()
  const { notificacoesCount } = useNotificacaoCount()

  return (
    <ContainerAppUi>
      <Flex justifyContent="space-between" width="100%" alignItems="center" marginTop="5px">
        <Flex gap={gapUi.md} alignItems="center">
          <Icon as={HandWaving} fontSize={íconeSizeUi.padrão} color={coresUi['primaria-ativo']} />

          <TextoLgUi color={coresUi.texto.primaria} onClick={() => navigate(rotasApp.início)}>
            {usuárioAtivo?.Perfil?.Aluno?.nome}
          </TextoLgUi>
        </Flex>

        <Flex alignItems="center">
          {JSON.parse(localStorage.getItem('carrinho') as string)?.length > 0 && (
            <Icon
              cursor="pointer"
              as={ShoppingCart}
              fontSize={íconeSizeUi.padrão}
              color={coresUi['primaria-ativo']}
              onClick={() => navigate(rotasApp.carrinho)}
            />
          )}
          <Flex onClick={() => navigate(rotasApp.notificação)} paddingY="10px" position="relative">
            <Icon cursor="pointer" as={BellSimple} fontSize={íconeSizeUi.padrão} color={coresUi['primaria-ativo']} />
            {!!notificacoesCount && notificacoesCount > 0 && (
              <Flex
                width="13px"
                height="13px"
                borderRadius="50%"
                backgroundColor={coresUi.texto.erro}
                color={coresUi.texto.primaria}
                fontSize="8px"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                top="5px"
                right="10px"
              >
                {notificacoesCount >= 9 ? '9+' : notificacoesCount}
              </Flex>
            )}
          </Flex>
          <Flex onClick={() => navigate(rotasApp.meuPerfil)} paddingX="10px">
            <AvatarUsuárioAtivo size="sm" />
          </Flex>
        </Flex>
      </Flex>
    </ContainerAppUi>
  )
}
