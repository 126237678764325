import { Flex, FlexProps } from '@chakra-ui/react'

export const BotãoÍconeInput = (props: FlexProps) => {
  return (
    <Flex
      as="span"
      cursor="pointer"
      size="sm"
      position="absolute"
      height="full"
      alignItems="center"
      justifyContent="center"
      width="10%"
      right="0"
      top="50%"
      transform={`translateY(-50%)`}
      zIndex={5}
      {...props}
    />
  )
}
