import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { TextoLgUi } from '@/componentes/Textos/TextoLgUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresCard } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { useNotas } from '@/hooks/API/nota/useNotas'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { corAleatóriaSequencial } from '@/utilitários/corAleatóriaSequencial'
import { Card, Flex, Grid } from '@chakra-ui/react'
import { ChartBar, ChartDonut, ChartPieSlice } from '@phosphor-icons/react'
import moment from 'moment'

export const ExtratoNotasPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Extrato de notas', isBotãoEsquerdo: true })

  const { usuárioAtivo } = useUsuárioAtivoAtom()

  const { notas } = useNotas({
    alunoId: usuárioAtivo?.Perfil?.alunoId ?? '-',
  })

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi gap={gapUi.xl}>
        {notas?.length === 0 && <SvgSemDados />}
        <Grid width="100%" gridTemplateColumns="1fr" gap={gapUi.md}>
          {notas?.map((nota, i) => {
            return (
              <Card
                key={JSON.stringify(nota) + i.toString()}
                background={corAleatóriaSequencial({ index: i, cores: coresCard })}
                display="flex"
                direction="column"
                padding={paddingUi.xxl}
                borderRadius={borderRadiusUi['2xl']}
                gap={gapUi.md}
              >
                <Flex alignItems="center" justifyContent="space-between" width="100%" gap={gapUi.sm}>
                  <TextoLgUi textTransform="capitalize">{nota.Materia?.nome}</TextoLgUi>
                  <TextoXsUi fontStyle="italic">{moment(nota.criadoEm).format('DD/MM/YY')}</TextoXsUi>
                </Flex>
                <Flex width="100%" justifyContent="space-between">
                  <Flex alignItems="center" gap={gapUi.sm}>
                    <ChartPieSlice size={20} weight="duotone" color={'#ffffff'} />
                    <TextoSmUi>{nota.periodo}° Bimestre</TextoSmUi>
                  </Flex>
                  <Flex alignItems="center" gap={gapUi.sm}>
                    <ChartBar size={20} weight="duotone" color={'#ffffff'} />
                    <TextoSmUi>Nota: {nota.nota}</TextoSmUi>
                  </Flex>
                  <Flex alignItems="center" gap={gapUi.sm}>
                    <ChartDonut size={20} weight="duotone" color={'#ffffff'} />
                    <TextoSmUi>Presença: {nota.presenca}%</TextoSmUi>
                  </Flex>
                </Flex>
              </Card>
            )
          })}
        </Grid>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
