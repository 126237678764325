import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { Box, Flex } from '@chakra-ui/react'

export const SlidePaginacaoUi = (props: SlidePaginacaoUiProps) => {
  return (
    <Flex justifyContent="center" width="full" alignItems="center">
      <Flex justifyContent="center" width="full" alignItems="center">
        <Flex
          gap={gapUi.xs}
          background="rgba(0,0,0,0.8)"
          width="full"
          alignItems="center"
          justifyContent="center"
          paddingY={paddingUi.sm}
          borderBottomRadius={borderRadiusUi.xxl}
        >
          {props.array.map((item, i) => (
            <Box
              key={i}
              borderRadius="full"
              cursor="pointer"
              width={props.index === i ? '15px' : '6px'}
              height="6px"
              background={coresUi['primaria-ativo']}
              hidden={i >= props.array.length}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

interface SlidePaginacaoUiProps {
  index: number
  array: any[]
}
