import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { Box } from '@chakra-ui/react'
import { Eye, EyeSlash } from '@phosphor-icons/react'
import { useState } from 'react'

export const FormulárioBase = () => {
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)

  return (
    <>
      <FormulárioUi.Container name="matricula" hidden>
        <FormulárioUi.Label título="Matrícula" />
        <FormulárioUi.Input placeholder="Digite sua matrícula" value={localStorage.getItem('matricula') || ''} />
        <FormulárioUi.MensagemErro />
      </FormulárioUi.Container>
      <FormulárioUi.Container name="instituicaoId" hidden >
        <FormulárioUi.Label título="Instituição" />
        <FormulárioUi.Input value={localStorage.getItem('instituicoes') || ' '} />
        <FormulárioUi.MensagemErro />
      </FormulárioUi.Container>
      <FormulárioUi.Container name="email">
        <FormulárioUi.Label título="Email" />
        <FormulárioUi.Input placeholder="Digite seu email" autoComplete="username" />
        <FormulárioUi.MensagemErro />
      </FormulárioUi.Container>

      <FormulárioUi.Container name="senha">
        <FormulárioUi.Label título="Senha" />
        <Box position={'relative'}>
          <FormulárioUi.Input
            autoComplete="current-password"
            placeholder="Digite sua senha"
            type={isShowPassword ? 'text' : 'password'}
          />
          <Box
            cursor="pointer"
            position="absolute"
            top="1rem"
            right="1rem"
            zIndex="10"
            onClick={() => setIsShowPassword((prev) => !prev)}
          >
            {isShowPassword ? <Eye size={'1.25em'} /> : <EyeSlash size={'1.25em'} />}
          </Box>
        </Box>
        <FormulárioUi.MensagemErro />
      </FormulárioUi.Container>

      <FormulárioUi.Container name="confirmacaoSenha">
        <FormulárioUi.Label título="Confirmar senha" />
        <Box position={'relative'}>
          <FormulárioUi.Input
            autoComplete="current-password"
            placeholder="Digite sua senha"
            type={isShowConfirmPassword ? 'text' : 'password'}
          />
          <Box
            cursor="pointer"
            position="absolute"
            top="1rem"
            right="1rem"
            zIndex="10"
            onClick={() => setIsShowConfirmPassword((prev) => !prev)}
          >
            {isShowConfirmPassword ? <Eye size={'1.25em'} /> : <EyeSlash size={'1.25em'} />}
          </Box>
        </Box>

        <FormulárioUi.MensagemErro />
      </FormulárioUi.Container>
    </>
  )
}
