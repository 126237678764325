import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import Yup from '@/utilitários/Yup/Yup'
import { responseError } from '@/utilitários/handler/responseHandler'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'

export const useResponsávelVerificarCPF = () => {
  const storage = new LocalStorage()

  const {
    mutate: verificarCPFmutate,
    isLoading: verificarCPFisLoading,
    status: verificarCPFstatus,
  } = useMutation(endPoint, {
    onSuccess: (data) => {
      storage.set('idAluno', data.id)
      storage.set('nomeAluno', data.nome)
      storage.set('cpfAluno', data.cpf)
      storage.set('telefoneAluno', data.telefone)
      storage.set('emailAluno', data.email)
      storage.set('turma', data.turma)
      storage.set('instituicao', data.instituicao)
      storage.set('nomeResponsavel', data.Responsavel.nome)
      storage.set('cpfResponsavel', data.Responsavel.cpf)
      storage.set('telefoneResponsavel', data.Responsavel.telefone)
      storage.set('emailResponsavel', data.Responsavel.email)
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const verificarCPFcontext = useForm({
    resolver: yupResolver(verificarCPFSchema),
    reValidateMode: 'onChange',
  })

  return { verificarCPFmutate, verificarCPFisLoading, verificarCPFstatus, verificarCPFcontext }
}

async function endPoint(data: VerificarCPFSchema): Promise<ServiceResponseData> {
  const result = await api().post(rotasApi.responsávelValidarCPF, data)
  return result.data.data
}

const verificarCPFSchema = Yup.object({
  alunoUuidAutorizacao: Yup.string().uuid().required(),
  responsavelCPF: Yup.string().required().label('CPF do responsável'),
})

export type VerificarCPFSchema = Yup.InferType<typeof verificarCPFSchema>

type ServiceResponseData = {
  id: string
  nome: string
  cpf: string
  telefone: string
  email: string
  turma: string
  instituicao: string
  Responsavel: {
    id: string
    cpf: string
    nome: string
    telefone: string
    email: string
  }
}
