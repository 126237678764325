import { gapUi } from '@/configurações/estilização/medidas'
import { Flex, FlexProps } from '@chakra-ui/react'
import { createContext } from 'use-context-selector'

export const ContainerContexto = createContext<IContainerContexto>({
  required: false,
  name: '',
  disabled: false,
})

export const ContainerProvider = (props: ContainerProps) => {
  const { required, name, disabled } = props

  return (
    <ContainerContexto.Provider value={{ required, name, disabled }}>
      <Flex flexDirection="column" width="full" gap={gapUi.md} {...props} />
    </ContainerContexto.Provider>
  )
}

interface IContainerContexto {
  required?: boolean
  disabled?: boolean
  name: string
}

interface ContainerProps extends FlexProps, IContainerContexto {}
