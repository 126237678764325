import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useResponsávelVerificarUUID = ({ id }: Props) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['autorizacao-uuid', id],
    queryFn: async () => await endPoint({ id }),
    enabled: !!id,
  })

  return { data, isLoading, isError }
}

async function endPoint({ id }: Props): Promise<ResponsávelVerificarUUIDResponse> {
  const result = await api().get(rotasApi.responsávelVerificarUUID(id ?? ''))
  return result.data.data
}

interface Props {
  id?: string
}

interface ResponsávelVerificarUUIDResponse {
  nome: string
  CPF: string
}
