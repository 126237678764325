import { BarraProgresso } from '@/componentes/BarraProgresso/BarraProgresso'
import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { CardOutlineUi } from '@/componentes/Card/CardOutline/CardOutlineUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { RadioUi } from '@/componentes/RadioUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { coresUi } from '@/configurações/estilização/cores'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { rotasApp } from '@/configurações/rotasApp'
import { useQuizRespostaCriar } from '@/hooks/API/quizResposta/useQuizRespostaCriar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { toastUi } from '@/utilitários/toast'
import { Flex, Grid, Icon } from '@chakra-ui/react'
import { Clock, X } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuiz } from '../../../../hooks/API/quiz/useQuiz'
import { QuizLoadingPergunta } from './QuizLoadingPergunta'
import { useTimeResposta } from './useTimeResposta'
import { useVerifyQuizResposta } from './useVerifyQuizRespostas'

export const QuizPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Responder Quiz',
    isBotãoEsquerdo: true,
    íconeDireita: X,
  })

  const [searchBarParams] = useSearchParams()
  const quizId = searchBarParams.get('quizId') || undefined
  const { quiz } = useQuiz({ id: quizId || ' ' })
  const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const navigate = useNavigate()

  const { tempoResposta, quizTimeResposta, isLoadingQuizTimeResposta, tempoRespostaInterval, setTempoResposta } = useTimeResposta(
    { quizId },
  )

  const { quizRespostasContext, enviarRespostasQuiz, isLoadingEnviarRespostasQuiz, statusEnviarRespostasQuiz } =
    useQuizRespostaCriar()
  const { setValue, watch } = quizRespostasContext

  useEffect(() => {
    if (!quizTimeResposta) return
    setValue('perguntaId', quizTimeResposta?.pergunta.id)
  }, [JSON.stringify(quizTimeResposta)])

  const { verifyQuizResposta } = useVerifyQuizResposta({ quizId })

  useEffect(() => {
    if (statusEnviarRespostasQuiz === 'success') {
      if (quizTimeResposta?.pergunta.indice === quizTimeResposta?.pergunta.total) {
        tempoRespostaInterval.current && clearInterval(tempoRespostaInterval.current)
        setTempoResposta(-1)
        if (verifyQuizResposta === undefined) {
          navigate(0)
        }
      } else {
        // setTempoResposta(15)
        navigate(0)
      }
    }
  }, [statusEnviarRespostasQuiz])

  useEffect(() => {
    function loadToast() {
      if (verifyQuizResposta) {
        if (quiz) {
          toastUi({
            title: 'Parabéns! Você completou o quiz',
            description: `Você acertou ${verifyQuizResposta.respostasCorretas}/${quiz.Perguntas.length}  questões. Você poderá ver o gabarito quando o quiz for encerrado. `,
            status: 'success',
            duration: null,
          })

          navigate(rotasApp.início)
        }
      }
    }

    loadToast()
  }, [verifyQuizResposta])

  if (!quizTimeResposta) return <QuizLoadingPergunta hidden={false} />

  return (
    <ContainerAppUi position="relative" ResetCSS>
      <QuizLoadingPergunta hidden={isLoadingQuizTimeResposta || tempoResposta! !== -1} />

      <FlexColumnUi gap={gapUi['2xl']}>
        <FlexColumnUi gap={gapUi.lg}>
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <TextoMdUi fontWeight="medium">{`${quizTimeResposta.pergunta.indice}/${quizTimeResposta.pergunta.total}`}</TextoMdUi>
            <Flex
              alignItems="center"
              gap={gapUi.md}
              borderRadius={borderRadiusUi.md}
              background={coresUi.fundo.secundaria}
              padding={paddingUi.md}
            >
              <Icon as={() => <Clock size={16} weight="fill" color={coresUi['primaria-ativo']} />} />
              <TextoSmUi fontWeight="medium" color={coresUi['primaria-ativo']} marginLeft="sm">
                {tempoResposta! > 0 ? tempoResposta : 0}s
              </TextoSmUi>
            </Flex>
          </Flex>
          <BarraProgresso
            corBarra={coresUi['primaria-ativo']}
            progressoEmPorcentagem={(quizTimeResposta.pergunta.indice / quizTimeResposta.pergunta.total) * 100}
          />
          {tempoResposta === 0 && (
            <Flex align="center" justify="center">
              <TextoMdUi color={coresUi['primaria-ativo']} align="center" fontWeight="medium">
                Tempo esgotado para essa pergunta, clique em continuar!
              </TextoMdUi>
            </Flex>
          )}
        </FlexColumnUi>
        <FormProvider {...quizRespostasContext}>
          <FormulárioUi.Form callback={enviarRespostasQuiz}>
            <FlexColumnUi gap={gapUi['2xl']}>
              <FlexColumnUi
                gap={gapUi.sm}
                {...(tempoResposta === 0 && {
                  pointerEvents: 'none',
                  opacity: 0.5,
                })}
              >
                <Flex gap={gapUi.sm}>
                  {<TextoMdUi>{quizTimeResposta.pergunta.indice}.</TextoMdUi>}
                  <TextoMdUi lineHeight={lineHeightUi.lg}>{quizTimeResposta.pergunta.pergunta}</TextoMdUi>
                </Flex>
                <CardOutlineUi>
                  {quizTimeResposta.pergunta.alternativas.sort().map((alternativa, index) => (
                    <Grid
                      key={alternativa.id}
                      alignItems="start"
                      gap="10px"
                      width="full"
                      gridTemplateColumns={'15px 1fr'}
                      onClick={() => setValue(`alternativaId`, alternativa.id)}
                      cursor="pointer"
                      {...(statusEnviarRespostasQuiz !== 'idle' && {
                        pointerEvents: 'none',
                        opacity: 0.5,
                      })}
                    >
                      <RadioUi selecionado={watch(`alternativaId`) === alternativa.id} />

                      <TextoMdUi lineHeight={lineHeightUi.lg}>
                        {letras.charAt(index)}. {alternativa.alternativa}
                      </TextoMdUi>
                    </Grid>
                  ))}
                </CardOutlineUi>
              </FlexColumnUi>

              <BotãoFormulárioUi isLoading={isLoadingEnviarRespostasQuiz} type="submit">
                {quizTimeResposta?.pergunta.indice === quizTimeResposta?.pergunta.total ? 'Finalizar' : 'Continuar'}
              </BotãoFormulárioUi>
            </FlexColumnUi>
          </FormulárioUi.Form>
        </FormProvider>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
