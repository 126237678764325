import { Image, ImageProps } from '@chakra-ui/react'
import React from 'react'
import { useInView } from 'react-intersection-observer'

export const LazyImageUi: React.FC<LazyImageProps> = ({ src, alt, ...resto }) => {
  const [ref, inView] = useInView({ triggerOnce: true })

  return <Image ref={ref} src={inView ? src : ''} alt={alt} loading="lazy" {...resto} />
}

interface LazyImageProps extends ImageProps {
  src: string
  alt: string
}
