import { NotificacaoModel } from '@/@types/Models'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { LinkUi } from '@/componentes/LinkUi'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { corPrimariaAtivoUi, coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { useNotificacaoCount } from '@/hooks/API/notificacao/useNotificacaoCount'
import { useNotificacaoDeletar } from '@/hooks/API/notificacao/useNotificacaoDeletar'
import { useNotificacaoMarcarComoLida } from '@/hooks/API/notificacao/useNotificacaoMarcarComoLida'
import { useNotificacaoMarcarTodasComoLida } from '@/hooks/API/notificacao/useNotificacaoMarcarTodasComoLida'
import { useNotificacoes } from '@/hooks/API/notificacao/useNotificacoes'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { DateBR } from '@/utilitários/DateBR'
import { CSSReset, Flex, Icon } from '@chakra-ui/react'
import { ArrowSquareOut, Circle, X } from '@phosphor-icons/react'
import moment from 'moment'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export const NotificaçãoPágina = () => {
  useContextoOutletLayoutComNavegação({ título: 'Notificações', isBotãoEsquerdo: true })
  const { ref, inView } = useInView()

  const { notificacoes, fetchNextPage, hasNextPage, refetch } = useNotificacoes({ take: 10 })
  const { notificacoesCount } = useNotificacaoCount()
  const { marcarTodasComoLida } = useNotificacaoMarcarTodasComoLida()

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage()
  }, [hasNextPage, inView])

  return (
    <FlexColumnUi gap={gapUi.md} paddingX="20px">
      <CSSReset />
      {!!notificacoesCount && notificacoesCount > 1 && (
        <Flex
          alignSelf="end"
          gap={gapUi.sm}
          paddingY={gapUi.lg}
          borderRadius="10px"
          cursor="pointer"
          onClick={() => marcarTodasComoLida()}
        >
          <TextoSmUi color={coresUi['primaria-ativo']} fontWeight="thin">
            Marcar todas como lidas
          </TextoSmUi>
        </Flex>
      )}
      {notificacoes?.pages[0].data?.length === 0 && <SvgSemDados />}
      {notificacoes?.pages.map((page) =>
        page?.data?.map((notificacao, index) => {
          if (page.data.length === index + 1) {
            return (
              <Flex key={notificacao.id} ref={ref}>
                <CardNotificacao notificacao={notificacao} />
              </Flex>
            )
          } else {
            return <CardNotificacao key={notificacao.id} notificacao={notificacao} />
          }
        }),
      )}
      {!hasNextPage && !!notificacoes?.pages[0].data.length && (
        <FlexColumnUi width="100%" height="fit-content" alignItems="center" gap={gapUi.lg} paddingY="20px">
          <TextoMdUi color={coresUi['primaria-inativo']} cursor="pointer" onClick={() => refetch()}>
            Fim das notificações
          </TextoMdUi>
        </FlexColumnUi>
      )}
    </FlexColumnUi>
  )
}

const CardNotificacao = ({ notificacao }: { notificacao: NotificacaoModel }) => {
  const { marcarComoLida } = useNotificacaoMarcarComoLida()
  const { deletarNotificacao } = useNotificacaoDeletar()

  return (
    <Flex alignItems="center" paddingY="15px" minW="100%" background="#1c1c1c" borderRadius="10px">
      <Flex
        paddingX="10px"
        paddingBottom="10px"
        alignSelf="start"
        height="full"
        {...(!notificacao.lido && {
          cursor: 'pointer',
          onClick: () => marcarComoLida({ id: notificacao.id }),
        })}
      >
        <Icon
          fontSize="15px"
          weight="duotone"
          as={Circle}
          color={notificacao.lido ? coresUi['primaria-inativo'] : coresUi['primaria-ativo']}
        />
      </Flex>

      <FlexColumnUi gap="15px">
        <FlexColumnUi
          gap="5px"
          {...(!notificacao.lido && {
            cursor: 'pointer',
            onClick: () => marcarComoLida({ id: notificacao.id }),
          })}
        >
          <TextoSmUi width="fit-content" zIndex={99} opacity={notificacao.lido ? 0.3 : 1}>
            {notificacao.titulo}
          </TextoSmUi>
          <TextoSmUi
            textAlign="justify"
            color={coresUi.texto.secundaria}
            lineHeight={lineHeightUi.lg}
            opacity={notificacao.lido ? 0.3 : 1}
          >
            {notificacao.descricao}
          </TextoSmUi>
        </FlexColumnUi>
        {notificacao.link && notificacao.link?.length > 0 && (
          <LinkUi
            rota={notificacao.link}
            target={notificacao.link.includes('http') ? '_blank' : '_self'}
            flexDirection="row"
            display="flex"
            alignItems="center"
            gap="5px"
            background={coresUi['primaria-ativo']}
            width="fit-content"
            paddingX={gapUi.lg}
            paddingY={gapUi.sm}
            borderRadius="10px"
            cursor="pointer"
            {...(!notificacao.lido && { onClick: () => marcarComoLida({ id: notificacao.id }) })}
            opacity={notificacao.lido ? 0.3 : 1}
          >
            <Icon as={ArrowSquareOut} weight="duotone" fontSize="15px" color={coresUi.texto['com-fundo-primário']} />
            <TextoXsUi
              textTransform="uppercase"
              fontWeight="bold"
              style={{ fontSize: '10px' }}
              color={coresUi.texto['com-fundo-primário']}
            >
              {notificacao.textoLink ?? 'Acessar'}
            </TextoXsUi>
          </LinkUi>
        )}
        <TextoXsUi color={corPrimariaAtivoUi} opacity={notificacao.lido ? 0.3 : 1}>
          {moment(notificacao.criadoEm).from(DateBR.dataNova())}
        </TextoXsUi>
      </FlexColumnUi>
      <Flex
        paddingX="10px"
        paddingBottom="10px"
        alignSelf="start"
        onClick={() => deletarNotificacao({ id: notificacao.id })}
        cursor="pointer"
      >
        <Icon fontSize="15px" as={X} color={coresUi.texto.erro} alignItems="center" height="100%" />
      </Flex>
    </Flex>
  )
}
