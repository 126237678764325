const MEDIDA_BASE = 4

const BORDER_BASE = MEDIDA_BASE
export const borderRadiusUi = {
  /** # 2px */ xs: `${BORDER_BASE * 0.5}px`,
  /** # 4px */ sm: `${BORDER_BASE}px`,
  /** # 8px */ md: `${BORDER_BASE * 2}px`,
  /** # 12px */ lg: `${BORDER_BASE * 3}px`,
  /** # 16px */ xl: `${BORDER_BASE * 4}px`,
  /** # 20px */ xxl: `${BORDER_BASE * 5}px`,
  /** # 24px */ '2xl': `${BORDER_BASE * 6}px`,
  /** # 28px */ '3xl': `${BORDER_BASE * 7}px`,
  /** # 32px */ '4xl': `${BORDER_BASE * 8}px`,
  /** # 36px */ '5xl': `${BORDER_BASE * 9}px`,
  /** # 40px */ '6xl': `${BORDER_BASE * 10}px`,
  /** # 44px */ '7xl': `${BORDER_BASE * 11}px`,
  /** # 48px */ '8xl': `${BORDER_BASE * 12}px`,
  /** # 52px */ '9xl': `${BORDER_BASE * 13}px`,
  /** # 56px */ '10xl': `${BORDER_BASE * 14}px`,
  /** # 100% */ full: '100%',
}

const GAP_BASE = MEDIDA_BASE
export const gapUi = {
  /** # 2px */ xs: `${GAP_BASE * 0.5}px`,
  /** # 4px */ sm: `${GAP_BASE}px`,
  /** # 8px */ md: `${GAP_BASE * 2}px`,
  /** # 12px */ lg: `${GAP_BASE * 3}px`,
  /** # 16px */ xl: `${GAP_BASE * 4}px`,
  /** # 20px */ xxl: `${GAP_BASE * 5}px`,
  /** # 24px */ '2xl': `${GAP_BASE * 6}px`,
  /** # 28px */ '3xl': `${GAP_BASE * 7}px`,
  /** # 32px */ '4xl': `${GAP_BASE * 8}px`,
  /** # 36px */ '5xl': `${GAP_BASE * 9}px`,
  /** # 40px */ '6xl': `${GAP_BASE * 10}px`,
  /** # 44px */ '7xl': `${GAP_BASE * 11}px`,
  /** # 48px */ '8xl': `${GAP_BASE * 12}px`,
  /** # 52px */ '9xl': `${GAP_BASE * 13}px`,
  /** # 56px */ '10xl': `${GAP_BASE * 14}px`,
}

const PADDING_BASE = MEDIDA_BASE
export const paddingUi = {
  /** # 2px */ xs: `${PADDING_BASE * 0.5}px`,
  /** # 4px */ sm: `${PADDING_BASE}px`,
  /** # 8px */ md: `${PADDING_BASE * 2}px`,
  /** # 12px */ lg: `${PADDING_BASE * 3}px`,
  /** # 16px */ xl: `${PADDING_BASE * 4}px`,
  /** # 20px */ xxl: `${PADDING_BASE * 5}px`,
  /** # 24px */ '2xl': `${PADDING_BASE * 6}px`,
  /** # 28px */ '3xl': `${PADDING_BASE * 7}px`,
  /** # 32px */ '4xl': `${PADDING_BASE * 8}px`,
  /** # 36px */ '5xl': `${PADDING_BASE * 9}px`,
  /** # 40px */ '6xl': `${PADDING_BASE * 10}px`,
  /** # 44px */ '7xl': `${PADDING_BASE * 11}px`,
  /** # 48px */ '8xl': `${PADDING_BASE * 12}px`,
  /** # 52px */ '9xl': `${PADDING_BASE * 13}px`,
  /** # 56px */ '10xl': `${PADDING_BASE * 14}px`,
}

const MARGIN_BASE = MEDIDA_BASE
export const marginUi = {
  /** # 2px */ xs: `${MARGIN_BASE * 0.5}px`,
  /** # 4px */ sm: `${MARGIN_BASE}px`,
  /** # 8px */ md: `${MARGIN_BASE * 2}px`,
  /** # 12px */ lg: `${MARGIN_BASE * 3}px`,
  /** # 16px */ xl: `${MARGIN_BASE * 4}px`,
  /** # 20px */ xxl: `${MARGIN_BASE * 5}px`,
  /** # 24px */ '2xl': `${MARGIN_BASE * 6}px`,
  /** # 28px */ '3xl': `${MARGIN_BASE * 7}px`,
  /** # 32px */ '4xl': `${MARGIN_BASE * 8}px`,
  /** # 36px */ '5xl': `${MARGIN_BASE * 9}px`,
  /** # 40px */ '6xl': `${MARGIN_BASE * 10}px`,
  /** # 44px */ '7xl': `${MARGIN_BASE * 11}px`,
  /** # 48px */ '8xl': `${MARGIN_BASE * 12}px`,
  /** # 52px */ '9xl': `${MARGIN_BASE * 13}px`,
  /** # 56px */ '10xl': `${MARGIN_BASE * 14}px`,
}

export const íconeSizeUi = {
  /** # 28px */ menu: '28px',
  /** # 20px */ padrão: '20px',
}

export const aspectRatioUi = {
  /** # 1:1 */ quadrado: '100%',
  /** # 4:3 */ '4:3': '75%',
  /** # 16:9 */ '16:9': '56.25%',
  /** # 21:9 */ '21:9': '42.85%',
}
