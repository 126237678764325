import { PontezaAtributosDTO } from '@/@types/Models'
import { api } from '@/api/api'
import { rotasApiPlano } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const usePlanoPagamentoPlanoId = ({ planoId }: Props) => {
  const { data } = useQuery({
    queryKey: ['planoPagamento-plano-id', planoId],
    queryFn: async () => await endPoint({ planoId }),
    enabled: !!planoId,
  })

  return { data }
}

async function endPoint({ planoId }: Props): Promise<PontezaAtributosDTO[]> {
  const result = await api().get(rotasApiPlano.buscarPlanoPagamento(planoId ?? ''))
  return result.data.data
}

interface Props {
  planoId?: string
}
