import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { SlidePaginacaoUi } from '@/componentes/Slide/SlidePaginacaoUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXlUi } from '@/componentes/Textos/TextoXlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { aspectRatioUi, borderRadiusUi, gapUi, marginUi } from '@/configurações/estilização/medidas'
import { lineHeightUi } from '@/configurações/estilização/tipografia'
import { rotasApp } from '@/configurações/rotasApp'
import { usePrêmio } from '@/hooks/API/premio/usePrêmio'
import { usePrêmioBuscarAlunoPrêmio } from '@/hooks/API/premio/usePrêmioBuscarAlunoPrêmio'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { CSSReset, Flex, Image } from '@chakra-ui/react'
import { ShoppingCart } from '@phosphor-icons/react'
import { Fragment, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

export interface IItemCarrinhoLocalStorage {
  id: string
  quantidade: number
}

export const VisualizarPrêmioPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Prêmio',
    isBotãoEsquerdo: true,
    íconeDireita: ShoppingCart,
    rotaBotãoDireito: rotasApp.carrinho,
  })

  const { id } = useParams()
  const { premio } = usePrêmio({ id: id || '-' })
  const navigate = useNavigate()

  const adicionarAoCarrinho = useCallback(() => {
    const localstorage = new LocalStorage()
    let carrinho: IItemCarrinhoLocalStorage[] = JSON.parse(localstorage.get('carrinho') as string)
    if (!carrinho?.length) carrinho = []

    if (
      !carrinho.some(({ id: idLocal, quantidade }, index) => {
        if (idLocal === id) {
          carrinho[index].quantidade = quantidade + 1
          return true
        }
        return false
      })
    ) {
      carrinho.push({ id: id as string, quantidade: 1 })
    }
    localstorage.set('carrinho', JSON.stringify(carrinho))
    navigate(rotasApp.carrinho)
  }, [id])

  const { prêmioBuscarAluno } = usePrêmioBuscarAlunoPrêmio({ premioId: id })

  return (
    <FlexColumnUi gap={gapUi['4xl']}>
      <CSSReset />
      {id && id?.length > 0 && premio?.id && (
        <>
          <FlexColumnUi>
            <Flex width="100%" position="relative">
              {!!premio?.Fotos?.length && (
                <AutoPlaySwipeableViews interval={5000} slideStyle={{ padding: '0 16px', width: '100vw', maxWidth: '635px' }}>
                  {premio?.Fotos?.map((foto, index, arr) => (
                    <Fragment key={foto.id}>
                      <Flex
                        position="relative"
                        width="100%"
                        height="0"
                        paddingBottom={aspectRatioUi['16:9']}
                        overflow="hidden"
                        borderRadius={borderRadiusUi.lg}
                      >
                        <Flex
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image
                            src={foto.arquivoLink}
                            maxWidth="100%"
                            width="100%"
                            height="100%"
                            maxHeight="100%"
                            objectFit="cover"
                          />
                        </Flex>
                      </Flex>
                      <SlidePaginacaoUi index={index} array={arr} />
                    </Fragment>
                  ))}
                </AutoPlaySwipeableViews>
              )}
            </Flex>
            <ContainerAppUi direction="column">
              <Flex justifyContent="space-between">
                <FlexColumnUi>
                  <TextoXlUi marginTop={marginUi.xl} fontWeight="medium">
                    {premio?.nome}
                  </TextoXlUi>
                  <TextoSmUi color={coresUi.texto.secundaria} marginTop={marginUi.sm}>
                    {premio?.valorEmPontos} ponto
                    {premio?.valorEmPontos !== 1 ? 's' : ''}
                  </TextoSmUi>
                </FlexColumnUi>
                <FlexColumnUi alignItems="end">
                  <TextoXlUi marginTop={marginUi.xl} fontWeight="medium">
                    Estoque
                  </TextoXlUi>
                  <TextoSmUi color={coresUi.texto.secundaria} marginTop={marginUi.sm}>
                    {premio?.estoque} unidade
                    {premio?.estoque !== 1 ? 's' : ''}
                  </TextoSmUi>
                </FlexColumnUi>
              </Flex>
              <FlexColumnUi marginTop={marginUi.xl} gap={gapUi.xl} alignItems="center">
                {prêmioBuscarAluno?.quantidade === premio?.limitePorAluno ? (
                  <TextoMdUi color={coresUi.texto.sucesso} lineHeight={lineHeightUi.lg}>
                    Você já resgatou esse prêmio
                  </TextoMdUi>
                ) : (
                  <BotãoFormulárioUi isLoading={false} callback={() => adicionarAoCarrinho()} isDisabled={premio.estoque <= 0}>
                    {premio.estoque <= 0 ? 'Sem estoque' : 'Adicionar ao carrinho'}
                  </BotãoFormulárioUi>
                )}
              </FlexColumnUi>
            </ContainerAppUi>
          </FlexColumnUi>
          <ContainerAppUi>
            <FlexColumnUi gap={gapUi.sm}>
              <TextoMdUi fontWeight="medium">Descrição</TextoMdUi>
              <TextoSmUi color={coresUi.texto.secundaria} lineHeight={lineHeightUi.lg}>
                {premio?.descricao}
              </TextoSmUi>
            </FlexColumnUi>
          </ContainerAppUi>
        </>
      )}
    </FlexColumnUi>
  )
}
