import { LayoutComMenu } from '@/componentes/LayoutComMenu'
import { LayoutComNavegação } from '@/componentes/LayoutComNavegação'
import { LayoutSemLayout } from '@/componentes/LayoutSemLayout'
import { rotasApp } from '@/configurações/rotasApp'
import { NotFound404Page } from '@/páginas/NotFound404'
import { NotificaçãoPágina } from '@/páginas/autenticadas/Início/Notificação.página'
import { ExtratoPontosPágina } from '@/páginas/autenticadas/pontos/ExtratoPontos.página'
import { PrêmioPágina } from '@/páginas/autenticadas/prêmios/Prêmios.página'
import { LoginPágina } from '@/páginas/publicas/Login/Login.página'
import { RecuperarSenhaCódigoPágina } from '@/páginas/publicas/Login/RecuperarSenhaCódigo.página'
import { RecuperarSenhaMatrículaPágina } from '@/páginas/publicas/Login/RecuperarSenhaMatrícula.página'
import { RecuperarSenhaNovaSenhaPágina } from '@/páginas/publicas/Login/RecuperarSenhaNovaSenha.página'
import { SplashPágina } from '@/páginas/publicas/Splash'
import { TermosDeUsoPagina } from '@/páginas/publicas/TermosDeUso.pagina'
import { autorizarAlunoRoutes } from '@/páginas/publicas/autorizarAluno/autorizarAluno.routes'
import { BoasVindasPágina } from '@/páginas/publicas/boasVindas/BoasVindas.página'
import { ExcluirContaPágina } from '@/páginas/publicas/excluirConta/ExcluirConta.página'
import { primeiroAcessoRoutes } from '@/páginas/publicas/primeiroAcesso/primeiroAcesso.routes'
import { mapRotasPublicApp } from '@/utilitários/mapRotasApp'
import { RouteObject } from 'react-router-dom'

const rotasLayoutComMenu: RouteObject[] = [{ path: rotasApp.prêmio, element: <PrêmioPágina /> }]

const rotasLayoutComNavegação: RouteObject[] = [
  { path: rotasApp.notificação, element: <NotificaçãoPágina /> },
  { path: rotasApp.boasVindas, element: <BoasVindasPágina /> },
  { path: rotasApp.recuperarSenhaMatrícula, element: <RecuperarSenhaMatrículaPágina /> },
  { path: rotasApp.recuperarSenhaCódigo, element: <RecuperarSenhaCódigoPágina /> },
  { path: rotasApp.recuperarSenhaNovaSenha, element: <RecuperarSenhaNovaSenhaPágina /> },
  { path: rotasApp.extratoPontos, element: <ExtratoPontosPágina /> },
]

const rotasSemLayout: RouteObject[] = [
  { path: '*', element: <NotFound404Page /> },
  { path: '/splash', element: <SplashPágina /> },
  { path: '/termos-de-uso', element: <TermosDeUsoPagina /> },
]

const rotasLayoutSemLayout: RouteObject[] = [
  { path: rotasApp.login, element: <LoginPágina /> },
  { path: rotasApp.excluirConta, element: <ExcluirContaPágina /> },
  ...mapRotasPublicApp(primeiroAcessoRoutes),
  ...mapRotasPublicApp(autorizarAlunoRoutes),
]

export const rotasPublicas: RouteObject[] = [
  ...rotasSemLayout,
  { element: <LayoutComMenu />, children: [...rotasLayoutComMenu] },
  { element: <LayoutComNavegação />, children: [...rotasLayoutComNavegação] },
  { element: <LayoutSemLayout />, children: [...rotasLayoutSemLayout] },
]
