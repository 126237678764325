import { AutenticacaoModel } from '@/@types/Models'
import { useMe } from '@/hooks/API/autenticação/useMe'
import { responseError } from '@/utilitários/handler/responseHandler'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { toastUi } from '@/utilitários/toast'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { createContext } from 'use-context-selector'

export const UsuárioAutenticadoContexto = createContext<IUsuárioAutenticadoContexto>({
  data: undefined,
  error: undefined,
  status: 'loading',
  isLoading: false,
})

export const UsuárioAutenticadoProvider = () => {
  const { dataMe, errorMe, statusMe, isLoadingMe } = useMe()
  const storage = new LocalStorage()
  const navigate = useNavigate()

  const ambiente = import.meta.env.VITE_NODE_ENV

  useEffect(() => {
    if (statusMe === 'error') {
      if (ambiente === 'desenvolvimento') {
        responseError('DEBUG: Usuário não autenticado')
      } else {
        responseError('Usuário não autenticado!')
        storage.remove('token')
      }
    }
  }, [statusMe])

  useEffect(() => {
    if (dataMe?.acesso === 'administrador') {
      toastUi({
        title: 'Acesso negado',
        description: 'Uma conta é necessária para acessar o sistema',
        duration: 25000,
        status: 'error',
      })
      storage.remove('token')
    }
    if (dataMe && !dataMe.Perfil?.termosAceitos) {
      toastUi({
        title: 'Termos de uso',
        description: 'Você precisa ler e aceitar os termos de uso para acessar o sistema',
        duration: 10000,
        status: 'info',
      })
      navigate('/termos-de-uso')
    }
  }, [dataMe])

  return (
    <UsuárioAutenticadoContexto.Provider value={{ status: statusMe, data: dataMe, error: errorMe, isLoading: isLoadingMe }}>
      <Outlet />
    </UsuárioAutenticadoContexto.Provider>
  )
}

export interface IUsuárioAutenticadoContexto {
  status: 'error' | 'success' | 'loading'
  error: AxiosError<unknown, any> | unknown
  data: AutenticacaoModel | undefined
  isLoading: boolean
}
