import { CardQuizPesquisasUi } from '@/componentes/Card/CardQuizPesquisasUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { coresCard } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { corAleatóriaSequencial } from '@/utilitários/corAleatóriaSequencial'
import { Grid } from '@chakra-ui/react'
import { ClockCountdown } from '@phosphor-icons/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModalConferirQuizPesquisa } from './useModalConferirQuizPesquisa'
import { ResponseQuizPesquisaListar, useQuizPesquisaListar } from './useQuizPesquisaListar'
import { Popup } from '@/componentes/Popup/Popup'

export const QuizPesquisasPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Quiz/Pesquisas',
    isBotãoEsquerdo: true,
    íconeDireita: ClockCountdown,
    rotaBotãoDireito: rotasApp.histórico,
  })

  const { quizPesquisaListar } = useQuizPesquisaListar()
  const navigate = useNavigate()

  const [quizPesquisaSelecionada, setQuizPesquisaSelecionada] = useState<ResponseQuizPesquisaListar | undefined>()

  const { ModalConferir, onOpen } = useModalConferirQuizPesquisa()
  const handleClickCard = (quizPesquisa: ResponseQuizPesquisaListar) => {
    setQuizPesquisaSelecionada(quizPesquisa)
    onOpen()
  }

  return (
    <ContainerAppUi ResetCSS>
      <ModalConferir quizPesquisaSelecionada={quizPesquisaSelecionada} />
      <FlexColumnUi gap={gapUi['3xl']}>
        <FlexColumnUi gap={gapUi.lg}>
          {quizPesquisaListar?.length === 0 && <SvgSemDados />}
          <Grid width="100%" gridTemplateColumns="repeat(auto-fit, minmax(190px, 1fr))" gap={gapUi.lg} gridAutoFlow="row">
            {quizPesquisaListar?.map((quizPesquisa, index) => {
              const tipoQuiz = quizPesquisa?.tipo === 'quiz'
              const timeAtual = new Date().getTime()
              const timeQuiz = new Date(quizPesquisa.expiraEm || '').getTime()
              const isExpirado = (tipoQuiz && timeAtual > timeQuiz) ?? false

              return (
                <CardQuizPesquisasUi
                  key={quizPesquisa.id}
                  variação={isExpirado ? 'fechado' : 'aberto'}
                  texto={quizPesquisa?.tipo === 'quiz' ? 'Novo Quiz no Ar!' : 'Nova Pesquisa no Ar!'}
                  conteúdo={quizPesquisa.tema}
                  background={corAleatóriaSequencial({ index, cores: coresCard })}
                  onClickBotão={() =>
                    isExpirado ? navigate(rotasApp.historicoQuiz(quizPesquisa.id)) : handleClickCard(quizPesquisa)
                  }
                />
              )
            })}
          </Grid>
        </FlexColumnUi>
      </FlexColumnUi>
      <Popup />
    </ContainerAppUi>
  )
}
