import { coresUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

export const LayoutSemLayout = () => {
  return (
    <Flex
      margin="0 auto"
      width="100vw"
      maxWidth="500px"
      bg={coresUi.fundo.primaria}
      height="full"
      alignItems="center"
      flexDirection="column"
      padding={paddingUi.xl}
      marginTop={paddingUi['10xl']}
      gap={gapUi['6xl']}
    >
      <Outlet />
    </Flex>
  )
}
