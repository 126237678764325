import { PremioModel } from '@/@types/Models'
import { BotãoOutlineUi } from '@/componentes/Botões/BotãoOutlineUi'
import { Cabeçalho } from '@/componentes/Cabeçalho/Cabeçalho'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { ImagemProdutoUi } from '@/componentes/ImagemProdutoUi'
import { Popup } from '@/componentes/Popup/Popup'
import { SvgSemDados } from '@/componentes/SvgSemDados'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi, íconeSizeUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { usePremios } from '@/hooks/API/premio/usePrêmios'
import { useMenuAtivo } from '@/hooks/useMenuAtivo'
import { Flex, Grid, Icon } from '@chakra-ui/react'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const PrêmioPágina = () => {
  useMenuAtivo('Prêmios')

  const formContext = useForm<{
    busca: string
  }>()
  const buscaWatch = formContext.watch('busca')
  const buscar = buscaWatch && buscaWatch?.length > 2

  const { premios } = usePremios({
    ...(buscar && { nome: buscaWatch }),
  })

  const search = localStorage.getItem('searchParam')

  useEffect(() => {
    localStorage.removeItem('searchParam')
  }, [])

  return (
    <Flex width="100%" bg={coresUi.fundo.primaria} height="100%" alignItems="center" flexDirection="column" gap={gapUi['3xl']}>
      <Cabeçalho />
      <ContainerAppUi ResetCSS>
        <FlexColumnUi gap={gapUi['4xl']}>
          <FlexColumnUi gap={gapUi['5xl']}>
            <FormProvider {...formContext}>
              <FormulárioUi.Container name="busca" position="relative">
                <FormulárioUi.Input name="busca" placeholder="Buscar seu prêmio favorito..." defaultValue={search || ''} />
                <Icon
                  as={MagnifyingGlass}
                  position="absolute"
                  right="12px"
                  top="14px"
                  color={coresUi['primaria-ativo']}
                  fontSize={íconeSizeUi.padrão}
                />
              </FormulárioUi.Container>
            </FormProvider>
            {premios?.length === 0 && <SvgSemDados />}
            <Grid
              gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))"
              width="100%"
              justifyContent="space-between"
              gap={gapUi.xl}
            >
              {premios?.map((prêmio) => (
                <PrêmioRender key={prêmio.id} prêmio={prêmio} />
              ))}
            </Grid>
          </FlexColumnUi>
        </FlexColumnUi>
      </ContainerAppUi>
      <Popup />
    </Flex>
  )
}

export const PrêmioRender = (props: PrêmioRenderProps) => {
  const { prêmio } = props
  const navigate = useNavigate()
  return (
    <FlexColumnUi gap={gapUi.md} width="100%">
      <ImagemProdutoUi src={prêmio.Fotos?.[0]?.arquivoLink || ''} />
      <FlexColumnUi gap={gapUi.sm}>
        <TextoSmUi>{prêmio.nome}</TextoSmUi>
        <TextoXsUi color={coresUi.texto.secundaria}>
          {prêmio.valorEmPontos} ponto{prêmio.valorEmPontos > 1 ? 's' : ''}
        </TextoXsUi>
      </FlexColumnUi>
      <BotãoOutlineUi
        height="32px"
        width="95px"
        color={coresUi['primaria-ativo']}
        callback={() => navigate(rotasApp.visualizarPrêmio(prêmio.id))}
      >
        <TextoXsUi color="inherit">Ver detalhes</TextoXsUi>
      </BotãoOutlineUi>
    </FlexColumnUi>
  )
}

interface PrêmioRenderProps {
  prêmio: PremioModel
}
