import { RotasPublicRouterAppAtributos } from '@/@types/rotasRouterApp'
import { AutorizarAlunoConfirmar } from './AutorizarAlunoConfirmar.página'
import { AutorizarAlunoVerificarResponsável } from './AutorizarAlunoVerificarResponsável.página'

export const autorizarAlunoRotasApp = {
  verificarResponsavel: (id: string) => `/responsavel/autorizar-aluno/verificar/${id}`,
  alunoConfirmar: '/responsavel/autorizar-aluno/confirmar',
}

export const autorizarAlunoRoutes: RotasPublicRouterAppAtributos[] = [
  { path: autorizarAlunoRotasApp.verificarResponsavel(':id'), Element: AutorizarAlunoVerificarResponsável },
  { path: autorizarAlunoRotasApp.alunoConfirmar, Element: AutorizarAlunoConfirmar },
]
