import { api } from '@/api/api'
import { rotasApi } from '@/configurações/rotasApi'
import { useQuery } from '@tanstack/react-query'

export const useNotificacaoCount = () => {
  const { data } = useQuery({ queryKey: ['notificacoes-count'], queryFn: endPoint })

  return { notificacoesCount: data }
}
async function endPoint(): Promise<number> {
  const result = await api().get(rotasApi.notificacaoCountNaoLinda)
  return result.data.data
}
