import { NotaModel } from '@/@types/Models'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoLgUi } from '@/componentes/Textos/TextoLgUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXsUi } from '@/componentes/Textos/TextoXsUi'
import { borderRadiusUi, gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { Card, Flex } from '@chakra-ui/react'
import { ChartBar, ChartDonut, ChartPieSlice } from '@phosphor-icons/react'
import moment from 'moment'

export const CardNotaDisciplina = (props: CardNotaDisciplinaAtributos) => {
  const { cor, nota } = props

  return (
    <Card
      background={cor}
      display="flex"
      direction="column"
      padding={paddingUi.xxl}
      borderRadius={borderRadiusUi['2xl']}
      gap={gapUi['3xl']}
    >
      <Flex direction="column" width="100%" gap={gapUi.sm}>
        <TextoLgUi textTransform="capitalize">{nota.Materia?.nome}</TextoLgUi>
        <TextoXsUi fontStyle="italic"> {moment(nota.criadoEm).format('DD/MM/YY')}</TextoXsUi>
      </Flex>
      <FlexColumnUi width="100%" gap={gapUi.xs}>
        <Flex alignItems="center" gap={gapUi.sm}>
          <ChartPieSlice size={20} weight="duotone" color={'#ffffff'} />
          <TextoSmUi>{nota.periodo}° Bimestre</TextoSmUi>
        </Flex>
        <Flex alignItems="center" gap={gapUi.sm}>
          <ChartBar size={20} weight="duotone" color={'#ffffff'} />
          <TextoSmUi>Nota: {nota.nota}</TextoSmUi>
        </Flex>
        <Flex alignItems="center" gap={gapUi.sm}>
          <ChartDonut size={20} weight="duotone" color={'#ffffff'} />
          <TextoSmUi>Presença: {nota.presenca}%</TextoSmUi>
        </Flex>
      </FlexColumnUi>
    </Card>
  )
}

export interface CardNotaDisciplinaAtributos {
  cor: string
  nota: NotaModel
}
